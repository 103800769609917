import React from "react";
import {createUseStyles, useTheme} from "react-jss";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    BottomNavBar: {
        padding: "0px 10px",
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        position: "relative",
        height: "100%",
		boxSizing : "border-box",
		alignItems : "center"
    },
    BottomNavBar_Left: {
        position: "absolute",
        left: "0px",
        flex: "0 0 250px",
        padding: "0px 10px",
    },
    BottomNavBar_Middle: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "center",
    },
    BottomNavBar_Right: {
        position: "absolute",
        right: "0px",
        flex: "0 0 250px",
        padding: "0px 10px",
        display: "flex",
        alignItems: "center",
        height: "100%",
        margin: "0px",
		boxSizing : "border-box"
    },
    BottomNavBar_Right_Logo_Container: {
        display: "flex",
        height: "100%",
        alignItems: "center",
		boxSizing : "border-box"
    },
    BottomNavBar_Right_Logo: {
        width: "auto",
        height: "25px",
        paddingRight: "10px",
        margin: "0px",
    },
    BottomNavBar_Right_Text: {
        display: "flex",
        alignItems: "center",
    },
}));

const BottomNavBar = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <div className={classes.BottomNavBar}>
            <div className={classes.BottomNavBar_Left}>PDProtecTesting - V1.0.1</div>
            <div className={classes.BottomNavBar_Middle}>Copyright © 2021 ArTiBulles</div>
            <div className={classes.BottomNavBar_Right}>
                <div className={classes.BottomNavBar_Right_Logo_Container}>
                    <img alt="ArtibullesESLogo" src="/images/Artibulles_logo_ES_black_fontexp.svg" className={classes.BottomNavBar_Right_Logo} />
                </div>

                <div className={classes.BottomNavBar_Right_Text}>Designed by ArTiBulles Engineering Solutions</div>
            </div>
        </div>
    );

    /***************** RENDER ******************/
};

export default BottomNavBar;
