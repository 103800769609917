import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";
import {Route, Switch, Redirect} from "react-router-dom";

import TabNavMenu from "../TabNavMenu/TabNavMenu";
import TabNavLink from "../TabNavLink/TabNavLink";
//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

const Lockedstyles = {
    TabNav: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
    },
};

const styles = createUseStyles((theme) => ({
    TabNav: theme.TabNav,
}));
/**
 * Create tab menu with routing/navigation links
 * The advantage of using routing is that you can hide tabs and only activate them if permissions are allowing it or if they need to be
 * hidden in the menu bar and only visible when clicking a link
 */
const TabNav = React.forwardRef(function TabNav(props, ref) {
    const {
        MainSlug,
        DefaultTab,
        TabMenuContent,
        ClassExtendTabNav,
        ClassOverrideTabNav,
        Scrollable,
        MenuFullWidth,
        TabNavMenuHeight,
        ZIndexTabNavMenu,
        TabNavMenuSeparation,
        ClassExtendTabNavMenu,
        ClassOverrideTabNavMenu,
        ClassExtendTabNavMenuScrollableController,
        ScrollIconStyle,
        ActiveIndicator,
        NavLinkStyle,
        ClassOverrideTabNavLink,
        ClassExtendTabNavLink,
        ClassOverrideTabNavLink_Link,
        ClassExtendTabNavLink_Link,
        ClassOverrideTabNavLinkActive,
        ClassExtendTabNavLinkActive,
        ClassOverrideTabNavLink_Link_Indicator_Active,
        ClassExtendTabNavLink_Link_Indicator_Active,
        ClassOverrideTabNavLink_Link_Indicator_Inactive,
        ClassExtendTabNavLink_Link_Indicator_Inactive,
        ClassOverrideTabNavLink_Content,
        ClassExtendTabNavLink_Content
    } = props;
    const theme = useTheme();
    const classes = styles({...props, theme});

    

    var TabMenu = TabMenuContent.map((elem) => {
        return (
            <TabNavLink
                key={elem.Slug}
                exact
                ActiveIndicator={ActiveIndicator}
                Slug={elem.Slug}
                ShowOnlyIfActive={elem.ShowOnlyIfActive ? true : false}
                NavLinkStyle={NavLinkStyle}
                Tooltip={elem.Tooltip ? elem.Tooltip : null}
                ClassOverrideTabNavLink={ClassOverrideTabNavLink}
                ClassExtendTabNavLink={ClassExtendTabNavLink}
                ClassOverrideTabNavLink_Link={ClassOverrideTabNavLink_Link}
                ClassExtendTabNavLink_Link={ClassExtendTabNavLink_Link}
                ClassOverrideTabNavLinkActive={ClassOverrideTabNavLinkActive}
                ClassExtendTabNavLinkActive={ClassExtendTabNavLinkActive}
                ClassOverrideTabNavLink_Link_Indicator_Active={ClassOverrideTabNavLink_Link_Indicator_Active}
                ClassExtendTabNavLink_Link_Indicator_Active={ClassExtendTabNavLink_Link_Indicator_Active}
                ClassOverrideTabNavLink_Link_Indicator_Inactive={ClassOverrideTabNavLink_Link_Indicator_Inactive}
                ClassExtendTabNavLink_Link_Indicator_Inactive={ClassExtendTabNavLink_Link_Indicator_Inactive}
                ClassOverrideTabNavLink_Content={ClassOverrideTabNavLink_Content}
                ClassExtendTabNavLink_Content={ClassExtendTabNavLink_Content}
            >
                {elem.TabMenu}
            </TabNavLink>
        );
    });
  
    var Switcher = TabMenuContent.map((elem) => {
        return (
            <Route key={elem.Slug} path={elem.Slug}>
                {elem.TabContent}
            </Route>
        );
    });

   
    return (
        <div className={ClassOverrideTabNav ? ClassOverrideTabNav : clsx(classes.TabNav, ClassExtendTabNav)} style={Lockedstyles.TabNav}>
            <TabNavMenu
                ClassExtendTabNavMenu={ClassExtendTabNavMenu}
                ClassOverrideTabNavMenu={ClassOverrideTabNavMenu}
                ZIndex={ZIndexTabNavMenu}
                Scrollable={Scrollable}
                ClassExtendTabNavMenuScrollableController={ClassExtendTabNavMenuScrollableController}
                ScrollIconStyle={ScrollIconStyle}
                TabNavMenuSeparation={TabNavMenuSeparation}
                MenuFullWidth={MenuFullWidth}
                TabNavMenuHeight={TabNavMenuHeight}
            >
                {TabMenu}
            </TabNavMenu>
            <Switch>
                <Route exact path={MainSlug}>
                    <Redirect to={DefaultTab} />
                </Route>

                {Switcher}
            </Switch>
        </div>
    );
});

TabNav.defaultProps = {
    MainSlug: null,
    DefaultTab: null,
    TabMenuContent: null,
    ClassOverrideTabNav: null,
    ClassExtendTabNav: null,
    Scrollable: false,
    TabNavMenuHeight: null,
    ClassExtendTabNavMenuScrollableController: null,
    ClassOverrideTabNavMenu: null,
    ClassExtendTabNavMenu: null,
    ZIndexTabNavMenu: 10,
    TabNavMenuSeparation: "Shadow",
    MenuFullWidth: false,
    ScrollIconStyle: null,
    NavLinkStyle: null,
    ActiveIndicator: {
        WithIndicator: false,
        Position: "Bottom",
        ClassExtendActiveIndicator: null,
        ClassExtendInactiveIndicator: null,
    },
    ClassOverrideTabNavLink: null,
    ClassExtendTabNavLink: null,
    ClassOverrideTabNavLink_Link: null,
    ClassExtendTabNavLink_Link: null,
    ClassOverrideTabNavLinkActive: null,
    ClassExtendTabNavLinkActive: null,
    ClassOverrideTabNavLink_Link_Indicator_Active: null,
    ClassExtendTabNavLink_Link_Indicator_Active: null,
    ClassOverrideTabNavLink_Link_Indicator_Inactive: null,
    ClassExtendTabNavLink_Link_Indicator_Inactive: null,
    ClassOverrideTabNavLink_Content: null,
    ClassExtendTabNavLink_Content: null,
};

TabNav.propTypes = {
    /**
     * The Path to the Main Tab Route.
     * Used to redirect to the default Tab if navigating to the main slug
     * for instance : MainSlug : "/news", DefaultTab : "news/tab1", will redirect to tab1 when the route is /news.
     */
    MainSlug: PropTypes.string,
    /**
     * The path to the default tab
     * Combined with MainSlug it redirects to the default tab when the active route is the mainSlug
     */
    DefaultTab: PropTypes.string,
    /**
     * An array of objects defining the properties of the menu, the routing and the component to be displayed when the tab is active
     * Each Element must contain :
     * TabMenu : (a react object) that will be displayed in the tabMenu
     * Slug : (String) that will be used to route to the tab
     * TabContent : (a react Object) that will be displayed in the TabContent Element
     * ShowOnlyIfActive : true/false : Hide the TabMenu item unless the route is active. Default = false
     * Tooltip : Add a React-tooltip component to assist the user.
     * See https://www.npmjs.com/package/react-tooltip to check the properties that can be used.
     * The main props are :
     * place (top, right, bottom, left)
     * multiline : true/false
     * Example :
     * [
     * {TabMenu: (<div className={classes.TabMenuDiv}>
                     <Admin />
                     <div className={classes.TabTitle}>Admin</div>
                  </div>
                  ),
        Slug: "/tabnav/tab1",
        TabContent: Tab1,
        ShowOnlyIfActive : true,
        Tooltip: {Content: "hello I am There", disable : true, className : classes.Tooltip}
     *
     * the strucutre of the object is :
     *  Tooltip: {Content: "hello I am There", disable : true}
     *}
     *]
     */

    TabMenuContent: PropTypes.arrayOf(PropTypes.object),
    /**
     * Override the Overall TabNav div
     */
    ClassOverrideTabNav: PropTypes.string,
    /**
     * Extend the Overall TabNav div
     */
    ClassExtendTabNav: PropTypes.string,
    /**
     * Add a Scrollable NavMenu  : default : false, options : (false, true, Auto). If auto, the scrollControllers will not be added if the TabNav Elements fit in the menu.
     */
    Scrollable: PropTypes.any,
    /**
     * Defines the height of the TabNavMenu Toolbar : default ="50px"
     */
    TabNavMenuHeight: PropTypes.string,
    /**
     * If true the Menu Bar will take all the space based on parent size, if not, it will shrink to the content of the Nav Items
     */
    MenuFullWidth: PropTypes.bool,
    /**
     * Override the TabMenu class
     */
    ClassOverrideTabNavMenu: PropTypes.string,
    /**
     * Extend the TabMenuClass
     */
    ClassExtendTabNavMenu: PropTypes.string,

    /**
     * z-index of the tabNavMenu
     */
    ZIndexTabNavMenu: PropTypes.any,
    /**
     *Extends the class of the div containing the scroll Arrow
     */
    ClassExtendTabNavMenuScrollableController: PropTypes.string,
    /**
     *Style properties for the Controller Arrows for scrolling
     */
    ScrollIconStyle: PropTypes.object,
    /**
     * Style of the separator between the menu and the content of the tab.
     * Default = Shadow, options : (Shadow, Line, null)
     */
    TabNavMenuSeparation: PropTypes.string,
    /**
     * Style of the TabLink (default = rectangle) option : Tab (rounded rectangle)
     */
    NavLinkStyle: PropTypes.string,
    /**
     * Add an Indicator or not when the link is active:
     *  ActiveIndicator: {WithIndicator: false, Position: "Top", ClassExtendActiveIndicator: null, ClassExtendInactiveIndicator: null},
     */
    ActiveIndicator: PropTypes.any,

    /**
     * Extend the class
     */
    ClassExtendTabNavLink: PropTypes.string,
    /**
     * Override the class
     */
    ClassOverrideTabNavLink: PropTypes.string,
    /**
     * Extend the class
     */
    ClassExtendTabNavLink_Link: PropTypes.string,
    /**
     * Override the class
     */
    ClassOverrideTabNavLink_Link: PropTypes.string,
    /**
     * Override the class
     */

    ClassOverrideTabNavLinkActive: PropTypes.string,
    /**
     * Extend the class
     */
    ClassExtendTabNavLinkActive: PropTypes.string,

    /**
     * Extend the class
     */
    ClassOverrideTabNavLink_Link_Indicator_Active: PropTypes.string,
    /**
     * Extend the class
     */
    ClassExtendTabNavLink_Link_Indicator_Active: PropTypes.string,
    /**
     * Override the class
     */
    ClassOverrideTabNavLink_Link_Indicator_Inactive: PropTypes.string,
    /**
     * Extend the class
     */
    ClassExtendTabNavLink_Link_Indicator_Inactive: PropTypes.string,
    /**
     * Override the class
     */
    ClassOverrideTabNavLink_Content: PropTypes.string,
    /**
     * Extend the class
     */
    ClassExtendTabNavLink_Content: PropTypes.string,
};

export default TabNav;
