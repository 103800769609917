import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import Button from "@artibulles-cis/react/Button";
import {socketPIsensor} from "../../../common/util/socketPIsensor";
import LocalCircularLoaderIOS from "../../../artibulles-cis/LocalCircularLoaderIOS/LocalCircularLoaderIOS";
import _ from "lodash";
import {CheckBoxCircularFilled} from "@artibulles-cis/react-icons";
import GeneralGraph from "../Testing/GeneralGraph";
const {jStat} = require("jstat");

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    Main: {},
    Step: {
        borderBottom: "1px solid grey",
    },
    Step_Title: {
        "& h2": {margin: "5px 0px"},
    },
    Step_TitleFinal: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        marginTop: "10px",
        "& h2": {margin: "0px"},
    },
    Step_Content: {
        padding: "10px 20px",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "4px 0px 4px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
    FormFieldCheckBoxAligned: {
        flex: "0 1 600px",
        margin: "10px 0px 0px 0px",
    },
    Step2_Wrapper: {
        display: "flex",
    },
    Step2_Left: {
        flex: "0 0 400px",
    },
    Step2_Right: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        padding: "0px 0px 0px 20px",
        alignItems: "flex-start",
    },
    Description_Picture_Wrapper: {
        display: "flex",
    },
    Description_Picture: {
        width: "300px",
        height: "auto",
    },
    Instructions: {
        fontSize: "1.2rem",
        "& li": {
            margin: "5px 0px",
        },
    },
    ConnectionCheck: {
        fontSize: "0.8rem",
        marginTop: "5px",
        display: "flex",
        alignItems: "center",
        "& p": {
            margin: "0px 0px 0px 0px ",
        },
    },

    CameraToolbar: {
        display: "flex",
        alignItems: "center",
    },
    SensorData: {
        display: "flex",
        justifyContent: "center",
        flex: "1 0 auto",
        maxWidth: "750px",
        margin: "10px auto",
    },
    SensorDisplay: {
        display: "flex",
        flex: "1 0 auto",
        justifyContent: "center",
        margin: "0px 0px",
        boxSizing: "border-box",
        height: "30px",
        alignItems: "center",
        fontSize: "1.0rem",
        fontWeight: "500",
    },
    SensorDisplayValue: {
        display: "flex",
        flex: "1 0 100px",
        maxWidth: "150px",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        margin: "0 10px",
        boxSizing: "border-box",
        border: "1px solid grey",
        borderRadius: "5px",
        height: "100%",
    },
    FinalResult_WidthEvaluation: {
        display: "flex",
        width: "100%",
        margin: "10px 0px 0px 0px",
        "& h3": {
            margin: "0px auto",
        },
    },
    GraphResults: {
        flex: "1 1 auto",
        boxSizing: "border-box",
        padding: "10px",
        border: "1px solid grey",
        borderRadius: "10px",
        margin: "0px 5px",
    },
    Evaluation: {
        flex: "1 1 auto",
        boxSizing: "border-box",
        padding: "10px",
        border: "1px solid grey",
        borderRadius: "10px",
        margin: "0px 5px",
    },
    EvaluationContent: {
        display: "flex",
        flexDirection: "column",

        padding: "10px 20px",
    },
    ClassExtendTopToolBar: {
        backgroundColor: "grey",
        justifyContent: "center",
        height: "30px",
        flex: "0 0 35px",
    },
    CardExtended: {
        borderRadius: "10px",
    },
    StartestToolbar: {
        display: "flex",
        justifyContent: "center",
        marginBottom: "15px",
    },
}));

const PressureSensorComparison = React.memo(function PressureSensorComparison(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_SensorCalibrationData = useRef([]); //raw sensor data from calibration
    const Ref_RawSensorData = useRef([]); //Storing the raw sensor data;
    const Ref_FullChartData = useRef([]); //Storing the Full chart data;
    const Ref_ZeroSensorHSC = useRef();
	const Ref_GraphInstance = useRef(null); //Stroring the graph instance
    /***************** REFS ******************/

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/
    const [PISensorsSocketConnection, setPISensorsSocketConnection] = useState(false); //Checking the PI sensor Socket Connection

    const [PISensorZeroingHSC, setPISensorZeroingHSC] = useState(null);
    const [Loadertest, setLoadertest] = useState(false);
    const [Phase, setPhase] = useState(null);

    const [ZeroSensorValueHSC, setZeroSensorValueHSC] = useState(null);
    const [ShowSensorReading, setShowSensorReading] = useState(false); //display the sensor reading
    const [ShowTestResult, setShowTestResult] = useState(false); //display the temp test results
    const [ShowFinalResult, setShowFinalResult] = useState(false); //display the test results
    const [SensorSamplingRate, setSensorSamplingRate] = useState(100); // Sample rate of the sensor (200ms)
    const [CurrentSensorValue, setCurrentSensorValue] = useState({Time_s: null, Pressure_mbar: null});
    const [CurrentChartPressurePointHSC, setCurrentChartPressurePointHSC] = useState(null);
    const [TestResults, setTestResults] = useState(null);

    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const StoreCalibrationData = useCallback(
        (data) => {
            //data = {SerialNumber, TimeMs , AtmPressure (bar), StartDate (Server unixTime), Pressure (mbar), SensorCount (SensorValue)}
            var CurrentEntries = [];

            if (Ref_SensorCalibrationData.current && Array.isArray(Ref_SensorCalibrationData.current)) {
                CurrentEntries = Ref_SensorCalibrationData.current;
            }
            //Storing Raw Data as backup
            let NewEntry = data;
            CurrentEntries.push(NewEntry);
        },
        [Ref_SensorCalibrationData]
    );
    const CalculateCalibrationValue = useCallback(() => {
        //Based on the RawData, we calculate the average, the min, the max, the std and we propose a zero value
        var PressureDataSSC = [];
        var PressureDataHSC = [];
        if (Ref_SensorCalibrationData.current && Array.isArray(Ref_SensorCalibrationData.current)) {
            Ref_SensorCalibrationData.current.forEach((elem) => {
                PressureDataHSC.push(elem.Pressure);
            });
        }
        let ZeroingOutputHSC = {
            min: jStat.min(PressureDataHSC),
            max: jStat.max(PressureDataHSC),
            mean: jStat.mean(PressureDataHSC),
            stdv: jStat.stdev(PressureDataHSC),
            deltaMaxMin: jStat.max(PressureDataHSC) - jStat.min(PressureDataHSC),
        };
        let AbsErrorTheoreticalHSC = (0.25 * ZeroingOutputHSC.mean) / 100;
        ZeroingOutputHSC.AbsError = AbsErrorTheoreticalHSC;
        setPISensorZeroingHSC(ZeroingOutputHSC);
        setZeroSensorValueHSC(ZeroingOutputHSC.mean);
        Ref_ZeroSensorHSC.current = ZeroingOutputHSC.mean;

        setPhase("CalibrateSensorDone");
    }, [Ref_SensorCalibrationData]);

    const StoreSensorData = useCallback((data) => {
        // console.log("CallBack data", data);

        // let DataPointCount = (Ref_ChartSampling.current = Ref_ChartSampling.current + 1);

        var CurrentEntries = [];

        if (Ref_RawSensorData.current && Array.isArray(Ref_RawSensorData.current)) {
            CurrentEntries = Ref_RawSensorData.current;
        }
        //Storing Raw Data as backup
        let NewEntry = data;
        CurrentEntries.push(NewEntry);

        //Storing the current value

        //Adding a point to the graph //Based on the graph refresh rate
        // GraphRefreshRate

        const CurrentPressurePointHSC = [data.TimeMs / 1000, parseFloat(data.Pressure - Ref_ZeroSensorHSC.current)];
        //Storing the data in the chart
        Ref_FullChartData.current.push(CurrentPressurePointHSC);

        // if (DataPointCount > GraphRefreshRate / SensorSamplingRate) {
        //We pass the point to th chart and we reset the counter

        setCurrentChartPressurePointHSC(CurrentPressurePointHSC);
        //     Ref_ChartSampling.current = 0;
        // }

        setCurrentSensorValue({Time_s: (data.TimeMs / 1000).toFixed(1), Pressure_mbar: (data.Pressure - Ref_ZeroSensorHSC.current).toFixed(2)});
    }, []);

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        //Requesting connection to the PI-Sensors Socket Server
        socketPIsensor.emit("RequestConnection", () => {});
        //Receiving confirmation
        const HandlePISensorConnection = () => setPISensorsSocketConnection(true);

        socketPIsensor.on("UserConnected", (data) => {
            HandlePISensorConnection();
        });

        //Receiving calibration data from the sensor
        socketPIsensor.on("ContinuousPressureSensorCalibration", (data) => {
            StoreCalibrationData(data);
        });

        //Receiving calibration data from the sensor
        socketPIsensor.on("ContinuousPressureSensorCalibrationDone", () => {
            CalculateCalibrationValue();
            setLoadertest(false);
        });

        //Receiving data from the sensor
        socketPIsensor.on("ContinousPressureSensorData", (data) => {
            StoreSensorData(data);
        });
        return () => {
            // socketQRServer.off("UserConnected", HandleQRConnection);
            socketPIsensor.off("UserConnected", HandlePISensorConnection);
        };
    }, []);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleCalibrateSensor = () => {
        socketPIsensor.emit("ContinuousPressureCalibrateSensor", {SampleRate: 100, CalibrationTimeMS: 2000});

        setLoadertest(true);
    };

    const HandleStartTest = () => {
        //Ask the sensor to send the data
        socketPIsensor.emit("ContinousPressureStreamStart", {
            SampleRate: SensorSamplingRate,
        });
        setPhase(null);

        setShowSensorReading(true);
    };

    const HandleTestCompleted = () => {
        //Stop the sensor
        socketPIsensor.emit("ContinousPressureStreamStop");

        //Let's calculate some usefull test informations :
        //Let's store the Pressure values and the Time in a exploitable Jstat format :
        var ResX = [],
            ResY = [];
        Ref_FullChartData.current.forEach((elem) => {
            ResX.push(elem[0]);
            ResY.push(elem[1]);
        });
        let MaxPressure = jStat.max(ResY);
        let TestDuration = ResX[ResX.length - 1];

        //We need to normalize the time inputs for the graph, raw remains raw

        let DataChart = Ref_FullChartData.current;
        var ChartDataNormalized = [];
        if (Array.isArray(DataChart)) {
            let InitTime = DataChart[0][0];
            DataChart.forEach((elem) => {
                let newElem = [elem[0] - InitTime, elem[1]];
                ChartDataNormalized.push(newElem);
            });
        }
        
		setTestResults({TestDuration: TestDuration.toFixed(1), MaxPressure: MaxPressure.toFixed(2)});
		setShowTestResult(true);
		setShowFinalResult(true);
        //Show the results after 2 seconds
        setTimeout(() => {
            
            setShowFinalResult(true);
            console.log("Ref_FullChartData", Ref_FullChartData.current);
        }, 200);
    };
	const ShareGraphInstance = (Instance) => {
        if (Instance) {
            Ref_GraphInstance.current = Instance;
        }
    };
    const HandleZoomGraph = () => {
		setTimeout(() => {
			if (Ref_GraphInstance.current) {
				// Ref_GraphInstance.current.xAxis[0].setExtremes(0, MixingSealData.ExtremePoints.max.t);
				Ref_GraphInstance.current.yAxis[0].setExtremes(0, TestResults.MaxPressure);
				Ref_GraphInstance.current.redraw();
			}
		}, 500);
	}
	
	
	
	const HandleResetTest = () => {
        Ref_SensorCalibrationData.current = [];
        Ref_RawSensorData.current = [];
        Ref_FullChartData.current = [];
        Ref_ZeroSensorHSC.current = null;

        setPISensorZeroingHSC(null);
        setPhase(null);
        setZeroSensorValueHSC(null);
        setShowSensorReading(false);
        setShowTestResult(false);
        setCurrentSensorValue(false);
        setCurrentChartPressurePointHSC(null);
        setTestResults(null);
        setShowFinalResult(false);
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    var CalibrationResultsComponent = null;

    if (Phase === "CalibrateSensorDone") {
        CalibrationResultsComponent = (
            <div className={classes.Step}>
                <div className={classes.Step_TitleFinal}>
                    <h2>Step 3: Pressure sensor check status : </h2>
                    <CheckBoxCircularFilled SVGFillColor="green" />
                    <Button style={{margin: "0px 0px 0px 10px"}} onClick={HandleStartTest}>
                        Start Comparison
                    </Button>
                </div>

                <div className={classes.Step_Content}>
                    <div className={classes.ConnectionCheck}>
                        <p>PeelSeals pressure test device ID : "whatever"</p>
                        <p style={{margin: "5px 10px 0px 10px"}}>-</p>
                        <p>Connection is : ok</p>
                        <p style={{margin: "5px 10px 0px 10px"}}>-</p>
                        <p>Sensor Zeroing value: {PISensorZeroingHSC.mean.toFixed(3)} mbar</p>
                    </div>
                    <div className={classes.ConnectionCheck}>
                        <p>Mean Absolute Pressure : {PISensorZeroingHSC.mean.toFixed(3)} mbar</p>
                        <p style={{margin: "5px 10px 0px 10px"}}>-</p>
                        <p>Min Absolute Pressure : {PISensorZeroingHSC.min.toFixed(3)} mbar</p>
                        <p style={{margin: "5px 10px 0px 10px"}}>-</p>
                        <p>Max Absolute Pressure : {PISensorZeroingHSC.max.toFixed(3)} mbar</p>
                    </div>
                    <div className={classes.ConnectionCheck}>
                        <p>Standard Deviation : {PISensorZeroingHSC.stdv.toFixed(3)} mbar</p>
                        <p style={{margin: "5px 10px 0px 10px"}}>-</p>
                        <p> DeltaMaxMin : {PISensorZeroingHSC.deltaMaxMin.toFixed(3)} mbar</p>
                    </div>
                </div>
            </div>
        );
    }

    var SensorDataComponent;
    var SensorValuesComponent;
    if (ShowTestResult === false) {
        SensorValuesComponent = (
            <div className={classes.SensorData}>
                <div className={classes.SensorDisplay}>
                    <div>Time</div>
                    <div className={classes.SensorDisplayValue}>{CurrentSensorValue.Time_s}</div>
                    <div>s</div>
                </div>
                <div className={classes.SensorDisplay}>
                    <div>Pressure</div>
                    <div className={classes.SensorDisplayValue}>{CurrentSensorValue.Pressure_mbar}</div>
                    <div>mbar</div>
                </div>
            </div>
        );
    } else {
        SensorValuesComponent = (
            <div className={classes.SensorData}>
                <div className={classes.SensorDisplay}>
                    <div>Test duration</div>
                    <div className={classes.SensorDisplayValue}>{TestResults.TestDuration}</div>
                    <div>s</div>
                </div>
                <div className={classes.SensorDisplay}>
                    <div>Maximum pressure </div>
                    <div className={classes.SensorDisplayValue}>{TestResults.MaxPressure}</div>
                    <div>mbar</div>
                </div>
            </div>
        );
    }
    if (ShowSensorReading === true && ShowFinalResult === false) {
        console.log("displaying current graph");
        SensorDataComponent = (
            <React.Fragment>
                {SensorValuesComponent}
                <GeneralGraph AddPoint={CurrentChartPressurePointHSC} ShowFullChart={false} FullChartData={Ref_FullChartData.current} ChartHeight="400px" />
                <Button onClick={HandleTestCompleted}>Test completed</Button>
            </React.Fragment>
        );
    } else {
        SensorDataComponent = null;
    }

    var FinalResultsComponent;
    if (ShowFinalResult === true) {
        console.log("updating the final chart", Ref_FullChartData.current);
        FinalResultsComponent = (
            <div className={classes.FinalResult_WidthEvaluation}>
                <div className={classes.GraphResults}>
                    <h3>Pressure graph</h3>
                    {SensorValuesComponent}
                    <GeneralGraph AddPoint={null} ShowFullChart={true} FullChartData={Ref_FullChartData.current} ChartHeight="400px" EnableMouseHover={true} ShareGraphInstance={ShareGraphInstance}/>
                </div>
            </div>
        );
    } else {
        FinalResultsComponent = null;
    }

    return (
        <div className={classes.Main}>
            <LocalCircularLoaderIOS
                Loading={Loadertest}
                ModalCardBackground="rgba(0,0,0,0.8)"
                WithModalCard={true}
                FullSize={true}
                SpinnerDimension="50px"
                Message={<div style={{color: "white"}}>Please wait while we check the sensor</div>}
            />
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                Pi Connection : {JSON.stringify(PISensorsSocketConnection)}
                <Button onClick={HandleCalibrateSensor}>Calibrate sensor</Button>
            </div>
            {CalibrationResultsComponent}
            {SensorDataComponent}
            {FinalResultsComponent}
            <Button Background="green" onClick={HandleZoomGraph}>
                Zoom Graph
            </Button>
			<Button Background="red" onClick={HandleResetTest}>
                Reset test
            </Button>
        </div>
    );

    /***************** RENDER ******************/
});

export default PressureSensorComparison;
