import {combineReducers} from "redux";

import authReducer from "../reduxFunctions/auth/authReducer";

const appReducer = combineReducers({
    auth: authReducer,
    // ActivePage: pageTransitionReducer,
    // globalLoader : globalLoaderReducer,
    // SidebarMessages : sidebarMessagesReducer,
});

const rootReducer = (state, action) => {
    return appReducer(state, action);
};

export default rootReducer;
