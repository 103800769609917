import React from "react";
import {createUseStyles, useTheme} from "react-jss";
import {Route, Switch} from "react-router-dom";

import CameraTest from "./CameraTest";
import WeigthVolumeTest from "./WeigthVolumeTest";
import VideoRecording from "./VideoRecording";
import PressureSensorComparison from "./PressureSensorComparison";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

const DevTestingRouter = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <Switch>
            <Route exact path="/dev/camera">
                <CameraTest />
            </Route>
            <Route exact path="/dev/sensorcomparison">
                <PressureSensorComparison />
            </Route>
			<Route exact path="/dev/coninuousstream">
                <WeigthVolumeTest />
            </Route>
			<Route exact path="/dev/videorecording">
                <VideoRecording />
            </Route>

			
        </Switch>
    );
    /***************** RENDER ******************/
};

export default DevTestingRouter;
