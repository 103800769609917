
import React from 'react'
import {createUseStyles, useTheme} from 'react-jss'
 
//eslint-disable-next-line
const styles = createUseStyles(theme => ({
 
}));
 
const AppMobile = props => {
//eslint-disable-next-line
  const theme = useTheme();
//eslint-disable-next-line
  const classes = styles({...props, theme});
 
  return (
	<div>
	  Mobile
	</div>
  )
 
};
 
export default AppMobile;