import io from "socket.io-client";
const DevMode = process.env.NODE_ENV === "development" ? true : false;
// const SocketIOPort = DevMode ? "https://artibulles-pi-server.artibulles.com:3201" : "https://artibulles-pi-server.artibulles.com:3201";
const SocketIOPort = DevMode ? "https://artibulles-pi-server.artibulles.com:3202" : "https://artibulles-pi-server.artibulles.com:3202";

export const socketPIsensor = io(SocketIOPort, {
    withCredentials: true,
    auth: {
        token: "Pi-sensor-need-to-check-how-to-pass-a-secured-token",
    },
});
