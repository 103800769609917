import React from "react";
import {createUseStyles, useTheme} from "react-jss";
import TabNav from "../../../../artibulles-cis/TabNav/TabNav";
import MADevelopment from "./DevelopmentMode/MADevelopment";
import ManualActivationFullBag from "./FullBag/ManualActivationFullBagIntro";
import ManualActivationMain from "./ManualActivationMain";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

const ManualActivationRouter = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    
    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <React.Fragment>
            <TabNav
                MainSlug="/testing/menu/manualactivation"
                DefaultTab="/testing/menu/manualactivation/main"
                ActiveIndicator={{
                    WithIndicator: true,
                    Position: "Bottom",
                }}
                TabMenuContent={[
                    {
                        TabMenu: (
                            <div className={classes.TabMenuDiv}>
                                <div className={classes.TabTitle}>Manual Activation</div>
                            </div>
                        ),
                        Slug: "/testing/menu/manualactivation/main",
                        TabContent: <ManualActivationMain />,
                    },
                    {
                        TabMenu: (
                            <div className={classes.TabMenuDiv}>
                                <div className={classes.TabTitle}>Full Bag</div>
                            </div>
                        ),
                        Slug: "/testing/menu/manualactivation/full",
                        TabContent: <ManualActivationFullBag />,
                    },
                    {
                        TabMenu: (
                            <div className={classes.TabMenuDiv}>
                                <div className={classes.TabTitle}>Mixing Seal</div>
                            </div>
                        ),
                        Slug: "/testing/menu/manualactivation/mixing",
                        TabContent: <ManualActivationMain />,
                    },
                    {
                        TabMenu: (
                            <div className={classes.TabMenuDiv}>
                                <div className={classes.TabTitle}>Missuse</div>
                            </div>
                        ),
                        Slug: "/testing/menu/manualactivation/missuse",
                        TabContent: <ManualActivationMain />,
                    },
                    {
                        TabMenu: (
                            <div className={classes.TabMenuDiv}>
                                <div className={classes.TabTitle}>Development</div>
                            </div>
                        ),
                        Slug: "/testing/menu/manualactivation/development",
                        TabContent: <MADevelopment />,
                    },
                ]}
            />
        </React.Fragment>
    );

    /***************** RENDER ******************/
};

export default ManualActivationRouter;
