import {LOGIN_USER, LOGGEDIN_USER, SIGN_OUT_USER, INREGISTRATIONFALSE} from "./authConstants";
import {createReducer} from "../../reduxStore/reduxReducerUtil.js";

// import axios from "axios";

const initialState = {
    authenticated: false,
    User: {
        id: null,
        PermissionGroups: null,
        isAdmin: false,
        inRegistration: true,

        userProfile: {
            id: null,
            username: null,
            avatar: null,
            email: null,
            firstName: null,
            lastName: null,
        },
    },
};

export const loginUser = (state, payload) => {
    let data = payload;

    let User = {
        id: data.id,
        PermissionGroups: data.PermissionGroups,
        isAdmin: data.isAdmin,
        inRegistration: data.inRegistration ? data.inRegistration : false,
        userProfile: {
            id: data.userProfile.id ? data.userProfile.id : null,
            username: data.userProfile.username ? data.userProfile.username : null,
            avatar: data.userProfile.avatar ? data.userProfile.avatar : null,
            email: data.userProfile.email ? data.userProfile.email : null,
            firstName: data.userProfile.firstName ? data.userProfile.firstName : null,
            lastName: data.userProfile.lastName ? data.userProfile.lastName : null,
        },
    };
    return {
        ...state,
        authenticated: true,
        User: {...state.User, ...User},
    };
};

export const loggedinUser = (state, payload) => {
    return {
        ...state,
        authenticated: true,
        User: {...state.User, ...payload},
    };
};

export const InRegistration = (state) => {
    return {
        ...state,
        User: {...state.User, ...{inRegistration: false}},
    };
};

export const signOutUser = (state, payload) => {
    return {
        ...state,
        ...initialState,
    };
};

export default createReducer(initialState, {
    [LOGIN_USER]: loginUser,
    [LOGGEDIN_USER]: loggedinUser,
    [SIGN_OUT_USER]: signOutUser,
    [INREGISTRATIONFALSE]: InRegistration,
});
