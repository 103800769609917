import React, {useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";

import _ from "lodash";
import FormCompontent from "@artibulles-cis/react/FormComponent";
import Button from "@artibulles-cis/react/Button";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    Form: {
        display: "flex",
        flexDirection: "column",
		textAlign : "left",
		color : "black"
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "4px 0px 4px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
    QuestionWrapper: {
        flex: "1 1 auto",
        flexDirection: "column",
        margin: "10px 0px",
        boxSizing: "border-box",
    },
    Question: {
        display: "flex",
        flexDirection: "column",
    },
    QuestionTitle: {
        margin: "10px 0px",
    },
}));
const OpeningRanking = React.memo(function OpeningRanking(props) {
    const {HandleStoreFullData} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const Ref_FormValues = useRef({
        discardReason: null,
    });

    /***************** CONST ******************/

    /***************** STATE ******************/
    const [BagOpened, setBagOpened] = useState(false);
    const [IssueWhileOpening, setIssueWhileOpening] = useState(null);
    const [DiscardTest, setDiscardTest] = useState(null);
    //eslint-disable-next-line
    const [InitialFormValues, setInitialFormValues] = React.useState({
        mixingseal_notopened_reason: null,
        discardReason: null,

        //DONT FOLD
    });
    const [FormValues, setFormValues] = React.useState({
        mixingseal_notopened_reason: null,
        discardReason: null,
        opening_description: null,
        IssueDescription: null,

        //DONT FOLD
    });
    //eslint-disable-next-line
    const [FieldOptions, setFieldOptions] = React.useState({
        mixingseal_opened: [
            {value: "01", label: "Yes"},
            {value: "02", label: "No"},
        ],
        issue: [
            {value: "01", label: "Yes"},
            {value: "02", label: "No"},
        ],
        discard: [
            {value: "01", label: "Yes"},
            {value: "02", label: "No"},
        ],
        opening_difficulty: [
            {value: "01", label: "Very Easy"},
            {value: "02", label: "Easy"},
            {value: "03", label: "I don't know"},
            {value: "04", label: "Hard"},
            {value: "05", label: "Very Hard"},
        ],
    }); //FORM OPTIONS FOR SELECT
    const [PristineDetails, setPristineDetails] = useState({});
    //eslint-disable-next-line
    const [Pristine, setPristine] = useState(true); //Form has been edited by the user -> Pristie === false
    //eslint-disable-next-line
    const [Invalid, setInvalid] = useState(false); //Form has invalid fields

    const [FormErrors, setFormErrors] = useState({}); //HNDLING FORM ERRORS
    //eslint-disable-next-line
    const [FormErrorMain, setFormErrorMain] = useState(); //HNDLING FORM ERROR GLOBAL MESSAGE

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleUpdateFormValues = (field, values) => {
        let value = values.Value;

        let FieldPristine = values.Pristine;

        //Checking Pristine

        // Update the pristine based on the Field
        var FinalPristine = true;
        let PristineKeys = _.keys(PristineDetails);
        let UpdatedPristineDetails = PristineDetails;

        if (PristineKeys && PristineKeys.length > 0) {
            //Going through all the pristine fields to check if one of them is false
            var CurrentFieldHandled = false;
            PristineKeys.forEach((key) => {
                if (key === field) {
                    CurrentFieldHandled = true;
                    //Update the pristine value
                    UpdatedPristineDetails[field] = FieldPristine;
                    if (FieldPristine === false) {
                        FinalPristine = false;
                    }
                } else {
                    //just checking if false;
                    if (UpdatedPristineDetails[key] === false) {
                        FinalPristine = false;
                    }
                }
            });
            if (CurrentFieldHandled === false) {
                //The key wasn't there so we need to add it
                if (FieldPristine === false) {
                    UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                    FinalPristine = false;
                }
            }
        } else {
            //First field to be touched
            if (FieldPristine === false) {
                UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                FinalPristine = false;
            }
        }
        setPristineDetails(UpdatedPristineDetails);
        setFormValues({...FormValues, ...{[field]: value}});
        Ref_FormValues.current = {...FormValues, ...{[field]: value}};
        setPristine(FinalPristine);
    };
    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/
    const handleSelectChange = (field, SelectedDetails) => {
        //Hanlding the select change

        if (field === "mixingseal_opened") {
            if (SelectedDetails.Value === "01") {
                setBagOpened(true);
            } else {
                setBagOpened(false);
            }
        }
        if (field === "issue") {
            if (SelectedDetails.Value === "01") {
                setIssueWhileOpening(true);
            } else {
                setIssueWhileOpening(false);
            }
        }
        if (field === "discard") {
            if (SelectedDetails.Value === "01") {
                setDiscardTest(true);
            } else {
                setDiscardTest(false);
            }
        }

        //Checking Pristine

        let FieldPristine = SelectedDetails.Pristine;
        let SelectedValues = SelectedDetails.Value;

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/
        var FinalPristine = true;

        //Checking Pristine using the FieldResponse
        let PristineKeys = _.keys(PristineDetails);
        let UpdatedPristineDetails = PristineDetails;

        if (PristineKeys && PristineKeys.length > 0) {
            //Going through all the pristine fields to check if one of them is false
            var CurrentFieldHandled = false;
            PristineKeys.forEach((key) => {
                if (key === field) {
                    CurrentFieldHandled = true;
                    //Update the pristine value
                    UpdatedPristineDetails[field] = FieldPristine;
                    if (FieldPristine === false) {
                        FinalPristine = false;
                    }
                } else {
                    //just checking if false;
                    if (UpdatedPristineDetails[key] === false) {
                        FinalPristine = false;
                    }
                }
            });
            if (CurrentFieldHandled === false) {
                //The key wasn't there so we need to add it
                if (FieldPristine === false) {
                    UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                    FinalPristine = false;
                }
            }
        } else {
            //First field to be touched
            if (FieldPristine === false) {
                UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                FinalPristine = false;
            }
        }
        setPristineDetails(UpdatedPristineDetails);
        setPristine(FinalPristine);
        var UpdatedFormValues = {...FormValues, ...{[field]: SelectedValues}};
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        /************* IF NO CONDITIONAL SELECT USE THIS *****************/
        setFormValues(UpdatedFormValues);
        /************* IF NO CONDITIONAL SELECT USE THIS *****************/

        /************* CONDITIONAL SELECT *****************/
        //To prevent having to deal with different server configuration, the logic to implement here must be tailored to your needs

        /*Option 1 :
         *Updating the options of a subfield based on the select value ->
         *If you use this, don't forget to specify that the conditional field is disabled if the parentField is empty or null
         */
    };
    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/
    //eslint-disable-next-line
    const HandleUpdateErrors = (field, error) => {
        // console.log("HandleUpdateErrors", field, error);
        //We need to check if there is an error or not
        const ErrorsKeys = _.keys(FormErrors);
        var FinalErrors = {};
        var InvalidTemp = false;
        if (ErrorsKeys && ErrorsKeys.length > 0) {
            // If there is an error
            ErrorsKeys.forEach((key) => {
                if (key !== field) {
                    if (FormErrors[key]) {
                        //Add the error in the list
                        FinalErrors = {...FinalErrors, ...{[key]: FormErrors[key]}};
                        InvalidTemp = true;
                    }
                }
            });
        }

        if (error) {
            //Add the error in the list
            FinalErrors = {...FinalErrors, ...{[field]: error}};
            InvalidTemp = true;
        }
        // console.log("InfalErrors", FinalErrors);

        setFormErrors(FinalErrors);

        setInvalid(InvalidTemp);
    };
    const HandleSubmit = (type) => {
        // console.log("submit", FormValues);
        if (type === "Seal not opened") {
            //Store the reason and stop the test
            HandleStoreFullData(
                {
                    AccessSealData: {
                        SealOpened: false,
                        sealNotopenedReason: FormValues.mixingseal_notopened_reason,
                    },
                },
                "Access Final"
            );
        } else if (type === "Discard No Pressure Data") {
            //Don't save the access pressure data and save the test
            HandleStoreFullData(
                {
                    AccessSealData: {
                        SealOpened: true,
                        Issue: true,
                        IssueDescription: FormValues.IssueDescription,
                        DiscardReason: FormValues.discardReason,
                    },
                },
                "Access Final"
            );
        } else if (type === "Final") {
            //Store data and move to access seal
            let EaseOfOpeningRanking = _.find(FieldOptions.opening_difficulty, {value: FormValues.opening_difficulty});
            HandleStoreFullData(
                {
                    AccessSealData: {
                        SealOpened: true,
                        Issue: false,
                        DiscardTest: false,
                        DiscardReason: null,
                        EaseOfOpeningRanking: EaseOfOpeningRanking.label,
                        OpeningDescription: FormValues.opening_description,
                    },
                },
                "Access Final"
            );
        }
    };

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    var SumbitComponent = null;

    if (BagOpened === false && FormValues.mixingseal_opened) {
        SumbitComponent = (
            <Button style={{margin: "5px 0px"}} Width="250px" onClick={() => HandleSubmit("Seal not opened")} disabled={FormValues.mixingseal_notopened_reason ? false : true}>
                Stop the test
            </Button>
        );
    } else if (FormValues.issue === "01") {
        if (FormValues.discard === "02" && FormValues.opening_difficulty && FormValues.opening_description) {
            SumbitComponent = (
                <Button style={{margin: "5px 0px"}} Width="250px" onClick={() => HandleSubmit("Access Seal")}>
                    Submit results Not full
                </Button>
            );
        }
    } else if (FormValues.issue === "02" && FormValues.opening_difficulty && FormValues.opening_description) {
        SumbitComponent = (
            <Button style={{margin: "5px 0px"}} Width="250px" onClick={() => HandleSubmit("Final")}>
                Submit results full
            </Button>
        );
    } else {
        SumbitComponent = null;
    }

    return (
        <div className={classes.Form}>
            <div className={classes.QuestionWrapper}>
                <div className={classes.QuestionTitle}>Were you able to open the bag?</div>
                <div className={classes.Question}>
                    <div style={{width: "150px", margin: "2px 10px"}}>
                        <FormCompontent
                            Name="mixingseal_opened"
                            InitialValue={InitialFormValues.mixingseal_opened ? InitialFormValues.mixingseal_opened : null}
                            FormValue={FormValues.mixingseal_opened ? FormValues.mixingseal_opened : null}
                            OutputSelectedValues={(values) => handleSelectChange("mixingseal_opened", values)}
                            SelectOptions={FieldOptions.mixingseal_opened}
                            Component="Select"
                            Variant="Outlined"
                            ShowLabel={false}
                            Label="Bag Opened"
                            NoMessage={true}
                            MultiSelect={false}
                            Required={true}
                            InputTextStyle={{fontSize: "0.9rem"}}
                        />
                    </div>
                </div>
            </div>
            <div className={classes.QuestionWrapper} style={{display: BagOpened === false && FormValues.mixingseal_opened ? "flex" : "none"}}>
                <div className={classes.QuestionTitle}>Please explain why you were not able to open the bag ?</div>
                <div className={classes.Question}>
                    <div style={{width: "500px", margin: "2px 10px"}}>
                        <FormCompontent
                            Name="mixingseal_notopened_reason"
                            InitialValue={InitialFormValues.mixingseal_notopened_reason ? InitialFormValues.mixingseal_notopened_reason : null}
                            FormValue={FormValues.mixingseal_notopened_reason ? FormValues.mixingseal_notopened_reason : null}
                            OutputValue={(values) => HandleUpdateFormValues("mixingseal_notopened_reason", values)}
                            Component="Input"
                            Variant="Outlined"
                            ShowLabel={false}
                            Label="Explanation"
                            NoMessage={true}
                            Required={true}
                            InputTextStyle={{fontSize: "0.9rem"}}
                        />
                    </div>
                </div>
            </div>

            <div className={classes.QuestionWrapper} style={{display: BagOpened === true ? "flex" : "none"}}>
                <div className={classes.QuestionTitle}>Was there an issue while opening the bag?</div>
                <div className={classes.Question}>
                    <div style={{width: "150px", margin: "2px 10px"}}>
                        <FormCompontent
                            Name="issue"
                            InitialValue={InitialFormValues.issue ? InitialFormValues.issue : null}
                            FormValue={FormValues.issue ? FormValues.issue : null}
                            OutputSelectedValues={(values) => handleSelectChange("issue", values)}
                            SelectOptions={FieldOptions.issue}
                            Component="Select"
                            Variant="Outlined"
                            ShowLabel={false}
                            Label="Bag Opened"
                            NoMessage={true}
                            MultiSelect={false}
                            Required={true}
                            InputTextStyle={{fontSize: "0.9rem"}}
                        />
                    </div>
                </div>
            </div>

            <div className={classes.QuestionWrapper} style={{display: IssueWhileOpening === true ? "flex" : "none"}}>
                <div className={classes.QuestionTitle}>Should we discard the access seal results?</div>
                <div className={classes.Question}>
                    <div style={{width: "150px", margin: "2px 10px"}}>
                        <FormCompontent
                            Name="discard"
                            InitialValue={InitialFormValues.discard ? InitialFormValues.discard : null}
                            FormValue={FormValues.discard ? FormValues.discard : null}
                            OutputSelectedValues={(values) => handleSelectChange("discard", values)}
                            SelectOptions={FieldOptions.discard}
                            Component="Select"
                            Variant="Outlined"
                            ShowLabel={false}
                            Label="Bag Opened"
                            NoMessage={true}
                            MultiSelect={false}
                            Required={true}
                            InputTextStyle={{fontSize: "0.9rem"}}
                        />
                    </div>
                </div>
            </div>
            <div className={classes.QuestionWrapper} style={{display: IssueWhileOpening === true ? (DiscardTest === true ? "flex" : "none") : "none"}}>
                <div className={classes.QuestionTitle}>Provide the reason for discarding the results</div>
                <div className={classes.Question} style={{display: "flex"}}>
                    <div style={{margin: "0px 10px 0px 10px", width: "350px", display: "flex"}}>
                        <FormCompontent
                            Name="discardReason"
                            InitialValue={InitialFormValues.discardReason ? InitialFormValues.discardReason : null}
                            FormValue={FormValues.discardReason ? FormValues.discardReason : null}
                            OutputValue={(values) => HandleUpdateFormValues("discardReason", values)}
                            Component="Input"
                            Variant="Outlined"
                            Label="discardReason"
                            NoMessage={true}
                            // multiline={true}
                            RowsToDisplay="4"
                            MaxRows="4"
                            ShowLabel={false}
                            // OnFieldExit={(status) => HandleFieldExit("AbortReason", status)}
                            // meta={{error: FormErrors.normal, invalid: FormErrors.normal ? true : false}}
                            // OutputError={(error) => HandleUpdateErrors("normal", error)}
                        />
                    </div>
                </div>
            </div>
            <div className={classes.QuestionWrapper} style={{display: IssueWhileOpening === true ? (DiscardTest === false ? "flex" : "none") : "none"}}>
                <div className={classes.QuestionTitle}>Describe the issue</div>
                <div className={classes.Question} style={{display: "flex"}}>
                    <div style={{margin: "0px 10px 0px 10px", width: "350px", display: "flex"}}>
                        <FormCompontent
                            Name="IssueDescription"
                            InitialValue={InitialFormValues.IssueDescription ? InitialFormValues.IssueDescription : null}
                            FormValue={FormValues.IssueDescription ? FormValues.IssueDescription : null}
                            OutputValue={(values) => HandleUpdateFormValues("IssueDescription", values)}
                            Component="Input"
                            Variant="Outlined"
                            Label="IssueDescription"
                            NoMessage={true}
                            // multiline={true}
                            RowsToDisplay="4"
                            MaxRows="4"
                            ShowLabel={false}
                            // OnFieldExit={(status) => HandleFieldExit("AbortReason", status)}
                            // meta={{error: FormErrors.normal, invalid: FormErrors.normal ? true : false}}
                            // OutputError={(error) => HandleUpdateErrors("normal", error)}
                        />
                    </div>
                </div>
            </div>
            <div className={classes.QuestionWrapper} style={{display: BagOpened === true ? (IssueWhileOpening === false ? "flex" : DiscardTest === false ? "flex" : "none") : "none"}}>
                <div className={classes.QuestionTitle}>How difficult was the access seal opening?</div>
                <div className={classes.Question}>
                    <div style={{width: "150px", margin: "2px 10px"}}>
                        <FormCompontent
                            Name="opening_difficulty"
                            InitialValue={InitialFormValues.opening_difficulty ? InitialFormValues.opening_difficulty : null}
                            FormValue={FormValues.opening_difficulty ? FormValues.opening_difficulty : null}
                            OutputSelectedValues={(values) => handleSelectChange("opening_difficulty", values)}
                            SelectOptions={FieldOptions.opening_difficulty}
                            Component="Select"
                            Variant="Outlined"
                            ShowLabel={false}
                            Label="Bag Opened"
                            NoMessage={true}
                            MultiSelect={false}
                            Required={true}
                            InputTextStyle={{fontSize: "0.9rem"}}
                        />
                    </div>
                </div>
            </div>
            <div className={classes.QuestionWrapper} style={{display: BagOpened === true ? (IssueWhileOpening === false ? "flex" : DiscardTest === false ? "flex" : "none") : "none"}}>
                <div className={classes.QuestionTitle}>What process did you follow to open the access seal?</div>
                <div className={classes.Question} style={{minHeight: "20px"}}>
                    <div style={{margin: "0px 10px 0px 10px", width: "350px", display: "flex", flex: "1 1 auto"}}>
                        <FormCompontent
                            Name="opening_description"
                            InitialValue={InitialFormValues.opening_description ? InitialFormValues.opening_description : null}
                            FormValue={FormValues.opening_description ? FormValues.opening_description : null}
                            OutputValue={(values) => HandleUpdateFormValues("opening_description", values)}
                            Component="Input"
                            Variant="Outlined"
                            Label="opening_description"
                            NoMessage={true}
                            // multiline={true}
                            RowsToDisplay="4"
                            MaxRows="4"
                            ShowLabel={false}
                            // OnFieldExit={(status) => HandleFieldExit("AbortReason", status)}
                            // meta={{error: FormErrors.normal, invalid: FormErrors.normal ? true : false}}
                            // OutputError={(error) => HandleUpdateErrors("normal", error)}
                        />
                    </div>
                </div>
            </div>
            {SumbitComponent}
        </div>
    );

    /***************** RENDER ******************/
});

export default OpeningRanking;
