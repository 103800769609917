import axios from "axios";

async function ApiCallExternal(args) {
	
    let url = args.url;
    let type = args.type;
    let data = args.data;
    let header = args.header;
    let timeout = args.timeout;

	
    if (type === "get" || !type) {
		
        
        try {
            const response = await axios.get(url, {
                withCredentials: true, //If not cookies won't be set and some requests will fail
                timeout: timeout ? parseInt(timeout) : 5000, //In case the server is not reachable
            });
			
            return response;
        } catch (error) {
			console.log("error",error)
            if (error.response) {
                return {error: true, errorStatus: error.response.status, errorMessage: error.response.data};
            }
            //Because of cors or other events we need to consider that it is a newtork error.
            return {error: true, erroStatus: 404, errorMessage: "no network"};
        }
    } else if (type === "post") {
        try {
            const response = await axios.post(url, data, {
                withCredentials: true, //If not cookies won't be set and some requests will fail
                timeout: timeout ? parseInt(timeout) : 10000, //In case the server is not reachable
                maxContentLength: 30000000,
                maxBodyLength: 30000000,
            });
            // const response = await axios.post(url, data);
            // console.log("error axios", response);
            return response;
        } catch (error) {
            console.log("error axios", error)
            if (error.response) {
                // console.log("error axios", error);
                return {error: true, errorStatus: error.response.status, errorMessage: error.response.data};
            }
            //Because of cors or other events we need to consider that it is a newtork error.
            return {error: true, erroStatus: 404, errorMessage: "no network"};
        }
    } else if (type === "put") {
        try {
            const response = await axios.put(url, data, {
                withCredentials: true, //If not cookies won't be set and some requests will fail
                timeout: timeout ? parseInt(timeout) : 3000, //In case the server is not reachable
                maxContentLength: 5000000000,
                maxBodyLength: 50000000000,
            });

            return response;
        } catch (error) {
            if (error.response) {
                return {error: true, errorStatus: error.response.status, errorMessage: error.response.data};
            }
            //Because of cors or other events we need to consider that it is a newtork error.
            return {error: true, erroStatus: 404, errorMessage: "no network"};
        }
    } else if (type === "delete") {
        try {
            const response = await axios.delete(url, {
                withCredentials: true, //If not cookies won't be set and some requests will fail
                timeout: timeout ? parseInt(timeout) : 3000, //In case the server is not reachable
            });

            return response;
        } catch (error) {
            if (error.response) {
                return {error: true, errorStatus: error.response.status, errorMessage: error.response.data};
            }
            //Because of cors or other events we need to consider that it is a newtork error.
            return {error: true, erroStatus: 404, errorMessage: "no network"};
        }
    }
}

export default ApiCallExternal;
