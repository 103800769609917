/* eslint-disable */
import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import {socketPIsensor} from "../../../../../common/util/socketPIsensor";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import Button from "@artibulles-cis/react/Button";
import CardModal from "@artibulles-cis/react/CardModal";
import CameraPreview from "./CameraPreview";
import OpeningRanking from "./OpeningRankingAccessSealSeal";
import GeneralGraph from "../../GeneralGraph";
const {jStat} = require("jstat");
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    Main: {
        color: "black",
        textAlign: "left",
    },
    Step: {
        borderBottom: "1px solid grey",
    },
    Step_Title: {
        "& h2": {margin: "5px 0px"},
    },
    Step_TitleFinal: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        marginTop: "10px",
        "& h2": {margin: "0px"},
    },
    Step_Content: {
        padding: "10px 20px",
    },
    Step2_Wrapper: {
        display: "flex",
    },
    Step2_Left: {
        flex: "0 0 400px",
    },
    Step2_Right: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        padding: "0px 0px 0px 20px",
        alignItems: "flex-start",
    },
    Description_Picture_Wrapper: {
        display: "flex",
    },
    Description_Picture: {
        width: "300px",
        height: "auto",
    },
    Instructions: {
        fontSize: "1.2rem",
        "& li": {
            margin: "5px 0px",
        },
        boxSizing: "border-box",
        borderBottom: "1px solid grey",
    },
    CameraToolbar: {
        display: "flex",
        alignItems: "center",
    },
    SensorData: {
        display: "flex",
        justifyContent: "center",
        flex: "1 0 auto",
        maxWidth: "750px",
        margin: "10px auto",
    },
    SensorDisplay: {
        display: "flex",
        flex: "1 0 auto",
        justifyContent: "center",
        margin: "0px 0px",
        boxSizing: "border-box",
        height: "30px",
        alignItems: "center",
        fontSize: "1.0rem",
        fontWeight: "500",
    },
    SensorDisplayValue: {
        display: "flex",
        flex: "1 0 100px",
        maxWidth: "150px",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        margin: "0 10px",
        boxSizing: "border-box",
        border: "1px solid grey",
        borderRadius: "5px",
        height: "100%",
    },
    FinalResult_WidthEvaluation: {
        display: "flex",
        width: "100%",
        margin: "10px 0px 0px 0px",
        "& h3": {
            margin: "0px auto",
        },
    },
    GraphResults: {
        flex: "0 0 600px",
        boxSizing: "border-box",
        padding: "10px",
        border: "1px solid grey",
        borderRadius: "10px",
        margin: "0px 5px",
    },
    Evaluation: {
        flex: "1 1 auto",
        boxSizing: "border-box",
        padding: "10px",
        border: "1px solid grey",
        borderRadius: "10px",
        margin: "0px 5px",
    },
    EvaluationContent: {
        display: "flex",
        flexDirection: "column",

        padding: "10px 20px",
    },
    ClassExtendTopToolBar: {
        backgroundColor: "grey",
        justifyContent: "center",
        height: "30px",
        flex: "0 0 35px",
    },
    CardExtended: {
        borderRadius: "10px",
    },
    StartestToolbar: {
        display: "flex",
        justifyContent: "center",
        marginBottom: "15px",
    },
}));

const ManualActivationFullBagStep3 = React.memo(function ManualActivationFullBagStep3(props) {
    const {ZeroSensorValue, HandleStoreFullData, SampleID, ProtocolID} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const Ref_RawSensorData = useRef([]); //Storing the raw sensor data;
    const Ref_FullChartData = useRef([]); //Storing the Full chart data;
    const Ref_ChartSampling = useRef(0);
    const Ref_TimeOutResultsAccessReceived = useRef(null);

    /***************** CONST ******************/

    /***************** STATE ******************/
    const [showLoading, setshowLoading] = useState(false); //if loading
    const [ShowInstructions, setShowInstructions] = useState(true); //hide the instructions when the test is running
    const [ShowSensorReading, setShowSensorReading] = useState(false); //display the sensor reading
    const [ShowTestResult, setShowTestResult] = useState(false); //display the temp test results
    const [ShowFinalResult, setShowFinalResult] = useState(false); //display the test results
    const [PISensorsSocketConnection, setPISensorsSocketConnection] = useState(false); //Checking the PI sensor Socket Connection
    const [SensorSamplingRate, setSensorSamplingRate] = useState(20); // Sample rate of the sensor (200ms)
    const [GraphRefreshRate, setGraphRefreshRate] = useState(100); // GraphRefresh Rate
    const [CurrentSensorValue, setCurrentSensorValue] = useState({Time_s: null, Pressure_mbar: null});
    const [CurrentChartPressurePoint, setCurrentChartPressurePoint] = useState(null);
    const [TestResults, setTestResults] = useState(null);
    const [ShowCameraModal, setShowCameraModal] = useState(false);
    const [CameraPreviewImgSrc, setCameraPreviewImgSrc] = useState(null);
    const [UseCamera, setUseCamera] = useState(false);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const StoreSensorData = useCallback(
        (data) => {
            let DataPointCount = (Ref_ChartSampling.current = Ref_ChartSampling.current + 1);

            var CurrentEntries = [];

            if (Ref_RawSensorData.current && Array.isArray(Ref_RawSensorData.current)) {
                CurrentEntries = Ref_RawSensorData.current;
            }
            //Storing Raw Data as backup
            let NewEntry = data;
            CurrentEntries.push(NewEntry);

            //Storing the current value

            //Adding a point to the graph //Based on the graph refresh rate
            // GraphRefreshRate
            const CurrentPressurePoint = [data.TimeMs / 1000, parseFloat(data.Pressure - ZeroSensorValue)];
            //Storing the data in the chart
            Ref_FullChartData.current.push(CurrentPressurePoint);

            // if (DataPointCount > GraphRefreshRate / SensorSamplingRate) {
            //We pass the point to th chart and we reset the counter
            setCurrentChartPressurePoint(CurrentPressurePoint);
            //     Ref_ChartSampling.current = 0;
            // }

            setCurrentSensorValue({Time_s: (data.TimeMs / 1000).toFixed(1), Pressure_mbar: (data.Pressure - ZeroSensorValue).toFixed(2)});
        },
        [ZeroSensorValue]
    );

    const HandleTestCompletedCallback = useCallback((imgpaths) => {
        //Let's calculate some usefull test informations :
        //Let's store the Pressure values and the Time in a exploitable Jstat format :
        var ResX = [],
            ResY = [];
        Ref_FullChartData.current.forEach((elem) => {
            ResX.push(elem[0]);
            ResY.push(elem[1]);
        });
        let MaxPressure = jStat.max(ResY);
        let TestDuration = ResX[ResX.length - 1];

        setTestResults({TestDuration: TestDuration.toFixed(1), MaxPressure: MaxPressure.toFixed(2)});

        setShowTestResult(true);

        //We need to normalize the time inputs for the graph, raw remains raw

        let DataChart = Ref_FullChartData.current;
        var ChartDataNormalized = [];
        if (Array.isArray(DataChart)) {
            let InitTime = DataChart[0][0];
            DataChart.forEach((elem) => {
                let newElem = [elem[0] - InitTime, elem[1]];
                ChartDataNormalized.push(newElem);
            });
        }
        HandleStoreFullData({
            AccessSealData: {
                RawSensorData: Ref_RawSensorData.current,
                GraphSensorData: ChartDataNormalized,
                TestDuration: TestDuration.toFixed(1),
                MaxPressure: MaxPressure.toFixed(2),
                ImagesPaths: imgpaths,
            },
        });

        //Show the results after 2 seconds
        setTimeout(() => {
            setShowFinalResult(true);
        }, 1200);
    }, []);

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        //Requesting connection to the PI-Sensors Socket Server
        socketPIsensor.emit("RequestConnection", {Component: "FullBagTestStep3"});
        //Receiving confirmation
        const HandlePISensorConnection = () => setPISensorsSocketConnection(true);

        socketPIsensor.on("UserConnected", (data) => {
            HandlePISensorConnection();
        });

        //Receiving data from the sensor
        socketPIsensor.on("PressureSensorData", (data) => {
            StoreSensorData(data);
        });

        //If camera was active, the pi is sending back the paths of all images
        socketPIsensor.on("ImagesPathsToStore", (inputs) => {
            let imgpaths = inputs.imgpaths;
            let chamber = inputs.chamber;
            if (chamber === "access") {
                HandleTestCompletedCallback(imgpaths);
                setshowLoading(false);
                //we clear the timeout for recovery, everything went fine
                if (Ref_TimeOutResultsAccessReceived.current) {
                    console.log("clearing the recovery timeout, data uplaoded");
                    clearTimeout(Ref_TimeOutResultsAccessReceived.current);
                }
            }
        });

        //Streaming the camera
        socketPIsensor.on("CameraStream", (data) => {
            setCameraPreviewImgSrc(data);
        });
        return () => {
            socketPIsensor.off("UserConnected", HandlePISensorConnection);
        };
    }, []);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleStartTest = (camoption) => {
        setShowInstructions(false);
        //We update the final use cam to be able to pass it when stopping the test and preventing sending files to the server
        if (camoption === "with cam") {
            setUseCamera(true);
        } else {
            setUseCamera(false);
        }

        //Ask the sensor to send the data
        socketPIsensor.emit("StreamSensorData", {
            SampleId: SampleID ? SampleID : "NoSample",
            ProtocolId: ProtocolID ? ProtocolID : "NoPrtotocol",
            SampleRate: SensorSamplingRate,
            WithCamera: camoption === "with cam" ? true : false,
            ZeroSensor: ZeroSensorValue,
            chamber: "access",

            cameraOptions: {
                // width: 1280,
                // height: 720,
                width: 1332,
                height: 990,
                camerafps: 60,
                StreamRate: 20, //take a picture every StreamRate ms
            },
        });
        setShowSensorReading(true);
    };
    const HandleTestCompleted = () => {
        //Stop the sensor
        socketPIsensor.emit("StopSensorData", {SampleId: SampleID, ProtocolId: ProtocolID, WithCamera: UseCamera, chamber: "access"});
        setshowLoading(true);
        //We put a timer here to recover if the axios post failed or if something else went wrong on the PI
        Ref_TimeOutResultsAccessReceived.current = setTimeout(() => {
            console.log("entering recovery mode");
            if (Ref_TimeOutResultsAccessReceived.current) {
                console.log("timeout still exist");
                console.log("please recover");
                //we clear the loader
                socketPIsensor.emit("recover sample data", {SampleId: SampleID, ProtocolId: ProtocolID, WithCamera: UseCamera, chamber: "access"});
            }
        }, 5000);
    };
    const HandleCheckCamera = () => {
        //Request streaming the camera
        let PicRatio = 3 / 4;
        // const CameraOptions = {
        //     width: 400,
        //     height: PicRatio * 400,
        //     fps: 40,
        //     StreamRate: 40, //ms
        // };
        const CameraOptions = {
            // width: 1280,
            // height: 720,
            width: 1332,
            height: 990,
            camerafps: 60,
            StreamRate: 20, //take a picture every StreamRate ms
        };
        socketPIsensor.emit("StartStreamCameraFrames", CameraOptions);

        setShowCameraModal(true);
    };
    const HandleCloseCameraCard = () => {
        setShowCameraModal(false);
        //Stop streaming the camera
        socketPIsensor.emit("StopStreamCameraFrames");
    };

    const HandleShareCameraConfig = (config) => {
        if (config === "use camera") {
            setUseCamera(true);
        } else {
            setUseCamera(false);
        }
    };

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    var InstructionComponent;
    if (ShowInstructions === true) {
        InstructionComponent = (
            <React.Fragment>
                <div className={classes.Step_Content}>
                    <div className={classes.Step2_Wrapper}>
                        <div className={classes.Step2_Left}>
                            <div className={classes.Description_Picture_Wrapper}>
                                <img className={classes.Description_Picture} src="/images/PeelSealsPressureTester_V1.svg" alt="TestDescription" />
                            </div>
                        </div>

                        <div className={classes.Step2_Right}>
                            <ul className={classes.Instructions}>
                                <li>Please check again that the connections are secured and that there are no leaks</li>
                            </ul>
                            <div className={classes.CameraToolbar}>
                                <div style={{display: "flex", flexDirection: "column"}}>
                                    <h3 style={{margin: " 0px 20px 0px 0px"}}>If you want to use the camera, please check first that it's working properly</h3>
                                    <p style={{margin: "0px", fontStyle: "italic"}}>The camera is inactive by default to prevent recording error</p>
                                </div>

                                <Button onClick={HandleCheckCamera}>Check camera</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.StartestToolbar}>
                    <Button style={{margin: "0px 5px 0px 0px", display: UseCamera === true ? "flex" : "none"}} Width="300px" onClick={() => HandleStartTest("with cam")}>
                        Start test with camera
                    </Button>
                    <Button Background="orange" style={{margin: "0px 0px 0px 5px"}} Width="300px" onClick={() => HandleStartTest("without cam")}>
                        Start test without camera
                    </Button>
                </div>
            </React.Fragment>
        );
    } else {
        InstructionComponent = null;
    }
    var SensorDataComponent;
    var SensorValuesComponent;
    if (ShowTestResult === false) {
        SensorValuesComponent = (
            <div className={classes.SensorData}>
                <div className={classes.SensorDisplay}>
                    <div>Time</div>
                    <div className={classes.SensorDisplayValue}>{CurrentSensorValue.Time_s}</div>
                    <div>s</div>
                </div>
                <div className={classes.SensorDisplay}>
                    <div>Pressure</div>
                    <div className={classes.SensorDisplayValue}>{CurrentSensorValue.Pressure_mbar}</div>
                    <div>mbar</div>
                </div>
            </div>
        );
    } else {
        SensorValuesComponent = (
            <div className={classes.SensorData}>
                <div className={classes.SensorDisplay}>
                    <div>Test duration</div>
                    <div className={classes.SensorDisplayValue}>{TestResults.TestDuration}</div>
                    <div>s</div>
                </div>
                <div className={classes.SensorDisplay}>
                    <div>Maximum pressure </div>
                    <div className={classes.SensorDisplayValue}>{TestResults.MaxPressure}</div>
                    <div>mbar</div>
                </div>
            </div>
        );
    }
    if (ShowSensorReading === true && ShowFinalResult === false) {
        SensorDataComponent = (
            <React.Fragment>
                {SensorValuesComponent}
                <GeneralGraph AddPoint={CurrentChartPressurePoint} ShowFullChart={ShowTestResult} FullChartData={Ref_FullChartData.current} ChartHeight="400px" />
                <Button onClick={HandleTestCompleted}>Test completed</Button>
            </React.Fragment>
        );
    }

    var FinalResultsComponent;
    if (ShowFinalResult === true) {
        FinalResultsComponent = (
            <div className={classes.FinalResult_WidthEvaluation}>
                <div className={classes.GraphResults}>
                    <h3>Pressure graph</h3>
                    <GeneralGraph AddPoint={null} ShowFullChart={true} FullChartData={Ref_FullChartData.current} ChartHeight="400px" ChartWidth="400px" />
                </div>
                <div className={classes.Evaluation}>
                    <h3>Ease of opening evaluation</h3>
                    <div className={classes.EvaluationContent}>
                        <OpeningRanking HandleStoreFullData={HandleStoreFullData} />
                    </div>
                </div>
            </div>
        );
    } else {
        FinalResultsComponent = null;
    }

    return (
        <div className={classes.Main}>
            <LocalCircularLoader Loading={showLoading} WithModalCard={true} FullSize={true} />
            <CardModal
                // CardID="HealFit_CreateDailyIntake"
                // ParentCompRef={Ref_TabNavContent}
                TopBarContent={<h2>Camera check</h2>}
                ClassExtendTopToolBar={classes.ClassExtendTopToolBar}
                CloseCard={HandleCloseCameraCard}
                // CloseOnClickOutside={true}
                WithCloseButton={true}
                CardMaxWidth="800px"
                CardHeight="700px"
                WithPerfectScrollbar={true}
                CardWindowPaddingVertical="10px"
                CardWindowPaddingHorizontal="10px"
                ShowCard={ShowCameraModal}
                WindowBackgroundColor="rgba(0,0,0,0.6)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.CardExtended}
            >
                <CameraPreview imgSrc={CameraPreviewImgSrc} HandleShareConfig={HandleShareCameraConfig} CloseCardFromInside={HandleCloseCameraCard} />
            </CardModal>
            <div className={classes.Step}>
                <div className={classes.Step_Title}>
                    <h2>Step 5: Access seal activation</h2>
                    {InstructionComponent}
                </div>
            </div>
            {SensorDataComponent}
            {FinalResultsComponent}
        </div>
    );

    /***************** RENDER ******************/
});

export default ManualActivationFullBagStep3;
