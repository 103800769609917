import React, {useEffect, useRef} from "react";
import PropTypes from "prop-types";
// import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

//eslint-disable-next-line
const Lockedstyles = {};

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ChartWrapper: {
        boxSizing: "border-box",
        maxWidth: "100%",
        padding: "10px",
        display: "flex",
        position: "relative",
        overflow: "hidden",
    },
}));

/**
 * Component Description
 */

const HighChartWrapper = React.forwardRef(function HighChartWrapper(props, ref) {
    /****************************** PROPS AND JSS CLASSES *********************/

    const {ChartInputs, ChartInstance, ChartHeight, ChartWidth} = props;

    const theme = useTheme();
    const classes = styles({...props, theme});
    /****************************** PROPS AND JSS CLASSES *********************/

    /***************** REFS ******************/
    const ChartRef = useRef(null);
    const ChartContainerRef = useRef(null);
    const Ref_FinalHeight = useRef(null);
    const Ref_FinalWidth = useRef();
    /***************** REFS ******************/

    //Default container size

    /****************************** STATE *********************/

    /****************************** STATE *********************/

    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** EFFECT *********************/
    //Updating the chart dimensions

    useEffect(() => {
        let TempWidth = ChartWidth ? ChartWidth : null;
        let TempHeight = ChartHeight ? ChartHeight : "400px";

        Ref_FinalHeight.current = TempHeight
        Ref_FinalWidth.current = TempWidth

        if (ChartContainerRef && ChartContainerRef.current) {
            let ChartInstance = ChartRef.current.chart;
            ChartInstance.setSize(100, parseInt(TempHeight));
            var width;
            if (Ref_FinalWidth.current) {
                width = parseInt(TempWidth);
            } else {
                width = ChartContainerRef.current.getBoundingClientRect().width - 40;
            }
            setTimeout(() => {
                ChartInstance.setSize(width, parseInt(TempHeight));
            }, 300);
			window.dispatchEvent(new Event('resize'));
        }
    }, [ChartHeight, ChartWidth,ChartInputs]);

    //Resizing the chart
    useEffect(() => {
        window.addEventListener("resize", HandleWindowResize);
        return function cleanup() {
            window.removeEventListener("resize", HandleWindowResize);
        };
    }, []);

    //Passing the ChartInstance to the ParentComponent
    useEffect(() => {
        if (ChartInstance && ChartRef.current) {
            ChartInstance(ChartRef.current.chart);
        }
    }, [ChartRef, ChartInstance]);

    /****************************** EFFECT *********************/

    /****************************** FUNCTIONS *********************/
    const HandleWindowResize = () => {
        if (ChartContainerRef && ChartContainerRef.current) {
            let ChartInstance = ChartRef.current.chart;
            ChartInstance.setSize(100, parseInt(Ref_FinalHeight.current));
            var width;
            if (Ref_FinalWidth.current) {
                width = parseInt(Ref_FinalWidth.current);
            } else {
                width = ChartContainerRef.current.getBoundingClientRect().width - 40;
            }

            setTimeout(() => {
                ChartInstance.setSize(width, parseInt(Ref_FinalHeight.current));
            }, 200);
        }
    };

    /****************************** FUNCTIONS *********************/

    /****************************** RENDER *********************/
    return (
        <div className={classes.ChartWrapper} style={{height: Ref_FinalHeight.current, flex: Ref_FinalWidth.current ? `0 0 ${Ref_FinalWidth.current}` : "0 1 1500px"}} ref={ChartContainerRef}>
            <HighchartsReact
                ref={ChartRef}
                options={ChartInputs}
                highcharts={Highcharts}
                constructorType={"chart"}
                allowChartUpdate={true}
                immutable={false}
                updateArgs={[true, true, true]}
                // containerProps={{className: HighChartContainer}}
                containerProps={{style: {height: Ref_FinalHeight.current, flex: Ref_FinalWidth.current ? `0 0 ${Ref_FinalWidth.current}` : "0 1 auto"}}}
            />
        </div>
    );
    /****************************** RENDER *********************/
});

HighChartWrapper.defaultProps = {
    ChartInputs: null,
    ChartInstance: null,
};

HighChartWrapper.propTypes = {
    /**
     * ChartInputs :
     */
    ChartInputs: PropTypes.any,
    /**
     * ChartInstance :
     */
    ChartInstance: PropTypes.any,
};

export default HighChartWrapper;
