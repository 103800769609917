/* eslint-disable */
import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import Page from "@artibulles-cis/react/Page";
import {useParams} from "react-router-dom";
import _ from "lodash";
import APICallExternal from "@artibulles-cis/react/utils/APICallExternal";
import FormCompontent from "@artibulles-cis/react/FormComponent";

import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";

import {Calendar} from "@artibulles-cis/react-icons";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

import PictureViewer from "../PictureViewer";

const {format, fromUnixTime} = require("date-fns");
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    PageContentExtends: {
        // background: "blue",
        // backgroundImage: "url('/images/HomePageBackground.jpg')",
        // backgroundSize: "cover",
        // backgroundRepeat: "no-repeat",
        // backgroundPosition: "50% 50%",
        // color: "white",
    },
    Main: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "0px 10px",
        // height : "100%"
    },
    MainInfo: {
        maxWidth: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        boxSizing: "border-box",
        borderBottom: "1px solid grey",
        backgroundColor: "rgb(200,200,200)",
        marginBottom: "10px",
    },
    FormMultilineFlex: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        margin: "4px 0px 4px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
    ToolBarWrapper: {
        width: "100%",
        display: "flex",
        justifyContent: "space-around",
    },
    ChartAndPic: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        paddingBottom: "15px",
    },

    GraphResults: {
        flex: "0 0 500px",
        boxSizing: "border-box",
        padding: "10px",
        border: "1px solid grey",
        borderRadius: "10px",
        margin: "0px 5px",
    },
    PictureWrapper: {
        flex: "0 0 400px",
        // height : "100px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        padding: "10px",
        border: "1px solid grey",
        borderRadius: "10px",
    },
    SliderToolBarWrapper: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "10px 0px 30px 0px",
    },
    SliderWrapper: {
        width: "500px",
        margin: "0px 25px 0px 25px",
    },
    SliderInfo: {
        flex: "0 0 250px",
        display: "flex",
        fontWeight: "500",
    },
    DetailedResults: {
        display: "flex",
        flex: "1 1 auto",
        flexDirection: "column",
        boxSizing: "border-box",
        border: "1px solid grey",
        padding: "10px",
        borderRadius: "5px",
        margin: "10px 0px 0px 30px",
        fontSize: "0.9rem",
    },
    DetailedResults_Line: {
        display: "flex",
        flexDirection: "raw",
    },
    MainInfoToolbar: {
        flex: "1 1 auto",
        width: "100%",
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
        borderBottom: "1px solid grey",
        padding: "0px 0px 10px 0px ",
    },
    SummaryResults: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
    },
    MixingSealResults: {
        display: "flex",
        flex: "1 1 50%",
        flexDirection: "column",
        alignItems: "center",
        boxSizing: "border-box",
        padding: "10px",
        border: "1px solid grey",
        borderRadius: "10px",
        margin: "0px 10px",
    },
    AccessSealResults: {
        display: "flex",
        flex: "1 1 50%",
        flexDirection: "column",
        alignItems: "center",
        boxSizing: "border-box",
        padding: "10px",
        border: "1px solid grey",
        borderRadius: "10px",
        margin: "0px 10px",
    },
    TestSummaryResult_Line: {
        display: "flex",
    },
    TestSummaryResult_Line: {},
    DetailsText: {
        flex: "0 1 auto",
        height: "140px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "0px 0px 0px 30px",
    },
    Details_Row: {
        display: "flex",
        flex: "1 1 auto",
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
    },
    SleepDetails_ColIcon: {
        flex: "0 0 30px",
    },
    SleepDetails_ColTitle: {
        flex: "0 0 85px",
    },
    SleepDetails_ColBarChart: {
        flex: "0 0 1",
    },

    BarEmpty: {
        position: "relative",
        width: "100px",
        height: "15px",
        backgroundColor: "lightgrey",
        borderRadius: "2px",
    },
    BarFilled: {
        position: "absolute",
        borderRadius: "2px",
        top: "0px",
        left: "0px",
        height: "15px",
        backgroundColor: "green",
    },
    BarAvgIndicator: {
        position: "absolute",
        top: "0px",
        height: "15px",
        width: "2px",
        backgroundColor: "black",
    },
    SleepDetails_ColPercentage: {
        flex: "0 0 30px",
        padding: "5px",
    },
    SleepDetails_ColDuration: {
        flex: "0 0 40px",
    },
    SleepANSDetails_ColDuration: {
        flex: "0 0 28px",
        padding: "0px 0px 0px 5px",
    },
    SleepDetails_ColDuration_Avg: {
        flex: "0 0 30px",
        fontSize: "10px",
    },
    GageWrapper: {
        display: "flex",
        alignItems: "center",
    },
    Gage: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        margin: "5px",
    },
    // GageMessage: {
    //     display: "flex",
    //     justifyContent: "center",
    //     textAlign: "center",
    //     wordWrap: "break-word",
    //     width: "130px",
    // },
    GaugeTest: {
        display: "flex",
    },
    TestDetailsContent: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
    },
    ChamberTitle: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
    },
    ChamberTitle_Checkbox: {
        position: "absolute",
        right: "0px",
    },
    ChamberTitleAccess_Checkbox: {
        position: "absolute",
        left: "0px",
    },
}));

const ManualActivation = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const Ref_Page = React.useRef(null);
    const Ref_GraphInstance = useRef(null);
    const Ref_FormValues = useRef({showmixingresults: true, showaccessresults: false});

    const LocationParams = useParams();
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ShowBigPicture, setShowBigPicture] = useState(false);
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [CompReady, setCompReady] = useState(false);
    const [Id, SetId] = useState(LocationParams.id);
    const [ReloadAll, setReloadAll] = useState(false);

    const [Reload, setReload] = useState(true);
    const [InitialFormValues, setInitialFormValues] = useState({});
    const [FormValues, setFormValues] = useState({});

    const [TimePoints, setTimePoints] = useState(null);
	
    const [SliderOptions, setSliderOptions] = useState({
        min: 0,
        max: 100,
        marks: {
            0: 0,
            100: 100,
        },
    });

    const [ImagesPath, setImagesPath] = useState(null);
    const [ImagePathIndex, setImagePathIndex] = useState(0);
    const [ImgSrc, setImgSrc] = useState(null);
    const [CurrentPoint, setCurrentPoint] = useState({t: null});
    const [ReloadGraph, setReloadGraph] = useState(false);

    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = React.useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                console.log("error");
            } else {
                setReload(false);
                let Data = ApiRes.data.data;
                console.log("Data", Data);

                let ImgsPathArr = Data.ImagesPaths;
                var ImgPaths = [];
                if (ImgsPathArr && Array.isArray(ImgsPathArr)) {
                    ImgsPathArr.forEach((elem) => {
                        ImgPaths.push(elem.file);
                    });
                }
                

                let FormData = {
                    Protocol_Id: Data.Protocol_Id,
                    Sample_Id: Data.Sample_Id,
                    OperatorName: Data.OperatorName,
                    test_date: format(fromUnixTime(Data.test_date_Unix), "dd-MMM-yyyy , HH:mm"),
                    TestType: Data.TestType,
                };
                setFormValues(FormData);
                setInitialFormValues(FormData);
                setImagesPath(ImgPaths);
                setImgSrc(ImgPaths[0]);

                let FlowData = Data.FlowData;
                let FinalFlowData = [];
                if (Array.isArray(FlowData) && FlowData.length > 0) {
                    let InitialMS = FlowData[0].TimeMs;
                    for (let i = 0; i < FlowData.length; i++) {
                        let elem = FlowData[i];
                        FinalFlowData.push({Index: elem.Index, TimeSeconds: (elem.TimeMs - InitialMS) / 1000});
                    }
                }
                let IndexMin = 0;
                let IndexMax = FlowData[FlowData.length - 1].Index;
                console.log("IndexMax", IndexMax);
                let minTime = 0;
                let maxTime = FinalFlowData[IndexMax].TimeSeconds;

                let SliderOptions = {
                    min: 0,
                    max: IndexMax,
                    marks: {
                        [IndexMin]: minTime.toFixed(1),
                        [IndexMax]: maxTime.toFixed(1),
                    },
                };
				setTimePoints(FinalFlowData)
                setSliderOptions(SliderOptions);
            }
        }
    }, [ApiRes, Loading]);

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        if (LocationParams.id) {
            if (LocationParams.id !== "default") {
                SetId(LocationParams.id);
                setCompReady(true);
                setLoading(false);
            }
        }
    }, [LocationParams]);

    useEffect(() => {
        if (Reload === true) {
            const ApiCall = {url: `https://artibulles-es-dataserver.artibulles.com/api/pdprotectesting/flowtest/analysis/result/${Id}`, type: "get"};
            // const ApiCall = {url: `http://192.168.1.110:3661/api/pdprotectesting/pressuregraph/123456`, type: "get"};
            setLoading(true);
            // setLoginPhase("OTP code entered");
            async function APIInternal() {
                const res = await APICallExternal(ApiCall);

                setApiRes(res);
                setLoading(false);
            }

            APIInternal();
        }
    }, [Reload]);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleUpdateFormValues = (field, values) => {
        let value = values.Value;
        let formattedValue = values.FormattedValue;

        setFormValues({...FormValues, ...{[field]: value}});
    };
    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/
    const handleSelectChange = (field, SelectedDetails) => {
        //Hanlding the select change
        let SelectedValues = SelectedDetails.Value;

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        //Checking Pristine using the FieldResponse

        var UpdatedFormValues = {...FormValues, ...{[field]: SelectedValues}};
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        /************* IF NO CONDITIONAL SELECT USE THIS *****************/
        setFormValues(UpdatedFormValues);
        /************* IF NO CONDITIONAL SELECT USE THIS *****************/

        /************* CONDITIONAL SELECT *****************/
        //To prevent having to deal with different server configuration, the logic to implement here must be tailored to your needs

        /*Option 1 :
         *Updating the options of a subfield based on the select value ->
         *If you use this, don't forget to specify that the conditional field is disabled if the parentField is empty or null
         */

        //Performing actions when changing the value of the select
    };

    const HandleSliderChange = (index) => {
        // console.log("index", index);
        let CurrPoint = TimePoints[index];
        setCurrentPoint({t: CurrPoint.TimeSeconds});
        setImgSrc(ImagesPath[index]);
        setImagePathIndex(index);
    };

    const HandleShowPreview = () => {
        setShowBigPicture(true);
    };
    const HandleClosePreview = () => {
        setShowBigPicture(false);
    };

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    var PictureComponent = null;
    // https://artibulles-es-dataserver.artibulles.com/api/fileserver/private/capture1.jpg
    PictureComponent = (
        <div className={classes.PictureWrapper}>
            <img onClick={HandleShowPreview} style={{maxWidth: "550px"}} src={ImgSrc} alt="mypic" />
        </div>
    );

    var FinalComponent = null;

    var SliderComponent = null;
    if (ReloadGraph === false) {
        SliderComponent = (
            <div className={classes.SliderWrapper}>
                <Slider railStyle={{backgroundColor: "grey"}} min={SliderOptions.min} max={SliderOptions.max} marks={SliderOptions.marks} onChange={(value) => HandleSliderChange(value)} />
            </div>
        );
    } else {
        SliderComponent = null;
    }

    let PictureViewerComponent = null;
    if (ShowBigPicture === true) {
        PictureViewerComponent = <PictureViewer ShowCard={true} ImgSrc={ImgSrc} HandleCloseViewCard={HandleClosePreview} ImagesPath={ImagesPath} ImagePathIndex={ImagePathIndex} />;
    } else {
        PictureViewerComponent = null;
    }
    if (ReloadAll === false) {
        FinalComponent = (
            <React.Fragment>
                <LocalCircularLoader Loading={Loading} WithModalCard={true} FullSize={true} />

                <div className={classes.MainInfo}>
                    <div className={classes.FormMultilineFlex} style={{justifyContent: "center"}}>
                        <div className={classes.FormField} style={{flex: "0 1 150px"}}>
                            <FormCompontent
                                Name="OperatorName"
                                InitialValue={InitialFormValues.OperatorName ? InitialFormValues.OperatorName : null}
                                FormValue={FormValues.OperatorName ? FormValues.OperatorName : null}
                                Component="Input"
                                Variant="OutlinedLabel"
                                Label="Operator"
                                ReadOnly={true}
                                // InputTextStyle={{fontSize: "12px"}}
                                // FieldMaxWidth="150px"
                                // LabelMaxWidth="30px"
                                // LabelScale="0,8"
                                // AnimateToolBarLeft={true}
                                // AnimateToolbarLeftColor={true}
                                // NoMessage={false}
                                // // FieldMaxWidth="100px"
                                // InternalToolbarLeftIcon={<AvatarV2 IconSize="25px" SVGFillColor="black" />}
                            />
                        </div>
                        <div className={classes.FormField} style={{flex: "0 1 250px"}}>
                            <FormCompontent
                                Name="test_date"
                                InitialValue={InitialFormValues.test_date ? InitialFormValues.test_date : null}
                                FormValue={FormValues.test_date ? FormValues.test_date : null}
                                Component="Input"
                                Variant="OutlinedLabel"
                                Label="Date"
                                NoMessage={false}
                                Required={true}
                                // FieldMaxWidth="200px"
                                InternalToolbarLeftIcon={<Calendar IconSize="25px" SVGFillColor="black" />}
                                AnimateToolBarLeft={true}
                                AnimateToolbarLeftColor={true}
                                ReadOnly={true}
                                // OnFieldExit={(status) => HandleFieldExit("AbortReason", status)}
                                // meta={{error: FormErrors.normal, invalid: FormErrors.normal ? true : false}}
                                // OutputError={(error) => HandleUpdateErrors("normal", error)}
                            />
                        </div>
                        <div className={classes.FormField} style={{flex: "0 1 250px"}}>
                            <FormCompontent
                                Name="Protocol_Id"
                                InitialValue={InitialFormValues.Protocol_Id ? InitialFormValues.Protocol_Id : null}
                                FormValue={FormValues.Protocol_Id ? FormValues.Protocol_Id : null}
                                // OutputValue={(values) => HandleUpdateFormValues("protocol_id", values)}
                                // OutputError={(error) => HandleUpdateErrors("protocol_id", error)}
                                // meta={{error: FormErrors.protocol_id, invalid: FormErrors.protocol_id ? true : false}}
                                Component="Input"
                                Variant="OutlinedLabel"
                                ReadOnly={true}
                                Label="Protocol Id"
                                NoMessage={false}
                                // Required={true}
                                // LocalValidation={{When: "Leaving"}}
                                // DisplayMessageIn="Popup"
                                // FieldMaxWidth="200px"
                            />
                        </div>
                        <div className={classes.FormField} style={{flex: "0 1 250px"}}>
                            <FormCompontent
                                Name="Sample_Id"
                                InitialValue={InitialFormValues.Sample_Id ? InitialFormValues.Sample_Id : null}
                                FormValue={FormValues.Sample_Id ? FormValues.Sample_Id : null}
                                // OutputValue={(values) => HandleUpdateFormValues("protocol_id", values)}
                                // OutputError={(error) => HandleUpdateErrors("protocol_id", error)}
                                // meta={{error: FormErrors.protocol_id, invalid: FormErrors.protocol_id ? true : false}}
                                Component="Input"
                                Variant="OutlinedLabel"
                                ReadOnly={true}
                                Label="Sample Id"
                                NoMessage={true}
                                // Required={true}
                                // LocalValidation={{When: "Leaving"}}
                                // DisplayMessageIn="Popup"
                                // FieldMaxWidth="200px"
                            />
                        </div>
                        <div className={classes.FormField} style={{flex: "0 1 250px"}}>
                            <FormCompontent
                                Name="Protocol_Id"
                                InitialValue={InitialFormValues.TestType ? InitialFormValues.TestType : null}
                                FormValue={FormValues.TestType ? FormValues.TestType : null}
                                // OutputValue={(values) => HandleUpdateFormValues("protocol_id", values)}
                                // OutputError={(error) => HandleUpdateErrors("protocol_id", error)}
                                // meta={{error: FormErrors.protocol_id, invalid: FormErrors.protocol_id ? true : false}}
                                Component="Input"
                                Variant="OutlinedLabel"
                                ReadOnly={true}
                                Label="Test Type"
                                NoMessage={false}
                                // Required={true}
                                // LocalValidation={{When: "Leaving"}}
                                // DisplayMessageIn="Popup"
                                // FieldMaxWidth="200px"
                            />
                        </div>
                    </div>
                </div>

                <div className={classes.SliderToolBarWrapper}>
                    <div className={classes.SliderInfo} style={{justifyContent: "flex-end"}}>
                        <div>Time :</div>
                        <div> {CurrentPoint ? (CurrentPoint.t ? CurrentPoint.t.toFixed(1) : 0) : 0} s</div>
                    </div>
                    {SliderComponent}
                </div>

                <div className={classes.ChartAndPic}>
                    {PictureComponent}
                    {PictureViewerComponent}
                </div>
            </React.Fragment>
        );
    } else {
        FinalComponent = <React.Fragment>Hello</React.Fragment>;
    }
    return (
        <Page PageRef={Ref_Page} WithPerfectScrollBar={true} PagePadding="10px" ClassExtendsPage={classes.PageContentExtends}>
            <div ClassName={classes.Main}>{FinalComponent}</div>
        </Page>
    );

    /***************** RENDER ******************/
};

export default ManualActivation;
