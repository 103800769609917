import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import _ from "lodash";
import {Switch, Route, useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import Page from "@artibulles-cis/react/Page";
import {AvatarV2, Calendar, CheckBoxCircularFilled} from "@artibulles-cis/react-icons";
import FormCompontent from "@artibulles-cis/react/FormComponent";
import Button from "@artibulles-cis/react/Button/Button";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import CardModal from "@artibulles-cis/react/CardModal";
import {socketPIsensor} from "../../../../common/util/socketPIsensor";
import CameraPreview from "./CameraPreview";
import {format, getUnixTime, fromUnixTime, formatISO} from "date-fns";
import APICallExternal from "../../../../artibulles-cis/utils/APICallExternal";
import {convertSecondsToTime} from "../../../../artibulles-cis/utils/dateUtils";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    Main: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
    },
    Title: {
        display: "flex",
        alignItems: "center",
    },
    TestInfo: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "2px 0px 2px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
    FormFieldCheckBoxAligned: {
        flex: "0 1 600px",
        margin: "10px 0px 0px 0px",
    },
    ClassExtendTopToolBar: {
        backgroundColor: "grey",
        justifyContent: "center",
        height: "30px",
        flex: "0 0 35px",
    },
    CardExtended: {
        borderRadius: "10px",
    },
    TestStream: {
        display: "flex",
        margin: "0 auto",
        alignSelf: "center",
        flexDirection: "column",
        // width : "100%"
    },
}));

const Component = React.memo(function Component(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_Stream = useRef(null);
    const Ref_TimeOutResultsMixingReceived = useRef(null);
    const Ref_TimeOutStopTest = useRef(null);
    const Ref_FormValues = useRef({
        operator_name: "Arnaud Jaspard",
        operator_id: null,
        test_date: format(Date.now(), "dd-MMM-yyyy , hh:mm"),
        test_date_Unix: getUnixTime(Date.now()),
        TestDateIso: Date.now(),
        protocol_id: null,
        sample_id: null,
        sensor_installation: null,
        devmode: false,
    });

    const Ref_FlowData = useRef([]);
    /***************** REFS ******************/

    /***************** CONST ******************/
    const History = useHistory();
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [PISensorsSocketConnection, setPISensorsSocketConnection] = useState(false); //Checking the PI sensor Socket Connection
    const [Stream, setStream] = useState(null);
    const [CameraPreviewImgSrc, setCameraPreviewImgSrc] = useState(null);
    const [Loading, setLoading] = useState(false);
    const [APILoading, setAPILoading] = useState(false);
    const [ShowCameraModal, setShowCameraModal] = useState(false);
    const [CameraReady, setCameraReady] = useState(false);
    const [FormReady, setFormReady] = useState(false);
    const [StartTest, setStartTest] = useState(false);
    const [ElaspedTime, setElaspedTime] = useState(null);
    const [InitialFormValues, setInitialFormValues] = React.useState({
        operator_name: "Arnaud Jaspard",
        operator_id: null,
        test_date: format(Date.now(), "dd-MMM-yyyy , HH:mm"),
        protocol_id: null,
        sample_id: null,
        sensor_installation: null,
        devmode: false,

        //DONT FOLD
    });
    const [FormValues, setFormValues] = React.useState({
        operator_name: "Arnaud Jaspard",
        operator_id: null,
        test_date: format(Date.now(), "dd-MMM-yyyy , HH:mm"),
        test_date_Unix: getUnixTime(Date.now()),
        protocol_id: null,
        sample_id: null,
        sensor_installation: null,
        devmode: false,

        //DONT FOLD
    });

    const [FieldOptions, setFieldOptions] = React.useState({
        sensor_installation: [
            {value: "01", label: "Needle adaptor"},
            {value: "02", label: "PortTube adaptor"},
        ],
    }); //FORM OPTIONS FOR SELECT

    const [PristineDetails, setPristineDetails] = useState({});
    const [Pristine, setPristine] = useState(true); //Form has been edited by the user -> Pristie === false

    const [Invalid, setInvalid] = useState(false); //Form has invalid fields

    const [FormErrors, setFormErrors] = useState({}); //HNDLING FORM ERRORS

    const [ApiRes, setApiRes] = useState(null);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!APILoading && ApiRes) {
            if (ApiRes.error) {
                //Something went wrong for instance wrong credentials are captured here
                console.log("error EndofApi", ApiRes);
                // toast.error(`Error : ${ApiRes.errorMessage.message}`, {autoClose: true});
            } else {
                toast.success("Final test results submitted", {autoClose: true});
                setTimeout(() => {
                    History.push("/");
                }, 1000);
            }
        }
    }, [ApiRes, APILoading]);

    const HandleTestCompletedCallback = useCallback((imgpaths) => {
        console.log("Posting to the DB");
        //We need to Normalize the data;

        let FinalSubmitData = {
            OperatorName: Ref_FormValues.current.operator_name ? Ref_FormValues.current.operator_name : "NoProtocol",
            test_date_Unix: Ref_FormValues.current.test_date_Unix,
            TestDateIso: formatISO(fromUnixTime(FormValues.test_date_Unix)),
            Protocol_Id: Ref_FormValues.current.protocol_id ? Ref_FormValues.current.protocol_id : "NoProtocol",
            Sample_Id: Ref_FormValues.current.sample_id ? Ref_FormValues.current.sample_id : "NoSample",
            TestType: "FlowTest",
            ImagesPaths: imgpaths,
            FlowData: Ref_FlowData.current,
        };
        console.log("FinalSubmitData", FinalSubmitData);
        const ApiCall = {url: `https://artibulles-es-dataserver.artibulles.com/api/pdprotectesting/flowtest/addtestresult`, type: "post", data: FinalSubmitData};

        setLoading(true);

        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setAPILoading(false);
        }
        APIInternal();
    }, []);

    const StoreFlowData = useCallback((data) => {
        Ref_FlowData.current.push(data);
    }, []);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        //Requesting connection to the PI-Sensors Socket Server
        socketPIsensor.emit("RequestConnection", () => {});
        //Receiving confirmation
        const HandlePISensorConnection = () => setPISensorsSocketConnection(true);

        socketPIsensor.on("UserConnected", (data) => {
            HandlePISensorConnection();
        });
        //Streaming the data in real time
        socketPIsensor.on("CameraStreamFlow", (data) => {
            Ref_Stream.current = data;
            setStream(data);
        });

        //Camera Test Mode
        socketPIsensor.on("CameraStream", (data) => {
            setCameraPreviewImgSrc(data);
        });

        //Receiving data from the sensor
        socketPIsensor.on("FlowData", (data) => {
            let ElapsedMs = data.TimeMs;
            let Elapseds = ElapsedMs / 1000;
            console.log("data", data);
            let Sec = convertSecondsToTime(Elapseds);
            setElaspedTime(Sec);
            StoreFlowData(data);
        });

        socketPIsensor.on("FlowTestSaved", (inputs) => {
            let imgpaths = inputs.imgpaths;
            HandleTestCompletedCallback(imgpaths);
            setLoading(false);
            //we clear the timeout for recovery, everything went fine
            if (Ref_TimeOutResultsMixingReceived.current) {
                // console.log("clearing the recovery timeout, data uplaoded");
                clearTimeout(Ref_TimeOutResultsMixingReceived.current);
            }
        });

        return () => {
            socketPIsensor.off("UserConnected", HandlePISensorConnection);
        };
    }, []);

    useEffect(() => {
        if (Ref_FormValues.current.operator_name && Ref_FormValues.current.test_date && Ref_FormValues.current.protocol_id && Ref_FormValues.current.sample_id) {
            setFormReady(true);
        }
    }, [Ref_FormValues.current]);
    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!APILoading && ApiRes) {
            EndofAPI();
        }
    }, [APILoading, ApiRes]);

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleUpdateFormValues = (field, values) => {
        let value = values.Value;
        let formattedValue = values.FormattedValue;
        let FieldPristine = values.Pristine;

        //Checking Pristine

        // Update the pristine based on the Field
        var FinalPristine = true;
        let PristineKeys = _.keys(PristineDetails);
        let UpdatedPristineDetails = PristineDetails;

        if (PristineKeys && PristineKeys.length > 0) {
            //Going through all the pristine fields to check if one of them is false
            var CurrentFieldHandled = false;
            PristineKeys.forEach((key) => {
                if (key === field) {
                    CurrentFieldHandled = true;
                    //Update the pristine value
                    UpdatedPristineDetails[field] = FieldPristine;
                    if (FieldPristine === false) {
                        FinalPristine = false;
                    }
                } else {
                    //just checking if false;
                    if (UpdatedPristineDetails[key] === false) {
                        FinalPristine = false;
                    }
                }
            });
            if (CurrentFieldHandled === false) {
                //The key wasn't there so we need to add it
                if (FieldPristine === false) {
                    UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                    FinalPristine = false;
                }
            }
        } else {
            //First field to be touched
            if (FieldPristine === false) {
                UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                FinalPristine = false;
            }
        }
        setPristineDetails(UpdatedPristineDetails);
        setFormValues({...FormValues, ...{[field]: value}});
        Ref_FormValues.current = {...FormValues, ...{[field]: value}};
        setPristine(FinalPristine);
        //Manual update the pristine
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/
        // let FormValuesKeys = _.keys(FormValues);
        // var FinalPristine = true;

        // if (FormValuesKeys && FormValuesKeys.length > 0) {
        //     FormValuesKeys.forEach((key) => {
        //         let InitialValue = InitialFormValues[key];

        //         var Value;
        //         if (key !== field) {
        //             Value = FormValues[key];
        //         } else {
        //             //Check that the modified field is not the same as the InitalValue (The value has not changed yet)
        //             Value = value;
        //         }
        //         if (InitialValue === undefined) {
        //             InitialValue = null; //We set it to null to be able to compare to the Input Value that is set to null
        //             //It means that there was no initialValue passed to the form (that's the case when it is new)
        //         }
        //         if (InitialValue !== Value) {
        //             FinalPristine = false;
        //         }
        //     });
        // }

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        //Confirm Errors
        // ValidateForm(field, value);
        // HandleFiedsChange(field, value);
    };
    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/
    const handleSelectChange = (field, SelectedDetails) => {
        //Hanlding the select change
        //Checking Pristine

        let FieldPristine = SelectedDetails.Pristine;
        let SelectedValues = SelectedDetails.Value;

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/
        var FinalPristine = true;

        //Checking Pristine using the FieldResponse
        let PristineKeys = _.keys(PristineDetails);
        let UpdatedPristineDetails = PristineDetails;

        if (PristineKeys && PristineKeys.length > 0) {
            //Going through all the pristine fields to check if one of them is false
            var CurrentFieldHandled = false;
            PristineKeys.forEach((key) => {
                if (key === field) {
                    CurrentFieldHandled = true;
                    //Update the pristine value
                    UpdatedPristineDetails[field] = FieldPristine;
                    if (FieldPristine === false) {
                        FinalPristine = false;
                    }
                } else {
                    //just checking if false;
                    if (UpdatedPristineDetails[key] === false) {
                        FinalPristine = false;
                    }
                }
            });
            if (CurrentFieldHandled === false) {
                //The key wasn't there so we need to add it
                if (FieldPristine === false) {
                    UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                    FinalPristine = false;
                }
            }
        } else {
            //First field to be touched
            if (FieldPristine === false) {
                UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                FinalPristine = false;
            }
        }
        setPristineDetails(UpdatedPristineDetails);
        setPristine(FinalPristine);
        var UpdatedFormValues = {...FormValues, ...{[field]: SelectedValues}};
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        /************* IF NO CONDITIONAL SELECT USE THIS *****************/
        setFormValues(UpdatedFormValues);
        /************* IF NO CONDITIONAL SELECT USE THIS *****************/

        /************* CONDITIONAL SELECT *****************/
        //To prevent having to deal with different server configuration, the logic to implement here must be tailored to your needs

        /*Option 1 :
         *Updating the options of a subfield based on the select value ->
         *If you use this, don't forget to specify that the conditional field is disabled if the parentField is empty or null
         */
    };
    const HandleCheckbox = (field, value) => {
        setFormValues({...FormValues, ...{[field]: value}});
        Ref_FormValues.current = {...FormValues, ...{[field]: value}};
    };
    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/
    const HandleUpdateErrors = (field, error) => {
        // console.log("HandleUpdateErrors", field, error);
        //We need to check if there is an error or not
        const ErrorsKeys = _.keys(FormErrors);
        var FinalErrors = {};
        var InvalidTemp = false;
        if (ErrorsKeys && ErrorsKeys.length > 0) {
            // If there is an error
            ErrorsKeys.forEach((key) => {
                if (key !== field) {
                    if (FormErrors[key]) {
                        //Add the error in the list
                        FinalErrors = {...FinalErrors, ...{[key]: FormErrors[key]}};
                        InvalidTemp = true;
                    }
                }
            });
        }

        if (error) {
            //Add the error in the list
            FinalErrors = {...FinalErrors, ...{[field]: error}};
            InvalidTemp = true;
        }
        // console.log("InfalErrors", FinalErrors);

        setFormErrors(FinalErrors);

        setInvalid(InvalidTemp);
    };

    const HandleCheckCamera = () => {
        //Request streaming the camera
        let PicRatio = 3 / 4;
        // const CameraOptions = {
        //     width: 400,
        //     height: PicRatio * 400,
        //     fps: 40,
        //     StreamRate: 40, //ms
        // };
        const CameraOptions = {
            // width: 1280,
            // height: 720,
            width: 1332,
            height: 990,
            fps: 90,
            StreamRate: 100, //ms
            flip: "Both",
        };
        socketPIsensor.emit("StartStreamCameraFrames", CameraOptions);

        setShowCameraModal(true);
    };
    const HandleCloseCameraCard = () => {
        setShowCameraModal(false);
        //Stop streaming the camera
        socketPIsensor.emit("StopStreamCameraFrames");
        setCameraPreviewImgSrc(null);
    };
    const HandleShareCameraConfig = (config) => {
        if (config === "Ready") {
            setCameraReady(true);
            setCameraPreviewImgSrc(null);
        } else {
            setCameraReady(false);
            setCameraPreviewImgSrc(null);
        }
    };
    const HandleStartTest = () => {
        socketPIsensor.emit("FlowTestStart", {
            SampleId: Ref_FormValues.current.sample_id ? Ref_FormValues.current.sample_id : "NoSample",
            ProtocolId: Ref_FormValues.current.protocol_id ? Ref_FormValues.current.protocol_id : "NoProtocol",
            SampleRate: 5000, //Image saved
            StreamRate: 200, //Stremaing in real time
            WithCamera: true,
            cameraOptions: {
                width: 1332,
                height: 990,
                fps: 90,
                StreamRate: 100, //ms
                flip: "Both",
            },
        });

        setStartTest(true);
        //We initiate a timetout to automatically stop the test after 10 minutes
        Ref_TimeOutStopTest.current = setTimeout(() => {
            HandleStopTest();
        }, 600000);
    };

    const HandleStopTest = () => {
        //Stop the sensor
        socketPIsensor.emit("FlowTestStop", {
            SampleId: Ref_FormValues.current.sample_id ? Ref_FormValues.current.sample_id : "NoSample",
            ProtocolId: Ref_FormValues.current.protocol_id ? Ref_FormValues.current.protocol_id : "NoProtocol",
            WithCamera: true,
        });
        setLoading(true);
        //We put a timer here to recover if the axios post failed or if something else went wrong on the PI
        Ref_TimeOutResultsMixingReceived.current = setTimeout(() => {
            console.log("entering recovery mode");
            if (Ref_TimeOutResultsMixingReceived.current) {
                console.log("timeout still exist");
                console.log("please recover");

                //we clear the loader
                socketPIsensor.emit("RecoverFlowTest", {
                    SampleId: Ref_FormValues.current.sample_id ? Ref_FormValues.current.sample_id : "NoSample",
                    ProtocolId: Ref_FormValues.current.protocol_id ? Ref_FormValues.current.protocol_id : "NoProtocol",
                    WithCamera: true,
                });
            }
        }, 5000);
    };

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <Page WithPerfectScrollBar={true}>
            <div className={classes.Main}>
                <LocalCircularLoader Loading={Loading} WithModalCard={true} FullSize={true} />
                <div className={classes.Title}>
                    <h2 style={{margin: "0px 20px 0px 0px"}}>FlowTesting</h2>
                    <CheckBoxCircularFilled IconSize="20px" SVGFillColor="green" />
                    <div style={{display: `${PISensorsSocketConnection === true ? "flex" : "none"}`, alignItems: "center", color: "green"}}>
                        Pi Connection : {JSON.stringify(PISensorsSocketConnection)}
                    </div>
                </div>

                <div className={classes.TestInfo}>
                    <div className={classes.FormMultilineFlex}>
                        <div className={classes.FormField} style={{flex: "0 1 300px"}}>
                            <FormCompontent
                                Name="operator_name"
                                InitialValue={InitialFormValues.operator_name ? InitialFormValues.operator_name : null}
                                FormValue={FormValues.operator_name ? FormValues.operator_name : null}
                                OutputValue={(values) => HandleUpdateFormValues("operator_name", values)}
                                Component="Input"
                                Variant="OutlinedLabel"
                                Label="Operator"
                                NoMessage={false}
                                Required={true}
                                FieldMaxWidth="200px"
                                InternalToolbarLeftIcon={<AvatarV2 IconSize="25px" SVGFillColor="black" />}
                                AnimateToolBarLeft={true}
                                AnimateToolbarLeftColor={true}
                                ReadOnly={true}
                                // OnFieldExit={(status) => HandleFieldExit("operator_name", status)}
                                // meta={{error: FormErrors.normal, invalid: FormErrors.normal ? true : false}}
                                // OutputError={(error) => HandleUpdateErrors("normal", error)}
                            />
                        </div>
                        <div className={classes.FormField} style={{flex: "0 1 300px"}}>
                            <FormCompontent
                                Name="protocol_id"
                                InitialValue={InitialFormValues.protocol_id ? InitialFormValues.protocol_id : null}
                                FormValue={FormValues.protocol_id ? FormValues.protocol_id : null}
                                OutputValue={(values) => HandleUpdateFormValues("protocol_id", values)}
                                OutputError={(error) => HandleUpdateErrors("protocol_id", error)}
                                meta={{error: FormErrors.protocol_id, invalid: FormErrors.protocol_id ? true : false}}
                                Component="Input"
                                Variant="OutlinedLabel"
                                Label="Protocol Id"
                                NoMessage={false}
                                Required={true}
                                LocalValidation={{When: "Leaving"}}
                                DisplayMessageIn="Popup"
                                // FieldMaxWidth="200px"
                            />
                        </div>
                    </div>
                    <div className={classes.FormMultilineFlex}>
                        <div className={classes.FormField} style={{flex: "0 1 300px"}}>
                            <FormCompontent
                                Name="test_date"
                                InitialValue={InitialFormValues.test_date ? InitialFormValues.test_date : null}
                                FormValue={FormValues.test_date ? FormValues.test_date : null}
                                OutputValue={(values) => HandleUpdateFormValues("test_date", values)}
                                Component="Input"
                                Variant="OutlinedLabel"
                                Label="Date"
                                NoMessage={false}
                                Required={true}
                                FieldMaxWidth="200px"
                                InternalToolbarLeftIcon={<Calendar IconSize="25px" SVGFillColor="black" />}
                                AnimateToolBarLeft={true}
                                AnimateToolbarLeftColor={true}
                                ReadOnly={true}
                                // OnFieldExit={(status) => HandleFieldExit("AbortReason", status)}
                                // meta={{error: FormErrors.normal, invalid: FormErrors.normal ? true : false}}
                                // OutputError={(error) => HandleUpdateErrors("normal", error)}
                            />
                        </div>

                        <div className={classes.FormField} style={{flex: "0 1 300px"}}>
                            <FormCompontent
                                Name="sample_id"
                                InitialValue={InitialFormValues.sample_id ? InitialFormValues.sample_id : null}
                                FormValue={FormValues.sample_id ? FormValues.sample_id : null}
                                OutputValue={(values) => HandleUpdateFormValues("sample_id", values)}
                                OutputError={(error) => HandleUpdateErrors("sample_id", error)}
                                meta={{error: FormErrors.sample_id, invalid: FormErrors.sample_id ? true : false}}
                                Component="Input"
                                Variant="OutlinedLabel"
                                Label="Sample Id"
                                NoMessage={false}
                                Required={true}
                                // FieldMaxWidth="200px"
                                LocalValidation={{When: "Leaving"}}
                                DisplayMessageIn="Popup"
                            />
                        </div>
                    </div>
                </div>
                <Button onClick={HandleCheckCamera} style={{display: `${CameraReady === true ? "none" : "flex"}`}}>
                    Check Camera
                </Button>
                <Button onClick={HandleStartTest} style={{display: `${CameraReady === true && FormReady === true && StartTest === false ? "flex" : "none"}`}}>
                    StartTest
                </Button>
                <CardModal
                    // CardID="HealFit_CreateDailyIntake"
                    // ParentCompRef={Ref_TabNavContent}
                    TopBarContent={<h2>Camera check</h2>}
                    ClassExtendTopToolBar={classes.ClassExtendTopToolBar}
                    CloseCard={HandleCloseCameraCard}
                    // CloseOnClickOutside={true}
                    WithCloseButton={true}
                    CardMaxWidth="800px"
                    CardHeight="700px"
                    WithPerfectScrollbar={true}
                    CardWindowPaddingVertical="10px"
                    CardWindowPaddingHorizontal="10px"
                    ShowCard={ShowCameraModal}
                    WindowBackgroundColor="rgba(0,0,0,0.6)"
                    CardBackgroundColor="white"
                    CardBackground="white"
                    ClassExtendCard={classes.CardExtended}
                >
                    <CameraPreview imgSrc={CameraPreviewImgSrc} CloseCardFromInside={HandleCloseCameraCard} HandleShareConfig={HandleShareCameraConfig} />
                </CardModal>
                <div className={classes.TestStream} style={{display: `${StartTest === true ? "flex" : "none"}`}}>
                    {ElaspedTime}
                    <img src={Stream} alt="stream" style={{width: "700px", height: "auto"}} />
                    <Button onClick={HandleStopTest}>Stop the test</Button>
                </div>
            </div>
        </Page>
    );

    /***************** RENDER ******************/
});

export default Component;
