/* eslint-disable */
import React, {useState, useEffect} from "react";
import {createUseStyles, useTheme} from "react-jss";

import {useSelector} from "react-redux";
import NavTopBarLink from "@artibulles-cis/react/NavTopBarLink/NavTopBarLink";
import NavTopBarSubMenu from "@artibulles-cis/react/NavTopBarSubMenu";
import NavTopBarSubLink from "@artibulles-cis/react/NavTopBarSubLink";
import {AvatarV2, Logout, Admin} from "@artibulles-cis/react-icons";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    CompanyLink: {
        flex: "0 1 auto",
        boxSizing: "border-box",
        padding: "0px 10px 0px 10px",
    },
    CompanyLinkContent: {
        fontSize: "20px",
    },
    CompanyText: {
        flex: "1 0 auto",
    },
    CompanyLinkActive: {
        // background: "#FF6103",
        // opacity : 0.4
    },
    ClassExtendTopNavLink: {
        padding: "0px 5px 0px 5px",
    },
    ClassExtendNavUser: {
        flex: "0 1 auto",
        padding: "0px 10px 0px 10px",
    },
    ClassExtendNavTopBarSubMenu: {
        flex: "0 0 200px",
    },
    RefreshButton: {
        height: "100%",
        display: "flex",
        flex: "0 0 auto",
        position: "relative",
        alignItems: "center",
        justifyContent: "flex-end",
        background: "rgb(237, 45, 146)",
        padding: "10px",
        boxSizing: "border-box",
        zIndex: 2000,
        border: "1px solid rgb(120,120,120)",
    },
    TopNavIconCompany: {
        width: "35px",
        height: "35px",
    },
    ClassExtendTopNavLink: {
        padding: "0px 5px 0px 5px",
    },
    ClassExtendNavTopBarSubMenu: {
        flex: "0 0 200px",
    },
    ClassExtendNavTopBarSubMenuAdmin: {
        flex: "0 0 200px",
    },
    ClassExtendNavTopBarSubMenu_ControllerAdmin: {
        background: "rgb(255, 68, 0)",
        color: "white",
    },
    TopNavIcon: {
        width: "45px",
        height: "45px",
    },
    ClassExtendSubLinkWithIcon: {
        padding: "0px 20px",
    },
    SubLinkIcon: {
        flex: "0 0 30px",
        display: "flex",
        justifyContent: "flex-start",
        padding: "0px 10px 0px 0px",
    },
    SubLinkIconText: {
        flex: "1 1 auto",
        textAlign: "left",
    },
}));

const TopNavBar = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    // Extracting Desired Redux State Variables
    const {authenticated, avatar, username, MenuAccess} = useSelector((state) => {
        var MenuAccessTemp = null;

        if (state.auth.User.PermissionGroups) {
            if (Array.isArray(state.auth.User.PermissionGroups)) {
                if (state.auth.User.PermissionGroups.includes("admin")) {
                    MenuAccessTemp = "Admin";
                } else if (state.auth.User.PermissionGroups.includes("tester") && !state.auth.User.PermissionGroups.includes("analysis")) {
                    MenuAccessTemp = "tester";
                } else if (state.auth.User.PermissionGroups.includes("analysis")) {
                    MenuAccessTemp = "analysis";
                } else {
                    MenuAccessTemp = "view";
                }
            }
        }

        return {
            authenticated: state.auth.authenticated,
            avatar: state.auth.User.userProfile.avatar ? state.auth.User.userProfile.avatar : null,
            username: state.auth.User.userProfile.username ? state.auth.User.userProfile.username : "Welcome",
            group: state.auth.User.PermissionGroups ? state.auth.User.PermissionGroups : null,
            MenuAccess: MenuAccessTemp,
        };
    });

    var TestMenuComponent = null;
    if (MenuAccess === "Admin" || MenuAccess === "tester") {
        TestMenuComponent = (
            <NavTopBarSubMenu
                WithActiveIndicator={true}
                ClassExtendTopNavLink={classes.ClassExtendTopNavLink}
                SlugList={["/testing/menu/manualactivation", "/testing/menu/flowtest","/testing/menu/bursttest"]}
                ControllerContent={<p style={{padding: "10px"}}>Testing</p>}
            >
                <NavTopBarSubLink Slug="/testing/menu/manualactivation">Manual Activation</NavTopBarSubLink>
                <NavTopBarSubLink Slug="/testing/menu/flowtest">Flow Test</NavTopBarSubLink>
                <NavTopBarSubLink Slug="/testing/menu/bursttest">Burst Test</NavTopBarSubLink>
            </NavTopBarSubMenu>
        );
    } else {
        TestMenuComponent = null;
    }
    var SamplePrepMenuComponent = null;
    if (MenuAccess === "Admin" || MenuAccess === "tester") {
        SamplePrepMenuComponent = (
            <NavTopBarSubMenu
                WithActiveIndicator={true}
                ClassExtendTopNavLink={classes.ClassExtendTopNavLink}
                SlugList={["/sampleprep/bagfilling"]}
                ControllerContent={<p style={{padding: "10px"}}>Sample Preparation</p>}
            >
                <NavTopBarSubLink Slug="/sampleprep/bagfilling">Bag filling</NavTopBarSubLink>
            </NavTopBarSubMenu>
        );
    } else {
        SamplePrepMenuComponent = null;
    }
    var AnalysisComponent = null;
    if (MenuAccess === "Admin" || MenuAccess === "analysis") {
        AnalysisComponent = (
            <NavTopBarSubMenu
                WithActiveIndicator={true}
                ClassExtendTopNavLink={classes.ClassExtendTopNavLink}
                SlugList={["/analysis/manualactivation", "/analysis/flowtest"]}
                ControllerContent={<p style={{padding: "10px"}}>Analysis</p>}
            >
                <NavTopBarSubLink Slug="/analysis/manualactivation">Manual Activation</NavTopBarSubLink>
                <NavTopBarSubLink Slug="/analysis/flowtest">Flow Test</NavTopBarSubLink>
            </NavTopBarSubMenu>
        );
    } else {
        AnalysisComponent = null;
    }
    var DevTestComponent = null;
    if (MenuAccess === "Admin") {
        DevTestComponent = (
            <NavTopBarSubMenu
                WithActiveIndicator={true}
                ClassExtendNavTopBarSubMenu={classes.ClassExtendNavUser}
                SlugList={["/dev/camera", "/dev/sensorcomparison"]}
                ControllerContent={<p style={{padding: "10px"}}>Dev Testing</p>}
            >
                <NavTopBarSubLink Slug="/dev/videorecording">Video recording</NavTopBarSubLink>
                <NavTopBarSubLink Slug="/dev/camera">Camera testing</NavTopBarSubLink>
                <NavTopBarSubLink Slug="/dev/sensorcomparison">Pressure sensor comparison</NavTopBarSubLink>
                <NavTopBarSubLink Slug="/dev/coninuousstream">Continuous Stream</NavTopBarSubLink>
            </NavTopBarSubMenu>
        );
    } else {
        DevTestComponent = null;
    }

    return (
        <React.Fragment>
            <NavTopBarLink
                exact
                Slug="/"
                ClassExtendTopNavLink={classes.CompanyLink}
                ClassExtendNavTopBarLinkContent={classes.CompanyLinkContent}
                ClassExtendTopNavLinkActive={classes.CompanyLinkActive}
            >
                <img className={classes.TopNavIconCompany} src="/images/Artibulles_logo_ES_black_fontexp.svg" alt="Artibulles_Logo" />
                <p style={{padding: "10px"}} className={classes.CompanyText}>
                    PDprotec Testing
                </p>
            </NavTopBarLink>
            {TestMenuComponent}
            {SamplePrepMenuComponent}
            {AnalysisComponent}
            {DevTestComponent}
            <NavTopBarLink exact Slug="/doc">
                <p style={{padding: "10px"}} className={classes.CompanyText}>
                    Documentation
                </p>
            </NavTopBarLink>

            <NavTopBarSubMenu
                WithActiveIndicator={true}
                ClassExtendNavTopBarSubMenu={classes.ClassExtendNavUser}
                SlugList={["/logout", "/user/profile"]}
                ControllerContent={
                    <React.Fragment>
                        <AvatarV2 SVGFillColor="#ff6103" />
                        <p style={{padding: "0px 0px 0px 10px", flex: "0 1 auto"}} className={classes.CompanyText}>
                            {username}
                        </p>
                    </React.Fragment>
                }
            >
                <NavTopBarSubLink Slug="/logout" ClassExtendNavTopBarSubLink={classes.ClassExtendSubLinkWithIcon} ClassExtendNavTopBarSubLink_Content={classes.ClassExtendSubLinkWithIcon}>
                    <div className={classes.SubLinkIcon}>
                        <Logout />
                    </div>

                    <div className={classes.SubLinkIconText}>Logout</div>
                </NavTopBarSubLink>
                <NavTopBarSubLink Slug="/user/profile" ClassExtendNavTopBarSubLink={classes.ClassExtendSubLinkWithIcon} ClassExtendNavTopBarSubLink_Content={classes.ClassExtendSubLinkWithIcon}>
                    <div className={classes.SubLinkIcon}>
                        <Admin />
                    </div>

                    <div className={classes.SubLinkIconText}>Profile</div>
                </NavTopBarSubLink>
            </NavTopBarSubMenu>
        </React.Fragment>
    );
};

export default TopNavBar;
