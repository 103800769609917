import React from "react";
import {createUseStyles, useTheme} from "react-jss";
import {Switch, Route} from "react-router-dom";
import ManualActivationFullBagTestRouter from "./ManualActivation/FullBag/ManualActivationFullBagTestRouter";
import ManualActivationRouter from "./ManualActivation/ManualActivationRouter";
import FlowTest from "./FlowTest/FlowTest";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

const TestingRouter = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <React.Fragment>
            <Switch>
                <Route path="/testing/menu/manualactivation">
                    <ManualActivationRouter />
                </Route>
                <Route exact path="/testing/menu/burstest"></Route>
                <Route path="/testing/manualactivation/fullbag">
                    <ManualActivationFullBagTestRouter />
                </Route>
                <Route exact path="/testing/menu/flowtest">
                    <FlowTest />
                </Route>
            </Switch>
        </React.Fragment>
    );

    /***************** RENDER ******************/
};

export default TestingRouter;
