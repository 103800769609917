/* eslint-disable */
import React, {useEffect, useState, useCallback, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import {socketPIsensor} from "../../../../../common/util/socketPIsensor";
import {WarningHexagonRed, CheckBoxCircularFilled} from "@artibulles-cis/react-icons";
import Page from "@artibulles-cis/react/Page";
import Button from "@artibulles-cis/react/Button/Button";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";


import GraphWrapper from "../GraphWrapper";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    Header: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid grey",
        margin: "0px 0px 10px 0px",
    },
    MainContent: {
        padding: "0px 10px",
    },
    ChartWrapper: {
        margin: "0 auto",
        display: "flex",
        justifyContent: "center",
        height: "100%",
    },
    SensorConnection: {
        display: "flex",
        alignItems: "center",
    },
    TopToolBar: {
        display: "flex",
        justifyContent: "center",
        margin: "10px 0px",
        // alignItems : "flex-start"
    },
    SensorDisplay: {
        display: "flex",
        justifyContent: "center",
        margin: "20px 0px",
        boxSizing: "border-box",
        height: "30px",
        alignItems: "center",
        fontSize: "1.2rem",
        fontWeight: "500",
    },
    SensorDisplayValue: {
        display: "flex",
        maxWidth: "100px",
        width: "100%",
        justifyContent: "center",
        margin: "0 10px",
        boxSizing: "border-box",
        border: "1px solid grey",
        borderRadius: "5px",
        height: "100%",
    },
}));

const MADevelopment = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const SensorRawData_Ref = useRef([]); //Used to store the Sensor RawData unaltered
    const SensorRawChartData_Ref = useRef([]); //Used to store the full chart Data
    const ChartInstance_Ref = useRef(null);
    //This defines the chart inputs when initiating
    var ChartInputsInit = {
        chart: null, //The chart options
        xAxis: null, //The XAxis Options
        yAxis: null, //The YAxis Options
        series: [], //The series to create the chart
        title: {text: "Pressure"}, //The chart title
    };

    ChartInputsInit.series.push({
        name: "Pressure",
        data: [[0, 0]],
        color: "blue",
        lineWidth: 2,

        marker: {
            enabled: true,
            radius: 4,
            lineColor: "white",
            lineWidth: 1,
        },
        // dataLabels: {
        //     enabled: true,
        // },
    });

    ChartInputsInit.xAxis = {
        // labels: {
        //     formatter: function () {
        //         return `${format(this.value, "HH:mm")}`;
        //     },
        // },
        gridLineWidth: 1,
        crosshair: {
            // color: "yellow",
            width: 1,
        },
        // tickInterval :0.2,
        // tickInterval: 3600 * 1000,
        // min: 0,
        // max: 100,
        // min: getUnixTime(setHours(getTime(parseISO(WeigthMeasurement[0].date_iso)), 0)) * 1000,
        // // max: getUnixTime(setHours(getTime(parseISO(WeigthMeasurement[0].date_iso)), 24)) * 1000+1.5*60*60*1000,
        // max: getUnixTime(setHours(getTime(parseISO(WeigthMeasurement[0].date_iso)), 24)) * 1000 + 50 * 60 * 1000,
        showFirstLabel: true,
        showLastLabel: true,
        startOnTick: true,
        endOnTick: true,
        title: {
            text: "time [seconds]",
        },
    };
    ChartInputsInit.yAxis = [
        {
            title: {
                text: "Pressure [mbar]",
            },
            tickInterval: 5,
            max: 200,
            min: 0,
            // plotBands: [
            //     {
            //         color: "rgba(17, 237, 69,0.2)", // Color value
            //         from: 75, // Start of the plot band
            //         to: 80, // End of the plot band

            //         zIndex: 3,
            //         label: {
            //             text: "Weigh Goal", // Content of the label.
            //             align: "left", // Positioning of the label.
            //             // Default to center. x: +10 // Amount of pixels the label will be repositioned according to the alignment.
            //         },
            //     },
            // ],
        },
    ];
    ChartInputsInit.chart = {
        alignTicks: false,
    };
    ChartInputsInit.plotOptions = {
        // area: {
        //     marker: {
        //         enabled: false,
        //         states: {
        //             hover: {enabled: false},
        //         },
        //     },
        // },
    };

    /***************** CONST ******************/

    /***************** STATE ******************/
    const [SocketConnection, setSocketConnection] = useState(false); // check that the socket.io connection is active
    const [DeviceSerialNumber, setDeviceSerialNumber] = useState(null);
	const [CurrentPressurePoint, setCurrentPressurePoint] = useState({Time: null, Pressure: null}); //Used to display the current pressure
    
    const [ChartInstanceRef, setChartInstanceRef] = useState(null); //The chart instance
    const [ChartInputs, setChartInputs] = useState(ChartInputsInit);

    const [ForceChartUpdate, setForceChartUpdate] = useState(false);

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    //Storing all sensor data & generating the chart Data
    const StoreData = useCallback(
        (data) => {
            //data = {SerialNumber, TimeMs , AtmPressure (bar), StartDate (Server unixTime), Pressure (mbar), SensorCount (SensorValue)}
            var CurrentEntries = [];

            if (SensorRawData_Ref.current && Array.isArray(SensorRawData_Ref.current)) {
                CurrentEntries = SensorRawData_Ref.current;
            }
            //Storing Raw Data as backup
            let NewEntry = data;
            CurrentEntries.push(NewEntry);

            //Storing chart data
            const LastEntryPoint = [data.TimeMs / 1000, parseFloat(data.Pressure)];
            SensorRawChartData_Ref.current.push(LastEntryPoint);

            if (ChartInstance_Ref.current) {
                console.log("Updating the chart");
                var Shift = false;
                if (ChartInstance_Ref && ChartInstance_Ref.current.series[0].data.length > 20) {
                    Shift = true;
                }
                ChartInstance_Ref.current.series[0].addPoint(LastEntryPoint, true, Shift, false);
                // ChartInstance_Ref.current.series[0].addPoint(LastEntryPoint, false, false, false);
            }
            //Updating the state for displaying the Time and the Pressure
            setCurrentPressurePoint({Time: data.TimeMs / 1000, Pressure: data.Pressure});
        },
        [SensorRawData_Ref, ChartInstanceRef]
    );
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        //Requesting a connection to the server and expecting an answer
        socketPIsensor.emit("RequestConnection", (data) => {});
        const HandleConnection = () => setSocketConnection(true);
        const StoreDeviceSerialNumber = (data) => setDeviceSerialNumber(data);

        socketPIsensor.on("UserConnected", (data) => {
            HandleConnection();
            StoreDeviceSerialNumber(data);
        });

        //Subscribing to the sensor data when streaming

        socketPIsensor.on("SensorData", (data) => {
            StoreData(data); //Storing the sensor raw data through a callback
        });

        //Disconnecting when unmounting
        return () => {
            socketPIsensor.off("UserConnected", HandleConnection);
        };
    }, []);

    //Updating the chart when new data is received
    // useEffect(() => {
    //     UpdateChartData();
    // }, [CurrentPressurePoint]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    //On Click -> Start receiving data from the sensor and display in graph
    const HandleStartSendData = () => {
        socketPIsensor.emit("SendMeSensorData", {SampleRate: 1000});
    };

    //OnClick -> Stop receiving sensor Data (we stop dealing with the stream but the stream is still active)
    const HandleStopSendData = () => {
        socketPIsensor.emit("StopSensorData", (data) => {});

        //displaying the entire chart to the user - full refresh
        console.log("ChartInputsInit", ChartInputsInit);
        console.log("SensorRawChartData_Ref", SensorRawChartData_Ref.current);
        var ChartUpdate = ChartInputsInit;

        ChartUpdate.series[0].data = SensorRawChartData_Ref.current;
        // ChartInputsInit.series.push({
        // 	name: "Pressure",
        // 	data: [[0, 0]],
        // 	color: "blue",
        // 	lineWidth: 2,

        // 	marker: {
        // 		enabled: true,
        // 		radius: 4,
        // 		lineColor: "white",
        // 		lineWidth: 1,
        // 	},
        // 	// dataLabels: {
        // 	//     enabled: true,
        // 	// },
        // });
        setChartInputs(ChartUpdate);

        console.log("FullData", SensorRawData_Ref.current);
    };

    const HandleZeroSensor = () => {
        console.log("zero data");
    };
    //Used to store the chart Instance and use it to update the chart
    const StoreChartInstance = (ref) => {
        ChartInstance_Ref.current = ref;
        setChartInstanceRef(ref);
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    var ConnectionStatusComponent = <WarningHexagonRed />;
    if (SocketConnection === true) {
        ConnectionStatusComponent = <CheckBoxCircularFilled SVGFillColor="green" />;
    }

    var ChartComponent;
    ChartComponent = <GraphWrapper ChartInputs={ChartInputs} ChartInstance={StoreChartInstance} />;

    return (
        <Page WithPerfectScrollBar={true} PagePadding="10px">
            <LocalCircularLoader Loading={!SocketConnection} WithModalCard={true} FullSize={true} />
            <div className={classes.Header}>
                <h1 style={{margin: "0px", textAlign: "left"}}>Manual activation : Development Mode</h1>
                <div className={classes.SensorConnection}>
                    Measurement Device Status : {SocketConnection === true ? "Connected" : "Disconnected"}
                    {ConnectionStatusComponent}
                </div>
            </div>
            <div className={classes.MainContent}>
                <div className={classes.Step}>
                    <div className={classes.Step_Title}>
                        <h2>Step 1 : Check sensor calibration</h2>
                    </div>
                    <div className={classes.Step_Content}></div>
                </div>

                <div className={classes.TopToolBar}>
                    <Button Width="200px" style={{margin: "0px 5px"}} onClick={HandleStartSendData}>
                        CheckSensor
                    </Button>
                    <Button Width="200px" style={{margin: "0px 5px"}} onClick={HandleZeroSensor}>
                        Zero sensor
                    </Button>
                </div>
                <div className={classes.TopToolBar}>
                    <Button Width="200px" style={{margin: "0px 5px"}} onClick={HandleStopSendData}>
                        Start Test
                    </Button>
                    <Button Width="200px" style={{margin: "0px 5px"}} onClick={HandleStopSendData}>
                        Stop Test
                    </Button>
                </div>
                <div className={classes.SensorDisplay}>
                    <div>Time</div>
                    <div className={classes.SensorDisplayValue}>{CurrentPressurePoint.Time}</div>
                    <div>s</div>
                </div>
                <div className={classes.SensorDisplay}>
                    <div>Pressure</div>
                    <div className={classes.SensorDisplayValue}>{CurrentPressurePoint.Pressure}</div>
                    <div>mbar</div>
                </div>

                <div className={classes.ChartWrapper}>{ChartComponent}</div>
            </div>
        </Page>
    );

    /***************** RENDER ******************/
};

export default MADevelopment;
