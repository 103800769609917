import React, {useState, useRef} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";

import {CheckBoxRegularEmpty, CheckBoxRegularFilled} from "@artibulles-cis/react-icons";
var Color = require("color");
//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

const Lockedstyles = {
    FormElement: {
        display: "flex",
        flexDirection: "column",
    },
    CheckBoxLabel: {
        display: "inline-block",
        height: "auto",
    },
};

const styles = createUseStyles((theme) => ({
    FormElement: theme.FormTextInput.FormElement,
    CheckBox: {
        display: "inline-flex",
        position: "relative",
        alignItems: "center",
        boxSizing: "border-box",
    },
    CheckBoxButton: {
        display: "flex",
        position: "relative",
        flexDirection: "column",
        justifyContent: "center",
        flex: "0 0 auto",
        boxSizing: "border-box",
    },
    CheckBoxButtonChecked: {
        "&:hover $CheckBoxRippleEffect": {
            backgroundColor: (props) => (props.CircleColor ? Color(props.CircleColor).fade(0.9).string() : Color(theme.CheckBox.Circle.color).fade(0.9).string()), //Hover Circle Color Checked
            transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
    },
    CheckBoxButtonUnchecked: {
        "&:hover $CheckBoxRippleEffect": {
            backgroundColor: (props) => (props.CircleColor ? Color(props.CircleColor).fade(0.6).string() : Color(theme.CheckBox.Circle.color).fade(0.6).string()), //Hover Circle Color Checked
            transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        "&:hover $RippleAnimated": {
            backgroundColor: (props) => (props.CircleColor ? Color(props.CircleColor).fade(0.9).string() : Color(theme.CheckBox.Circle.color).fade(0.9).string()), //Hover Circle Color Checked
            transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
    },

    CheckBoxInput: {
        position: "absolute",
        top: "0px",
        left: "0px",
        right: "0px",
        bottom: "0px",
        margin: "0px",
        padding: "0px",
        opacity: "0",
        height: "100%",
        width: "100%",
        appearance: "none",
        webkitAppearance: "none ",
        outline: "none ",
        background: "none",
    },
    CheckBoxButtonCircle: {
        display: "flex",
        boxSizing: "border-box",
        padding: (props) => (props.CirclePadding ? props.CirclePadding : theme.CheckBox.Circle.padding),
        margin: "0px",
    },

    CheckBoxButtonCircleChecked: {},
    CheckBoxButtonCircleUnchecked: {},

    CheckBoxRippleEffect: {
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        zIndex: "0",
        overflow: "hidden",
        position: "absolute",
        borderRadius: "50%",
        pointerEvents: "none",
        transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
    CheckBoxSVGIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
    },
    CheckBoxLabel: theme.CheckBox.Label,

    RippleAnimated: {
        width: "100%",
        height: "100%",
        top: "0px",
        left: "0px",
        position: "absolute",
        opacity: "0.3",
        animation: "$RippleEnter 450ms cubic-bezier(0.4, 0, 0.2, 1)",
        transform: "scale(1)",
        backgroundColor: (props) => (props.CircleColor ? Color(props.CircleColor).fade(0.6).string() : Color(theme.CheckBox.Circle.color).fade(0.6).string()),
    },
    "@keyframes RippleEnter": {
        "0%": {
            opacity: "0.1",
            transform: "scale(0)",
        },
        "100%": {
            opacity: "0.8",
            transform: "scale(1)",
        },
    },
}));

/**
 * CheckBox with ripple Effects and customizable Icon without From Required
 */

const FormCheckBox = React.forwardRef(function FormCheckBox(props, ref) {
    /****************************** PROPS AND JSS CLASSES *********************/
    const {
        Inputname,
        UpdateProgrammatically,
        initialvalue,
        ShareInputValue,
        curvalue,
        label,
        disabled,
        required,
        IconSize,
        CheckMarkColor,
        CheckFillColor,
        UncheckedBorderColor,
        //eslint-disable-next-line
        CircleColor,
        //eslint-disable-next-line
        CirclePadding,
        LabelFontStyle,
        LabelPosition,
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});
    /****************************** PROPS AND JSS CLASSES *********************/

    /****************************** REFS *********************/
    const InputRef = useRef(null);
    /****************************** REFS *********************/

    /****************************** STATE *********************/
    const [Checked, setChecked] = useState(initialvalue);
    const [MouseDown, setMouseDown] = useState(false);
    /****************************** STATE *********************/

    /****************************** EFFECT *********************/
    //WHEN COMPONENT LOADS

    //SETTING INITIAL VALUE
    React.useEffect(() => {
        if (curvalue) {
            if (curvalue === undefined) {
                //skip undefined to prevent bugs
            } else {
                InputRef.current.checked = curvalue;
                setChecked(curvalue);
            }
        }
    }, [curvalue]);

    React.useEffect(() => {
        if (initialvalue) {
            if (initialvalue === undefined) {
                //skip undefined to prevent bugs
            } else {
                // InputRef.current.checked = initialvalue;
                setChecked(initialvalue);
            }
        }
    }, [initialvalue]);

    React.useEffect(() => {
        if (UpdateProgrammatically === true) {
            setChecked(true);
        } else {
            setChecked(false);
        }
    }, [UpdateProgrammatically]);
    /****************************** EFFECT *********************/

    /****************************** FUNCTIONS *********************/
    //ADD YOUR FUNCTIONS HERE
    const HandleCheckChange = (event) => {
        let checked = event.target.checked;
        setTimeout(() => {
            setChecked(checked);
        }, 200);

        setTimeout(() => {
            setMouseDown(false);
        }, 400);

        //Pass the value back to the component
        if (ShareInputValue) {
            ShareInputValue(checked);
        }
    };

    const HandleMouseDown = (event) => {
        // console.log("HandleMouseDown");
        setMouseDown(true);
        // let checked = event.target.checked;
        // setChecked(checked);
        // console.log(event)
    };

    /****************************** FUNCTIONS *********************/

    /****************************** RENDER *********************/
    const FinalLabelStyle = {
        fontFamily: LabelFontStyle.fontFamily ? LabelFontStyle.fontFamily : null,
        fontWeight: LabelFontStyle.fontWeight ? LabelFontStyle.fontWeight : null,
        fontSize: LabelFontStyle.fontSize ? LabelFontStyle.fontSize : null,
        color: LabelFontStyle.color ? LabelFontStyle.color : null,
        padding: LabelFontStyle.padding ? LabelFontStyle.padding : null,
        margin: LabelFontStyle.margin ? LabelFontStyle.margin : null,
        lineHeight: LabelFontStyle.lineHeight ? LabelFontStyle.lineHeight : null,
    };

    var CheckBoxButtonClassFinal;
    if (disabled) {
        CheckBoxButtonClassFinal = clsx(classes.CheckBoxButton);
    } else {
        if (Checked) {
            CheckBoxButtonClassFinal = clsx(classes.CheckBoxButton, classes.CheckBoxButtonChecked);
        } else {
            CheckBoxButtonClassFinal = clsx(classes.CheckBoxButton, classes.CheckBoxButtonUnchecked);
        }
    }

    const FinalIconStyle = {
        IconSize: IconSize ? IconSize : theme.CheckBox.Icon.IconSize,
        CheckMarkColor: CheckMarkColor ? CheckMarkColor : theme.CheckBox.Icon.CheckMarkColor,
        CheckFillColor: CheckFillColor ? CheckFillColor : theme.CheckBox.Icon.CheckFillColor,
        UncheckedBorderColor: UncheckedBorderColor ? UncheckedBorderColor : theme.CheckBox.Icon.UncheckedBorderColor,
    };

    const IconCheck = () => {
        if (Checked) {
            // return <CheckBoxNotchedFilled IconSize="23px" SVGStrokeColor="white" SVGFillColor="rgba(66, 165, 245, 1)" />;
            // return <CheckBoxCircularFilled IconSize="23px" SVGStrokeColor="white" SVGFillColor="rgba(66, 165, 245, 1)" />;
            return <CheckBoxRegularFilled IconSize={FinalIconStyle.IconSize} SVGStrokeColor={FinalIconStyle.CheckMarkColor} SVGFillColor={FinalIconStyle.CheckFillColor} />;
        } else {
            // return <CheckBoxNotchedEmpty IconSize="23px" />;
            // return <CheckBoxCircularEmpty IconSize="23px" />;
            return <CheckBoxRegularEmpty IconSize={FinalIconStyle.IconSize} SVGFillColor={FinalIconStyle.UncheckedBorderColor} />;
        }
    };
    const RippleDiv = () => {
        if (MouseDown) {
            return <div className={classes.RippleAnimated}></div>;
        } else {
            return null;
        }
    };

    const FinalCheckBox = () => {
        if (LabelPosition === "right") {
            return (
                <div className={classes.CheckBox}>
                    <span className={classes.CheckBoxLabel} style={{...Lockedstyles.CheckboxLabel, ...FinalLabelStyle}}>
                        {label}
                    </span>
                    <div className={CheckBoxButtonClassFinal} onMouseDown={HandleMouseDown}>
                        <div className={classes.CheckBoxButtonCircle}>
                            <div className={classes.CheckBoxSVGIcon}>{IconCheck()}</div>
                        </div>
                        <input
                            ref={InputRef}
                            name={Inputname}
                            required={required}
                            className={classes.CheckBoxInput}
                            type="checkbox"
                            onChange={HandleCheckChange}
                            disabled={disabled}
                            checked={Checked}
                        />

                        <div className={classes.CheckBoxRippleEffect}>{RippleDiv()}</div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className={classes.CheckBox}>
                    <div className={CheckBoxButtonClassFinal} onMouseDown={HandleMouseDown}>
                        <div className={classes.CheckBoxButtonCircle}>
                            <div className={classes.CheckBoxSVGIcon}>{IconCheck()}</div>
                        </div>
                        <input
                            ref={InputRef}
                            name={Inputname}
                            required={required}
                            className={classes.CheckBoxInput}
                            type="checkbox"
                            onChange={HandleCheckChange}
                            disabled={disabled}
                            checked={Checked}
                        />

                        <div className={classes.CheckBoxRippleEffect}>{RippleDiv()}</div>
                    </div>
                    <span className={classes.CheckBoxLabel} style={{...Lockedstyles.CheckboxLabel, ...FinalLabelStyle}}>
                        {label}
                    </span>
                </div>
            );
        }
    };

    return (
        <div className={classes.FormElement} style={{...Lockedstyles.FormElement}}>
            <div className={classes.CheckBox}>{FinalCheckBox()}</div>
        </div>
    );
    /****************************** RENDER *********************/
});

FormCheckBox.defaultProps = {
    Inputname: null,
    label: null,
    required: false,
    disabled: false,
    IconSize: null,
    CheckMarkColor: null,
    CheckFillColor: null,
    UncheckedBorderColor: null,
    CircleColor: null,
    CirclePadding: null,
    LabelFontStyle: {
        fontFamily: null,
        fontWeight: null,
        fontSize: null,
        color: null,
        padding: null,
        margin: null,
        lineHeight: null,
    },
    LabelPosition: "left",
};

FormCheckBox.propTypes = {
    /**
     * Inputname :
     */
    Inputname: PropTypes.string,
    /**
     * label :
     */
    label: PropTypes.string,
    /**
     * required :
     */
    required: PropTypes.bool,
    /**
     * disabled :
     */
    disabled: PropTypes.bool,
    /**
     * IconSize :
     */
    IconSize: PropTypes.string,
    /**
     * CheckMarkColor :
     */
    CheckMarkColor: PropTypes.string,
    /**
     * CheckFillColor :
     */
    CheckFillColor: PropTypes.string,
    /**
     * UncheckedBorderColor :
     */
    UncheckedBorderColor: PropTypes.string,
    /**
     * CircleColor : rgb(102,125,136) .Must be RGB !!!!
     */
    CircleColor: PropTypes.string,
    /**
     * CirclePadding
     */
    CirclePadding: PropTypes.string,
    /**
     *  LabelFontStyle : {fontFamily :null,fontWeight : null, fontSize : null, color : null, padding : null, margin : null, lineHeight : null}, :
     */
    LabelFontStyle: PropTypes.object,
    /**
     *  LabelPosition : left/right
     */
    LabelPosition: PropTypes.string,
};

export default FormCheckBox;
