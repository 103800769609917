/* eslint-disable */
import React, {useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";

import {Route, Switch, Redirect} from "react-router-dom";
import AnalysisManualActivation from "./ManualActivation/AnalysisManualActivation";
import FlowTestAnalysis from "./FlowTest/FlowTestAnalysis";
import ManualActivationTabNavRouter from "./ManualActivation/ManualActivationTabNavRouter";
import FlowTestTabNavRouter from "./FlowTest/FlowTestTabNavRouter";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

const AnalysisRouter = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <Switch>
            
            <Route path="/analysis/manualactivation">
                <ManualActivationTabNavRouter />
            </Route>
			<Route path="/analysis/flowtest">
                <FlowTestTabNavRouter />
            </Route>

            <Route path="/analysis/manualactivationdetails/:id">
                <AnalysisManualActivation />
            </Route>
            <Route path="/analysis/flowtestdetails/:id">
                <FlowTestAnalysis />
            </Route>
        </Switch>
    );

    /***************** RENDER ******************/
};

export default AnalysisRouter;
