/* eslint-disable */
import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import Page from "@artibulles-cis/react/Page";
import {useParams} from "react-router-dom";
import _ from "lodash";
import APICallExternal from "@artibulles-cis/react/utils/APICallExternal";
import FormCompontent from "@artibulles-cis/react/FormComponent";

import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import GeneralGraph from "../../Testing/GeneralGraph";
import {Calendar} from "@artibulles-cis/react-icons";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import CheckBox from "../../../../artibulles-cis/CheckBox/CheckBox";
import CircularGageFourQuarters from "../../../../artibulles-cis/CircularGageFourQuarters/CircularGageFourQuarters";
import PictureViewer from "../PictureViewer";

const {format, fromUnixTime} = require("date-fns");
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    PageContentExtends: {
        // background: "blue",
        // backgroundImage: "url('/images/HomePageBackground.jpg')",
        // backgroundSize: "cover",
        // backgroundRepeat: "no-repeat",
        // backgroundPosition: "50% 50%",
        // color: "white",
    },
    Main: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "0px 10px",
        // height : "100%"
    },
    MainInfo: {
        maxWidth: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        boxSizing: "border-box",
        borderBottom: "1px solid grey",
        backgroundColor: "rgb(200,200,200)",
        marginBottom: "10px",
    },
    FormMultilineFlex: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        margin: "4px 0px 4px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
    ToolBarWrapper: {
        width: "100%",
        display: "flex",
        justifyContent: "space-around",
    },
    ChartAndPic: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        paddingBottom: "15px",
    },

    GraphResults: {
        flex: "0 0 500px",
        boxSizing: "border-box",
        padding: "10px",
        border: "1px solid grey",
        borderRadius: "10px",
        margin: "0px 5px",
    },
    PictureWrapper: {
        flex: "0 0 400px",
        // height : "100px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        padding: "10px",
        border: "1px solid grey",
        borderRadius: "10px",
    },
    SliderToolBarWrapper: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "10px 0px 30px 0px",
    },
    SliderWrapper: {
        width: "500px",
        margin: "0px 25px 0px 25px",
    },
    SliderInfo: {
        flex: "0 0 250px",
        display: "flex",
        fontWeight: "500",
    },
    DetailedResults: {
        display: "flex",
        flex: "1 1 auto",
        flexDirection: "column",
        boxSizing: "border-box",
        border: "1px solid grey",
        padding: "10px",
        borderRadius: "5px",
        margin: "10px 0px 0px 30px",
        fontSize: "0.9rem",
    },
    DetailedResults_Line: {
        display: "flex",
        flexDirection: "raw",
    },
    MainInfoToolbar: {
        flex: "1 1 auto",
        width: "100%",
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
        borderBottom: "1px solid grey",
        padding: "0px 0px 10px 0px ",
    },
    SummaryResults: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
    },
    MixingSealResults: {
        display: "flex",
        flex: "1 1 50%",
        flexDirection: "column",
        alignItems: "center",
        boxSizing: "border-box",
        padding: "10px",
        border: "1px solid grey",
        borderRadius: "10px",
        margin: "0px 10px",
    },
    AccessSealResults: {
        display: "flex",
        flex: "1 1 50%",
        flexDirection: "column",
        alignItems: "center",
        boxSizing: "border-box",
        padding: "10px",
        border: "1px solid grey",
        borderRadius: "10px",
        margin: "0px 10px",
    },
    TestSummaryResult_Line: {
        display: "flex",
    },
    TestSummaryResult_Line: {},
    DetailsText: {
        flex: "0 1 auto",
        height: "140px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "0px 0px 0px 30px",
    },
    Details_Row: {
        display: "flex",
        flex: "1 1 auto",
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
    },
    SleepDetails_ColIcon: {
        flex: "0 0 30px",
    },
    SleepDetails_ColTitle: {
        flex: "0 0 85px",
    },
    SleepDetails_ColBarChart: {
        flex: "0 0 1",
    },

    BarEmpty: {
        position: "relative",
        width: "100px",
        height: "15px",
        backgroundColor: "lightgrey",
        borderRadius: "2px",
    },
    BarFilled: {
        position: "absolute",
        borderRadius: "2px",
        top: "0px",
        left: "0px",
        height: "15px",
        backgroundColor: "green",
    },
    BarAvgIndicator: {
        position: "absolute",
        top: "0px",
        height: "15px",
        width: "2px",
        backgroundColor: "black",
    },
    SleepDetails_ColPercentage: {
        flex: "0 0 30px",
        padding: "5px",
    },
    SleepDetails_ColDuration: {
        flex: "0 0 40px",
    },
    SleepANSDetails_ColDuration: {
        flex: "0 0 28px",
        padding: "0px 0px 0px 5px",
    },
    SleepDetails_ColDuration_Avg: {
        flex: "0 0 30px",
        fontSize: "10px",
    },
    GageWrapper: {
        display: "flex",
        alignItems: "center",
    },
    Gage: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        margin: "5px",
    },
    // GageMessage: {
    //     display: "flex",
    //     justifyContent: "center",
    //     textAlign: "center",
    //     wordWrap: "break-word",
    //     width: "130px",
    // },
    GaugeTest: {
        display: "flex",
    },
    TestDetailsContent: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
    },
    ChamberTitle: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
    },
    ChamberTitle_Checkbox: {
        position: "absolute",
        right: "0px",
    },
    ChamberTitleAccess_Checkbox: {
        position: "absolute",
        left: "0px",
    },
}));

const ManualActivation = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const Ref_Page = React.useRef(null);
    const Ref_GraphInstance = useRef(null);
    const Ref_FormValues = useRef({showmixingresults: true, showaccessresults: false});

    const LocationParams = useParams();
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ShowBigPicture, setShowBigPicture] = useState(false);
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [CompReady, setCompReady] = useState(false);
    const [Id, SetId] = useState(LocationParams.id);
    const [ReloadAll, setReloadAll] = useState(false);

    const [Reload, setReload] = useState(true);
    const [InitialFormValues, setInitialFormValues] = useState({});
    const [FormValues, setFormValues] = useState({});
    const [FieldOptions, setFieldOptions] = React.useState({
        chamber_select: [
            {value: "01", label: "Mixing seal"},
            {value: "02", label: "Access seal"},
        ],
    }); //FORM OPTIONS FOR SELECT

    const [ViewChamber, setViewChamber] = useState(null);
    const [MixingSealData, setMixingSealData] = useState(null);
    const [AccessSealData, setAccessSealData] = useState(null);

    const [DetailsOpening, setDetailsOpening] = useState({
        DiscardTest: null,
        DiscardReason: null,
        SealOpened: null,
        TestDuration: null,
        EaseOfOpeningRanking: null,
        OpeningDescription: null,
        sealNotopenedReason: null,
    });
    const [ResultDescription, setResultDescription] = useState(null);
    const [GraphData, setGraphData] = useState(null);
    const [JsonPressureData, setJsonPressureData] = useState(null);
    const [ExtremePoints, setExtremePoints] = useState({min: {t: 0, p: 0}, max: {t: 0, p: 0}, pmax: {t: 0, p: 0}});
    const [DataReady, setDataReady] = useState(false);
    const [SliderOptions, setSliderOptions] = useState({
        min: 0,
        max: 100,
        marks: {
            0: 0,
            100: 100,
        },
    });

    const [ImagesPath, setImagesPath] = useState(null);
    const [ImagePathIndex, setImagePathIndex] = useState(0);
    const [ImgSrc, setImgSrc] = useState(null);
    const [CurrentPoint, setCurrentPoint] = useState({t: null, P: null});
    const [ReloadGraph, setReloadGraph] = useState(false);
    const [HasMixingSealData, setHasMixingSealData] = useState(false);
    const [HasAccessSealData, setHasAccessSealData] = useState(false);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = React.useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                console.log("error");
            } else {
                setReload(false);
                let Data = ApiRes.data.data;
                console.log("Data", Data);
                // console.log("Data", Data);
                var EaseOfOpeningGageValue, EaseOfOpeningGageText;
                let MixingSealGraphData = Data.MixingSealData ? (Data.MixingSealData.GraphSensorData ? Data.MixingSealData.GraphSensorData : null) : null;
                let AccessSealGraphData = Data.AccessSealData ? (Data.AccessSealData.GraphSensorData ? Data.AccessSealData.GraphSensorData : null) : null;
                if (MixingSealGraphData && Array.isArray(MixingSealGraphData) && MixingSealGraphData.length > 0) {
                    setHasMixingSealData(true);
                    // console.log("MixingSealGraphData", Data.MixingSealData);
                    //We create an array of img source for the display
                    let ImgsPathArr = Data.MixingSealData.ImagesPaths;
                    var ImgPaths = [];
                    if (ImgsPathArr && Array.isArray(ImgsPathArr)) {
                        ImgsPathArr.forEach((elem) => {
                            ImgPaths.push(elem.file);
                        });
                    }
                    //We create the exploitable graph data
                    var JsonPData = [];
					console.log("MixingSealGraphData",MixingSealGraphData)
                    for (let i = 0; i < MixingSealGraphData.length; i++) {
                        let elem = MixingSealGraphData[i];
                        let newElem = {index: i, t: elem[0], P: elem[1]};
                        JsonPData.push(newElem);
                    }
                    let minTime = JsonPData[0];
                    let maxTime = JsonPData[JsonPData.length - 1];
                    let maxPressure = _.maxBy(JsonPData, "P");

                    if (Data.MixingSealData.EaseOfOpeningRanking === "Very Easy") {
                        EaseOfOpeningGageText = "VERY EASY";
                        EaseOfOpeningGageValue = 25;
                    } else if (Data.MixingSealData.EaseOfOpeningRanking === "Easy") {
                        EaseOfOpeningGageValue = 50;
                        EaseOfOpeningGageText = "EASY";
                    } else if (Data.MixingSealData.EaseOfOpeningRanking === "Hard") {
                        EaseOfOpeningGageValue = 75;
                        EaseOfOpeningGageText = "HARD";
                    } else if (Data.MixingSealData.EaseOfOpeningRanking === "Very Hard") {
                        EaseOfOpeningGageValue = 100;
                        EaseOfOpeningGageText = "VERY HARD";
                    } else {
                        EaseOfOpeningGageValue = 0;
                        EaseOfOpeningGageText = "UNKNOWN";
                    }
                    setMixingSealData({
                        JsonPressureData: JsonPData,

                        ExtremePoints: {min: minTime, max: maxTime, pmax: maxPressure},
                        SliderOptions: {
                            min: minTime.index,
                            max: maxTime.index,
                            marks: {
                                [minTime.index]: minTime.t.toFixed(1),
                                [maxTime.index]: maxTime.t.toFixed(1),
                                [maxPressure.index]: maxPressure.t.toFixed(1),
                            },
                        },
                        ImagesPath: ImgPaths,
                        GraphData: MixingSealGraphData,

                        DetailsOpening: {
                            DiscardTest: Data.MixingSealData.DiscardTest,
                            DiscardReason: Data.MixingSealData.DiscardReason,
                            SealOpened: Data.MixingSealData.SealOpened,
                            TestDuration: Data.MixingSealData.TestDuration,
                            EaseOfOpeningRanking: Data.MixingSealData.EaseOfOpeningRanking,
                            EaseOfOpeningGageValue: EaseOfOpeningGageValue,
                            EaseOfOpeningGageText: EaseOfOpeningGageText,
                            OpeningDescription: Data.MixingSealData.OpeningDescription,
                            sealNotopenedReason: Data.MixingSealData.sealNotopenedReason,
                            Issue: Data.MixingSealData.Issue,
                            IssueDescription: Data.MixingSealData.IssueDescription,
                        },
                    });
                }
                if (AccessSealGraphData && Array.isArray(AccessSealGraphData) && AccessSealGraphData.length > 0) {
                    setHasAccessSealData(true);
                    // console.log("AccessSealGraphData", Data.AccessSealData);
                    //We create an array of img source for the display
                    let ImgsPathArr = Data.AccessSealData.ImagesPaths;
                    var ImgPaths = [];
                    if (ImgsPathArr && Array.isArray(ImgsPathArr)) {
                        ImgsPathArr.forEach((elem) => {
                            ImgPaths.push(elem.file);
                        });
                    }
                    //We create the exploitable graph data
                    var JsonPData = [];
                    for (let i = 0; i < AccessSealGraphData.length; i++) {
                        let elem = AccessSealGraphData[i];
                        let newElem = {index: i, t: elem[0], P: elem[1]};
                        JsonPData.push(newElem);
                    }
                    let minTime = JsonPData[0];
                    let maxTime = JsonPData[JsonPData.length - 1];
                    let maxPressure = _.maxBy(JsonPData, "P");
                    if (Data.AccessSealData.EaseOfOpeningRanking === "Very Easy") {
                        EaseOfOpeningGageText = "VERY EASY";
                        EaseOfOpeningGageValue = 25;
                    } else if (Data.AccessSealData.EaseOfOpeningRanking === "Easy") {
                        EaseOfOpeningGageValue = 50;
                        EaseOfOpeningGageText = "EASY";
                    } else if (Data.AccessSealData.EaseOfOpeningRanking === "Hard") {
                        EaseOfOpeningGageValue = 75;
                        EaseOfOpeningGageText = "HARD";
                    } else if (Data.AccessSealData.EaseOfOpeningRanking === "Very Hard") {
                        EaseOfOpeningGageValue = 100;
                        EaseOfOpeningGageText = "VERY HARD";
                    } else {
                        EaseOfOpeningGageValue = 0;
                        EaseOfOpeningGageText = "UNKNOWN";
                    }
                    setAccessSealData({
                        JsonPressureData: JsonPData,
                        ExtremePoints: {min: minTime, max: maxTime, pmax: maxPressure},
                        SliderOptions: {
                            min: minTime.index,
                            max: maxTime.index,
                            marks: {
                                [minTime.index]: minTime.t.toFixed(1),
                                [maxTime.index]: maxTime.t.toFixed(1),
                                [maxPressure.index]: maxPressure.t.toFixed(1),
                            },
                        },
                        ImagesPath: ImgPaths,
                        GraphData: AccessSealGraphData,
                        DetailsOpening: {
                            DiscardTest: Data.AccessSealData.DiscardTest,
                            DiscardReason: Data.AccessSealData.DiscardReason,
                            SealOpened: Data.AccessSealData.SealOpened,
                            TestDuration: Data.AccessSealData.TestDuration,
                            EaseOfOpeningRanking: Data.AccessSealData.EaseOfOpeningRanking,
                            EaseOfOpeningGageValue: EaseOfOpeningGageValue,
                            EaseOfOpeningGageText: EaseOfOpeningGageText,
                            OpeningDescription: Data.AccessSealData.OpeningDescription,
                            sealNotopenedReason: Data.AccessSealData.sealNotopenedReason,
                            Issue: Data.AccessSealData.Issue,
                            IssueDescription: Data.AccessSealData.IssueDescription,
                        },
                    });
                }

                let FormData = {
                    Protocol_Id: Data.Protocol_Id,
                    Sample_Id: Data.Sample_Id,
                    OperatorName: Data.OperatorName,
                    test_date: format(fromUnixTime(Data.TestDateTimeUnix), "dd-MMM-yyyy , HH:mm"),
                    TestType: Data.TestType,
                    showmixingresults: true,
                };
                setFormValues(FormData);
                setInitialFormValues(FormData);
                setViewChamber("Mixing");
            }
        }
    }, [ApiRes, Loading]);

    const HandleCalculateResults = useCallback(
        (chamber) => {
            if (chamber === "Mixing") {
                if (MixingSealData && MixingSealData.GraphData) {
                    setDataReady(true);
                    setGraphData(MixingSealData.GraphData);
                    setImagesPath(MixingSealData.ImagesPath);
                    setExtremePoints(MixingSealData.ExtremePoints);
                    setSliderOptions(MixingSealData.SliderOptions);
                    setJsonPressureData(MixingSealData.JsonPressureData);
                    setImgSrc(MixingSealData.ImagesPath[0]);
                    setReloadGraph(true);
                    setDetailsOpening(MixingSealData.DetailsOpening);
                    setTimeout(() => {
                        setReloadGraph(false);
                    }, 20);
                    setTimeout(() => {
                        if (Ref_GraphInstance.current) {
                            Ref_GraphInstance.current.xAxis[0].setExtremes(0, MixingSealData.ExtremePoints.max.t);
                            Ref_GraphInstance.current.yAxis[0].setExtremes(0, MixingSealData.ExtremePoints.pmax.P);
                        }
                    }, 500);
                }
            } else if (chamber === "Access") {
                if (AccessSealData && AccessSealData.GraphData) {
                    setDataReady(true);
                    setGraphData(AccessSealData.GraphData);
                    setImagesPath(AccessSealData.ImagesPath);
                    setExtremePoints(AccessSealData.ExtremePoints);
                    setDetailsOpening(AccessSealData.DetailsOpening);
                    setSliderOptions(AccessSealData.SliderOptions);
                    setJsonPressureData(AccessSealData.JsonPressureData);
                    setImgSrc(AccessSealData.ImagesPath[0]);
                    setReloadGraph(true);
                    setTimeout(() => {
                        setReloadGraph(false);
                    }, 20);
                    setTimeout(() => {
                        if (Ref_GraphInstance.current) {
                            Ref_GraphInstance.current.xAxis[0].setExtremes(0, AccessSealData.ExtremePoints.max.t);
                            Ref_GraphInstance.current.yAxis[0].setExtremes(0, AccessSealData.ExtremePoints.pmax.P);
                        }
                    }, 500);
                }
            }
        },
        [MixingSealData]
    );
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        if (LocationParams.id) {
            if (LocationParams.id !== "default") {
                SetId(LocationParams.id);
                setCompReady(true);
                setLoading(false);
            }
        }
    }, [LocationParams]);

    useEffect(() => {
        if (Reload === true) {
            const ApiCall = {url: `https://artibulles-es-dataserver.artibulles.com/api/pdprotectesting/manualactivation/analysis/result/${Id}`, type: "get"};
            // const ApiCall = {url: `http://192.168.1.110:3661/api/pdprotectesting/pressuregraph/123456`, type: "get"};
            setLoading(true);
            // setLoginPhase("OTP code entered");
            async function APIInternal() {
                const res = await APICallExternal(ApiCall);

                setApiRes(res);
                setLoading(false);
            }

            APIInternal();
        }
    }, [Reload]);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);

    useEffect(() => {
        if (ViewChamber === "Mixing") {
            HandleCalculateResults("Mixing");
        } else if (ViewChamber === "Access") {
            HandleCalculateResults("Access");
        }
    }, [ViewChamber, HandleCalculateResults]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleUpdateFormValues = (field, values) => {
        let value = values.Value;
        let formattedValue = values.FormattedValue;

        setFormValues({...FormValues, ...{[field]: value}});
    };
    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/
    const handleSelectChange = (field, SelectedDetails) => {
        //Hanlding the select change
        let SelectedValues = SelectedDetails.Value;

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        //Checking Pristine using the FieldResponse

        var UpdatedFormValues = {...FormValues, ...{[field]: SelectedValues}};
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        /************* IF NO CONDITIONAL SELECT USE THIS *****************/
        setFormValues(UpdatedFormValues);
        /************* IF NO CONDITIONAL SELECT USE THIS *****************/

        /************* CONDITIONAL SELECT *****************/
        //To prevent having to deal with different server configuration, the logic to implement here must be tailored to your needs

        /*Option 1 :
         *Updating the options of a subfield based on the select value ->
         *If you use this, don't forget to specify that the conditional field is disabled if the parentField is empty or null
         */

        //Performing actions when changing the value of the select
        if (field === "chamber_select") {
            if (SelectedValues === "01") {
                setViewChamber("Mixing");
            } else if (SelectedValues === "02") {
                setViewChamber("Access");
            }
        }
    };

    const HandleCheckbox = (field, value) => {
        if (field === "showmixingresults") {
            if (value === true) {
                setViewChamber("Mixing");
            } else {
                setViewChamber("Access");
            }
            setFormValues({...FormValues, ...{[field]: value, showaccessresults: !value}});
        } else if (field === "showaccessresults") {
            if (value === true) {
                setViewChamber("Access");
            } else {
                setViewChamber("Mixing");
            }
            setFormValues({...FormValues, ...{[field]: value, showmixingresults: !value}});
        }
    };
    const ShareGraphInstance = (Instance) => {
        if (Instance) {
            Ref_GraphInstance.current = Instance;
        }
    };

    const HandleSliderChange = (index) => {
        let CurrentPoint = JsonPressureData[index];
        setCurrentPoint({t: CurrentPoint.t, P: CurrentPoint.P});

        setImgSrc(ImagesPath[index]);
        setImagePathIndex(index);
        //We need to unselect all points to prevent higcharts from losing its mind

        if (
            Ref_GraphInstance.current &&
            Ref_GraphInstance.current.series &&
            Ref_GraphInstance.current.series[0] &&
            Ref_GraphInstance.current.series[0].data &&
            Ref_GraphInstance.current.series[0].data[index]
        ) {
            Ref_GraphInstance.current.series[0].data[index].select(false, false);
            setTimeout(() => {
                // Ref_GraphInstance.current.series[0].data[index].select(true, true);

                Ref_GraphInstance.current.series[0].data[index].setState("select");

                Ref_GraphInstance.current.redraw();
            }, 10);
        }
    };

    const HandleShowPreview = () => {
        setShowBigPicture(true);
    };
    const HandleClosePreview = () => {
        setShowBigPicture(false);
    };

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    var GraphComponent = null;

    if (GraphData && ReloadGraph === false) {
        GraphComponent = (
            <div className={classes.GraphResults}>
                <GeneralGraph AddPoint={null} ShowFullChart={true} FullChartData={GraphData} ChartHeight="400px" ChartWidth="800px" ShareGraphInstance={ShareGraphInstance} />
            </div>
        );
    } else {
        GraphComponent = null;
    }

    var PictureComponent = null;
    // https://artibulles-es-dataserver.artibulles.com/api/fileserver/private/capture1.jpg
    PictureComponent = (
        <div className={classes.PictureWrapper}>
            <img onClick={HandleShowPreview} style={{maxWidth: "550px"}} src={ImgSrc} alt="mypic" />
        </div>
    );

    var FinalComponent = null;

    var SliderComponent = null;
    if (ReloadGraph === false) {
        SliderComponent = (
            <div className={classes.SliderWrapper}>
                <Slider railStyle={{backgroundColor: "grey"}} min={SliderOptions.min} max={SliderOptions.max} marks={SliderOptions.marks} onChange={(value) => HandleSliderChange(value)} />
            </div>
        );
    } else {
        SliderComponent = null;
    }
    var MixingSealComponent = null;
    var AccessSealComponent = null;
    if (HasMixingSealData === true) {
        MixingSealComponent = (
            <div className={classes.TestDetailsContent} style={{opacity: ViewChamber === "Access" ? "0.5" : "1"}}>
                <div className={classes.GageWrapper}>
                    <div className={classes.Gage}>
                        <div>Ease of opening</div>
                        <CircularGageFourQuarters
                            ExternalDiameter="110"
                            EmptyColor="rgb(220,220,220)"
                            Thickness="20"
                            GageTickThickness="1"
                            GageTickOverflow="0"
                            GageTickColor="black"
                            Value={MixingSealData.DetailsOpening.EaseOfOpeningGageValue}
                            MaxValue="100"
                            GageTriggers={{
                                Quarter1: {
                                    Text: "VERY EASY",
                                    TextColor: "black",
                                    GageColor: "rgb(100,204,98)",
                                    FontSize: "9px",
                                    FontWeight: "300",
                                    FontFamily: "Roboto",
                                    OffsetRadius: 1,
                                    InnerText: "18.5",
                                    OuterText: "60kg",
                                },
                                Quarter2: {
                                    Trigger: 25,
                                    Text: "EASY",
                                    TextColor: "black",
                                    GageColor: "rgb(66,185,245)",
                                    FontSize: "9px",
                                    FontWeight: "300",
                                    FontFamily: "Roboto",
                                    OffsetRadius: 1.2,
                                },
                                Quarter3: {
                                    Trigger: 50,
                                    Text: "HARD",
                                    TextColor: "black",
                                    GageColor: "orange",
                                    FontSize: "9px",
                                    FontWeight: "300",
                                    FontFamily: "Roboto",
                                    OffsetRadius: 1,
                                },
                                Quarter4: {
                                    Trigger: 75,
                                    Text: "VERY HARD",
                                    TextColor: "black",
                                    GageColor: "red",
                                    FontSize: "9px",
                                    FontWeight: "300",
                                    FontFamily: "Roboto",
                                    OffsetRadius: 1,
                                },
                            }}
                            WithOuterText={false}
                            WithInnerText={false}
                            // NeedleIndictator={{Active : true}}
                            GageCentralText={{
                                MainText: "Percent",
                                MainFontSize: "14px",
                                SubText: "Value",
                                SubFontSize: "14px",
                            }}
                            GageCustomCentralText={
                                <div style={{boxSizing: "border-box", display: "flex", flexDirection: "column", justifyContent: "center", flex: "1 0 auto"}}>
                                    <div style={{flex: "0 0 auto", display: "flex", alignItems: "center", justifyContent: "center", fontSize: "14px"}}>
                                        {MixingSealData.DetailsOpening.EaseOfOpeningGageText}
                                    </div>
                                </div>
                            }
                        />
                    </div>
                </div>

                <div className={classes.DetailsText}>
                    <div className={classes.Details_Row} style={{fontSize: "18px", fontWeight: "600"}}>
                        <div>Max Pressure :</div>
                        <div style={{marginLeft: "5px"}}>{parseFloat(MixingSealData.ExtremePoints.pmax.P).toFixed(1)} mbar</div>
                    </div>
                    <div className={classes.Details_Row}>
                        <div>Tot Duration :</div>
                        <div style={{marginLeft: "5px"}}>{MixingSealData.DetailsOpening.TestDuration} s</div>
                    </div>
                    <div className={classes.Details_Row}>
                        <div>Opening process :</div>
                        <div style={{marginLeft: "5px"}}>{MixingSealData.DetailsOpening.OpeningDescription}</div>
                    </div>
                </div>
            </div>
        );
    } else {
        MixingSealComponent = (
            <div className={classes.TestDetailsContent} style={{paddingTop: "10px"}}>
                No data
            </div>
        );
    }
    if (HasAccessSealData) {
        AccessSealComponent = (
            <div className={classes.TestDetailsContent} style={{opacity: ViewChamber === "Mixing" ? "0.5" : "1"}}>
                <div className={classes.GageWrapper}>
                    <div className={classes.Gage}>
                        <div>Ease of opening</div>
                        <CircularGageFourQuarters
                            ExternalDiameter="110"
                            EmptyColor="rgb(220,220,220)"
                            Thickness="20"
                            GageTickThickness="1"
                            GageTickOverflow="0"
                            GageTickColor="black"
                            Value={AccessSealData.DetailsOpening.EaseOfOpeningGageValue}
                            MaxValue="100"
                            GageTriggers={{
                                Quarter1: {
                                    Text: "VERY EASY",
                                    TextColor: "black",
                                    GageColor: "rgb(100,204,98)",
                                    FontSize: "9px",
                                    FontWeight: "300",
                                    FontFamily: "Roboto",
                                    OffsetRadius: 1,
                                    InnerText: "18.5",
                                    OuterText: "60kg",
                                },
                                Quarter2: {
                                    Trigger: 25,
                                    Text: "EASY",
                                    TextColor: "black",
                                    GageColor: "rgb(66,185,245)",
                                    FontSize: "9px",
                                    FontWeight: "300",
                                    FontFamily: "Roboto",
                                    OffsetRadius: 1.2,
                                },
                                Quarter3: {
                                    Trigger: 50,
                                    Text: "HARD",
                                    TextColor: "black",
                                    GageColor: "orange",
                                    FontSize: "9px",
                                    FontWeight: "300",
                                    FontFamily: "Roboto",
                                    OffsetRadius: 1,
                                },
                                Quarter4: {
                                    Trigger: 75,
                                    Text: "VERY HARD",
                                    TextColor: "black",
                                    GageColor: "red",
                                    FontSize: "9px",
                                    FontWeight: "300",
                                    FontFamily: "Roboto",
                                    OffsetRadius: 1,
                                },
                            }}
                            WithOuterText={false}
                            WithInnerText={false}
                            // NeedleIndictator={{Active : true}}
                            GageCentralText={{
                                MainText: "Percent",
                                MainFontSize: "14px",
                                SubText: "Value",
                                SubFontSize: "14px",
                            }}
                            GageCustomCentralText={
                                <div style={{boxSizing: "border-box", display: "flex", flexDirection: "column", justifyContent: "center", flex: "1 0 auto"}}>
                                    <div style={{flex: "0 0 auto", display: "flex", alignItems: "center", justifyContent: "center", fontSize: "14px"}}>
                                        {AccessSealData.DetailsOpening.EaseOfOpeningGageText}
                                    </div>
                                </div>
                            }
                        />
                    </div>
                </div>
                <div className={classes.DetailsText}>
                    <div className={classes.Details_Row} style={{fontSize: "18px", fontWeight: "600"}}>
                        <div>Max Pressure :</div>
                        <div style={{marginLeft: "5px"}}>{parseFloat(AccessSealData.ExtremePoints.pmax.P).toFixed(1)} mbar</div>
                    </div>
                    <div className={classes.Details_Row}>
                        <div>Tot Duration :</div>
                        <div style={{marginLeft: "5px"}}>{AccessSealData.DetailsOpening.TestDuration} s</div>
                    </div>
                    <div className={classes.Details_Row}>
                        <div>Opening process :</div>
                        <div style={{marginLeft: "5px"}}>{AccessSealData.DetailsOpening.OpeningDescription}</div>
                    </div>
                </div>
            </div>
        );
    } else {
        AccessSealComponent = (
            <div className={classes.TestDetailsContent} style={{paddingTop: "10px"}}>
                No data
            </div>
        );
    }

    let PictureViewerComponent = null;
    if (ShowBigPicture === true) {
        PictureViewerComponent = <PictureViewer ShowCard={true} ImgSrc={ImgSrc} HandleCloseViewCard={HandleClosePreview} ImagesPath={ImagesPath} ImagePathIndex={ImagePathIndex} />;
    } else {
        PictureViewerComponent = null;
    }
    if (ReloadAll === false) {
        FinalComponent = (
            <React.Fragment>
                <LocalCircularLoader Loading={Loading} WithModalCard={true} FullSize={true} />

                <div className={classes.MainInfo}>
                    <div className={classes.FormMultilineFlex} style={{justifyContent: "center"}}>
                        <div className={classes.FormField} style={{flex: "0 1 150px"}}>
                            <FormCompontent
                                Name="OperatorName"
                                InitialValue={InitialFormValues.OperatorName ? InitialFormValues.OperatorName : null}
                                FormValue={FormValues.OperatorName ? FormValues.OperatorName : null}
                                Component="Input"
                                Variant="OutlinedLabel"
                                Label="Operator"
                                ReadOnly={true}
                                // InputTextStyle={{fontSize: "12px"}}
                                // FieldMaxWidth="150px"
                                // LabelMaxWidth="30px"
                                // LabelScale="0,8"
                                // AnimateToolBarLeft={true}
                                // AnimateToolbarLeftColor={true}
                                // NoMessage={false}
                                // // FieldMaxWidth="100px"
                                // InternalToolbarLeftIcon={<AvatarV2 IconSize="25px" SVGFillColor="black" />}
                            />
                        </div>
                        <div className={classes.FormField} style={{flex: "0 1 250px"}}>
                            <FormCompontent
                                Name="test_date"
                                InitialValue={InitialFormValues.test_date ? InitialFormValues.test_date : null}
                                FormValue={FormValues.test_date ? FormValues.test_date : null}
                                Component="Input"
                                Variant="OutlinedLabel"
                                Label="Date"
                                NoMessage={false}
                                Required={true}
                                // FieldMaxWidth="200px"
                                InternalToolbarLeftIcon={<Calendar IconSize="25px" SVGFillColor="black" />}
                                AnimateToolBarLeft={true}
                                AnimateToolbarLeftColor={true}
                                ReadOnly={true}
                                // OnFieldExit={(status) => HandleFieldExit("AbortReason", status)}
                                // meta={{error: FormErrors.normal, invalid: FormErrors.normal ? true : false}}
                                // OutputError={(error) => HandleUpdateErrors("normal", error)}
                            />
                        </div>
                        <div className={classes.FormField} style={{flex: "0 1 250px"}}>
                            <FormCompontent
                                Name="Protocol_Id"
                                InitialValue={InitialFormValues.Protocol_Id ? InitialFormValues.Protocol_Id : null}
                                FormValue={FormValues.Protocol_Id ? FormValues.Protocol_Id : null}
                                // OutputValue={(values) => HandleUpdateFormValues("protocol_id", values)}
                                // OutputError={(error) => HandleUpdateErrors("protocol_id", error)}
                                // meta={{error: FormErrors.protocol_id, invalid: FormErrors.protocol_id ? true : false}}
                                Component="Input"
                                Variant="OutlinedLabel"
                                ReadOnly={true}
                                Label="Protocol Id"
                                NoMessage={false}
                                // Required={true}
                                // LocalValidation={{When: "Leaving"}}
                                // DisplayMessageIn="Popup"
                                // FieldMaxWidth="200px"
                            />
                        </div>
                        <div className={classes.FormField} style={{flex: "0 1 250px"}}>
                            <FormCompontent
                                Name="Sample_Id"
                                InitialValue={InitialFormValues.Sample_Id ? InitialFormValues.Sample_Id : null}
                                FormValue={FormValues.Sample_Id ? FormValues.Sample_Id : null}
                                // OutputValue={(values) => HandleUpdateFormValues("protocol_id", values)}
                                // OutputError={(error) => HandleUpdateErrors("protocol_id", error)}
                                // meta={{error: FormErrors.protocol_id, invalid: FormErrors.protocol_id ? true : false}}
                                Component="Input"
                                Variant="OutlinedLabel"
                                ReadOnly={true}
                                Label="Sample Id"
                                NoMessage={true}
                                // Required={true}
                                // LocalValidation={{When: "Leaving"}}
                                // DisplayMessageIn="Popup"
                                // FieldMaxWidth="200px"
                            />
                        </div>
                        <div className={classes.FormField} style={{flex: "0 1 250px"}}>
                            <FormCompontent
                                Name="Protocol_Id"
                                InitialValue={InitialFormValues.TestType ? InitialFormValues.TestType : null}
                                FormValue={FormValues.TestType ? FormValues.TestType : null}
                                // OutputValue={(values) => HandleUpdateFormValues("protocol_id", values)}
                                // OutputError={(error) => HandleUpdateErrors("protocol_id", error)}
                                // meta={{error: FormErrors.protocol_id, invalid: FormErrors.protocol_id ? true : false}}
                                Component="Input"
                                Variant="OutlinedLabel"
                                ReadOnly={true}
                                Label="Test Type"
                                NoMessage={false}
                                // Required={true}
                                // LocalValidation={{When: "Leaving"}}
                                // DisplayMessageIn="Popup"
                                // FieldMaxWidth="200px"
                            />
                        </div>
                    </div>
                </div>
                <div className={classes.SummaryResults}>
                    <div className={classes.MixingSealResults}>
                        <div className={classes.ChamberTitle}>
                            <h4 style={{margin: "0px", opacity: ViewChamber === "Access" ? "0.5" : "1"}}>Mixing seal</h4>
                            <div className={classes.ChamberTitle_Checkbox}>
                                <CheckBox
                                    Inputname="showmixingresults"
                                    LabelPosition="right"
                                    label="Show detailed results"
                                    initialvalue={InitialFormValues.showmixingresults === true ? true : false}
                                    curvalue={FormValues.showmixingresults}
                                    ShareInputValue={(value) => HandleCheckbox("showmixingresults", value)}
                                    UpdateProgrammatically={FormValues.showmixingresults}
                                />
                            </div>
                        </div>

                        {MixingSealComponent}
                    </div>
                    <div className={classes.AccessSealResults}>
                        <div className={classes.ChamberTitle}>
                            <h4 style={{margin: "0px", opacity: ViewChamber === "Mixing" ? "0.5" : "1"}}>Access seal</h4>
                            <div className={classes.ChamberTitleAccess_Checkbox}>
                                <CheckBox
                                    Inputname="showaccessresults"
                                    LabelPosition="left"
                                    label="Show detailed results"
                                    initialvalue={InitialFormValues.showaccessresults === true ? true : false}
                                    curvalue={FormValues.showaccessresults}
                                    ShareInputValue={(value) => HandleCheckbox("showaccessresults", value)}
                                    UpdateProgrammatically={FormValues.showaccessresults}
                                />
                            </div>
                        </div>

                        {AccessSealComponent}
                    </div>
                </div>

                <div className={classes.SliderToolBarWrapper}>
                    <div className={classes.SliderInfo} style={{justifyContent: "flex-end"}}>
                        <div>Time :</div>
                        <div> {CurrentPoint ? (CurrentPoint.t ? CurrentPoint.t.toFixed(1) : 0) : 0} s</div>
                    </div>
                    {SliderComponent}
                    <div className={classes.SliderInfo} style={{justifyContent: "flex-start"}}>
                        <div>Pressure :</div>
                        <div> {CurrentPoint ? (CurrentPoint.P ? CurrentPoint.P.toFixed(1) : 0) : 0} mbar</div>
                    </div>
                </div>

                <div className={classes.ChartAndPic}>
                    {GraphComponent}
                    {PictureComponent}
                    {PictureViewerComponent}
                </div>
            </React.Fragment>
        );
    } else {
        FinalComponent = <React.Fragment>Hello</React.Fragment>;
    }
    return (
        <Page PageRef={Ref_Page} WithPerfectScrollBar={true} PagePadding="10px" ClassExtendsPage={classes.PageContentExtends}>
            <div ClassName={classes.Main}>{FinalComponent}</div>
        </Page>
    );

    /***************** RENDER ******************/
};

export default ManualActivation;
