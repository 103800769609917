/* eslint-disable */
import React, {useEffect, useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import HighChartWrapper from "../../../artibulles-cis/HighChartWrapper/HighChartWrapper";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

const GeneralGraph = (props) => {
    const {AddPoint, FullChartData, ShowFullChart, ChartHeight, ChartWidth, ShareGraphInstance, EnableMouseHover = false} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const ChartInstance_Ref = useRef(null);
    //This defines the chart inputs when initiating
    var ChartInputsInit = {
        chart: null, //The chart options
        xAxis: null, //The XAxis Options
        yAxis: null, //The YAxis Options
        series: [], //The series to create the chart
        title: {text: "Pressure"}, //The chart title
    };

    ChartInputsInit.series.push({
        name: "Pressure",
        data: [[0, 0]],
        color: "blue",
        lineWidth: 2,
        // marker: {
        // 	radius: 0.1,
        // 	states: {
        // 		select: {
        // 			radius: 5
        // 		}
        // 	}
        // }
        marker: {
            enabled: true,
            radius: 5,
            lineColor: "orange",
            lineWidth: 1,
            fillColor: "orange",
            states: {
                select: {
                    radius: 6,
                    fillColor: "orange",
                },
            },
        },
        // dataLabels: {
        //     enabled: true,
        // },
    });

    ChartInputsInit.xAxis = {
        // labels: {
        //     formatter: function () {
        //         return `${format(this.value, "HH:mm")}`;
        //     },
        // },
        gridLineWidth: 1,
        crosshair: {
            // color: "yellow",
            width: 1,
        },
        // tickInterval :0.2,
        // tickInterval: 3600 * 1000,
        // min: 0,
        // max: 100,
        // min: getUnixTime(setHours(getTime(parseISO(WeigthMeasurement[0].date_iso)), 0)) * 1000,
        // // max: getUnixTime(setHours(getTime(parseISO(WeigthMeasurement[0].date_iso)), 24)) * 1000+1.5*60*60*1000,
        // max: getUnixTime(setHours(getTime(parseISO(WeigthMeasurement[0].date_iso)), 24)) * 1000 + 50 * 60 * 1000,
        showFirstLabel: true,
        showLastLabel: true,
        startOnTick: true,
        endOnTick: true,
        title: {
            text: "time [seconds]",
        },
    };
    ChartInputsInit.yAxis = [
        {
            title: {
                text: "Pressure [mbar]",
            },
            tickInterval: 5,
            max: 160,
            min: 0,
            // plotBands: [
            //     {
            //         color: "rgba(17, 237, 69,0.2)", // Color value
            //         from: 75, // Start of the plot band
            //         to: 80, // End of the plot band

            //         zIndex: 3,
            //         label: {
            //             text: "Weigh Goal", // Content of the label.
            //             align: "left", // Positioning of the label.
            //             // Default to center. x: +10 // Amount of pixels the label will be repositioned according to the alignment.
            //         },
            //     },
            // ],
        },
    ];
    ChartInputsInit.chart = {
        alignTicks: false,
    };
    ChartInputsInit.plotOptions = {
        series: {
            enableMouseTracking: EnableMouseHover,
        },

        // area: {
        //     marker: {
        //         enabled: false,
        //         states: {
        //             hover: {enabled: false},
        //         },
        //     },
        // },
    };
    /***************** CONST ******************/

    /***************** STATE ******************/

    const [ChartInputs, setChartInputs] = useState(ChartInputsInit);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    //Adding live point to the chart
    useEffect(() => {
        if (ChartInstance_Ref.current) {
            const LastEntryPoint = AddPoint;
            var Shift = false;
            if (ChartInstance_Ref && ChartInstance_Ref.current.series[0].data.length > 20) {
                Shift = true;
            }
            ChartInstance_Ref.current.series[0].addPoint(LastEntryPoint, true, Shift, false);
            // ChartInstance_Ref.current.series[0].addPoint(LastEntryPoint, false, false, false);
        }
    }, [AddPoint]);

    //Displaying the full chart when the test is stopped
    useEffect(() => {
        if (ShowFullChart === true) {
			console.log("display the full chart", FullChartData)
            var ChartUpdate = ChartInputsInit;
            ChartUpdate.series[0].data = FullChartData;
            ChartUpdate.series[0].marker = {
                enabled: false,
                // radius: 4,
                // lineColor: "white",
                lineWidth: 1,
            };
            setChartInputs(ChartUpdate);
        }
    }, [ShowFullChart]);

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    //Used to store the chart Instance and use it to update the chart
    const StoreChartInstance = (ref) => {
        ChartInstance_Ref.current = ref;
        if (ShareGraphInstance && typeof ShareGraphInstance === "function") {
            ShareGraphInstance(ref);
        }
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return <HighChartWrapper ChartInputs={ChartInputs} ChartInstance={StoreChartInstance} ChartHeight={ChartHeight} ChartWidth={ChartWidth} />;

    /***************** RENDER ******************/
};

export default GeneralGraph;
