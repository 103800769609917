import React, {useEffect, useState, useRef, useCallback} from 'react'
import {createUseStyles, useTheme} from 'react-jss'
import Button from "@artibulles-cis/react/Button";
import {socketPIsensor} from "../../../common/util/socketPIsensor";
//eslint-disable-next-line
const styles = createUseStyles(theme => ({
 
}));
 
const VideoRecording = React.memo(function VideoRecording (props) {
//eslint-disable-next-line
  const theme = useTheme();
//eslint-disable-next-line
  const classes = styles({...props, theme});
 
/***************** REFS ******************/
 
/***************** REFS ******************/
 
 
/***************** CONST ******************/
 
/***************** CONST ******************/
 
 
/***************** STATE ******************/
const [PISensorsSocketConnection, setPISensorsSocketConnection] = useState(false); //Checking the PI sensor Socket Connection
/***************** STATE ******************/
 
 
/***************** CALLBACK ******************/
 const HanaldeStoreVideo = useCallback((inputs)=> {
	console.log("inputs",inputs)

 },[])
/***************** CALLBACK ******************/
 
 
/***************** EFFECTS ******************/
useEffect(() => {
	//Requesting connection to the PI-Sensors Socket Server
	socketPIsensor.emit("RequestConnection", () => {});
	//Receiving confirmation
	const HandlePISensorConnection = () => setPISensorsSocketConnection(true);

	socketPIsensor.on("UserConnected", (data) => {
		console.log("Server confirmed scoket connection", data);
		HandlePISensorConnection();
	});
	


	 
	 socketPIsensor.on("VideoToStore", (inputs) => {
		let videoPath = inputs.paths;
		
		
			HanaldeStoreVideo(videoPath);
			
			
		
	});

	return () => {
		console.log("UseEffectReturn");
		socketPIsensor.off("UserConnected", HandlePISensorConnection);
	};
}, []);



/***************** EFFECTS ******************/
 
 
/***************** FUNCTIONS ******************/
 const HandleStartVideo = () => {
	 console.log("start video")
	 socketPIsensor.emit("TakeVideo",{
		width : 1641,
		height:1232,
		fps :40
	 });

 }
 const HandleStopVideo = () => {
	console.log("stop video")
	socketPIsensor.emit("StopVideo","mp4");
}
/***************** FUNCTIONS ******************/
 
/***************** RENDER ******************/
  return (
	<div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            Pi Connection : {JSON.stringify(PISensorsSocketConnection)}
            <Button onClick={HandleStartVideo}>Start Video</Button>
            <Button onClick={HandleStopVideo}>Stop Video</Button>
            
        </div>
  )
 
/***************** RENDER ******************/
 
});
 
export default VideoRecording;