/**
 * ScriptName : dateUtils.js
 * Version : 1.0.0
 * Date : 2020/06/17
 * Author: ArnaudJaspard
 * Description : description
 * License : N/A
 * Inputs :
 * Output:
 */

/********LOADING DEPENDENCIES************/
const {
    // format,
    // isSameDay,
    // differenceInCalendarDays,
    // addDays,
    add,
    fromUnixTime,
    startOfDay,
    getTime,
    // setHours,
    // getHours,
    // parseISO,
    // formatISO,
    // getUnixTime,
    // setMinutes,
    // setSeconds,
    // setMilliseconds,
    // sub,
    // getWeekOfMonth,
    // getDay,
} = require("date-fns");
/********LOADING DEPENDENCIES************/

/********CORE FUNCTION************/
exports.dateObjInUTC = function (date) {
    let ServerTimeOffset = new Date(Date.now()).getTimezoneOffset();
    let dateObjUTC0 = add(date, {minutes: parseInt(ServerTimeOffset)});
    return dateObjUTC0;
};
exports.dateObjInUTC_StartTUnix_DurationSec = function (StartTimeUnix, DurationSec) {
    let ServerTimeOffset = new Date(Date.now()).getTimezoneOffset();
    let dateobj = fromUnixTime((parseInt(StartTimeUnix) + parseInt(DurationSec) * 1000) / 1000);
    let dateObjUTC0 = add(dateobj, {minutes: parseInt(ServerTimeOffset)});
    return dateObjUTC0;
};

exports.Seconds_SinceStart = function (date) {
    let StartOfDay = startOfDay(date);
    let TimeSinceStart = date - StartOfDay;
    let dateObjUTC0 = getTime(TimeSinceStart) / 1000;
    return dateObjUTC0;
};

exports.convertSecondsToTime = (s, showSec = true) => {
    var h = s / (60 * 60);
    var hours = Math.floor(h);
    

    var m = (h - hours) * 60;
    var minutes = Math.floor(m);

    var ss = (m - minutes) * 60;
    var seconds = Math.floor(ss);

    if (showSec === false) {
        if (seconds > 30) {
            minutes = minutes + 1;
        }
        if (minutes >= 60) {
            hours = hours + 1;
            minutes = 0;
        }
    }
    var minutesFormatted = minutes > 9 ? minutes : "0" + minutes;
	var hoursFormatted = hours > 9 ? hours : "0" + hours;
	
    var secondsFormatted = seconds > 9 ? seconds : "0" + seconds;
    if (showSec === false) {
        return `${hoursFormatted}:${minutesFormatted}`;
    } else {
        return `${hoursFormatted}:${minutesFormatted}:${secondsFormatted}`;
    }
};
/********CORE FUNCTION************/

/********GLOBAL FUNCTIONS************/

/********GLOBAL FUNCTIONS************/

//Convert a date obj in UTC format
