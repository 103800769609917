/* eslint-disable */
import React, {useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";

import {Switch, Route} from "react-router-dom";
import BagFilling from "./BagFilling/BagFilling";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

const SamplePrepRouter = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <Switch>    
		<Route path="/sampleprep/bagfilling">
                <BagFilling />
            </Route>
        </Switch>
    );

    /***************** RENDER ******************/
};

export default SamplePrepRouter;
