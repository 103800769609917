import React, {useState, useRef, useCallback, useEffect} from "react";
import PropTypes from "prop-types";

import {createUseStyles, useTheme} from "react-jss";
import Card from "../Card/Card";

const LockedstylesWithProps = (props) => {
    //Used to prevent the user from editing those styles other than those specified with props
    return {
        preloader: {
            position: props.FullSize === true ? "absolute" : props.ModalParentID ? "absolute" : "relative",
            maxWidth: "100%",
            width: "100%",
            maxHeight: "100%",
            height: props.FullHeight ? "100%" : null,
            boxSizing: "content-box",
            // display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
    };
};

const styles = createUseStyles((theme) => ({
    //Used to upload the theme classes
    LocalCircularLoaderIOS: {},
    Loader: {},
    LoaderWithModal: {
        display: "flex",
        height: "100%",
        width: "100%",
    },
    ClassOverrideCard: {
        borderRadius: "0px",
        background: "none",
        // backgroundColor: "rgba(255, 255, 255, 0.226)",
        // color: "white",
        // textAlign: "center",
        // background: "linear-gradient(0deg, rgba(15, 14, 14, 0.9) 0%, rgba(49, 49, 49, 0.9) 100%)",
        // boxShadow:
        //   "rgba(0, 0, 0, .419608) 0 14px 26px -12px, rgba(0, 0, 0, .117647) 0 4px 23px 0, rgba(0, 0, 0, .2) 0 8px 10px -5px",
        // backgroundColor: "rgba(250, 250, 250, 0.9)"
    },
}));

/**
 * Create a Local Circular Loader Element
 */
const LocalCircularLoaderIOS = React.forwardRef(function LocalCircularLoaderIOS(props, ref) {
    const {
        WithModalCard,
        //eslint-disable-next-line
        FullSize,
        ModalCardBackground,
        ModalParentID,
        FitToParentDimensions,
        Loading,
        //
        SpinnerDimension,
        SpinnerCount,
        SpinnerRadius,
        SpinnerColor,
        SpinnerSpeedDuration,
        SpinnerBorderRadius,
        SpinnerRectHeight,
        SpinnerRectWidth,
        Message,
        children,
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});
    const LoaderRef = useRef();
    const [LoaderHeight, setLoaderHeight] = useState();

    const calculateLoaderHeight = useCallback(() => {
        if (LoaderRef && LoaderRef.current) {
            var parentHeight, ContentHeight;
            //Calculate the content page height
            let parentNode = LoaderRef.current.parentNode;
            parentHeight = parentNode.getBoundingClientRect().height;
            ContentHeight = parseFloat(parentHeight) + "px";
            setLoaderHeight(ContentHeight);
        }
    }, [LoaderRef]);

    useEffect(() => {
        calculateLoaderHeight();
    }, [calculateLoaderHeight]);

    //Extracting the Theme Default values to pass them as inputs

    var Radius, RectHeight, rectY, RectRadiusRatio, RectWidth, TotalItem, SVGDimensions;
    if (SpinnerDimension) {
        SVGDimensions = SpinnerDimension;
    } else {
        SVGDimensions = 50 + "px";
    }

    if (SpinnerCount) {
        let r = parseInt(SpinnerCount);
        if (r < 0) {
            TotalItem = 10;
        } else if (r > 50) {
            TotalItem = 50;
        } else {
            TotalItem = SpinnerCount;
        }
    } else {
        TotalItem = 10;
    }

    if (SpinnerRectWidth) {
        let r = parseInt(SpinnerRectWidth);
        if (r < 0) {
            RectWidth = 10;
        } else {
            RectWidth = SpinnerRectWidth;
        }
    } else {
        RectWidth = 10;
    }

    if (SpinnerBorderRadius) {
        let r = parseInt(SpinnerBorderRadius);
        if (r > 100) {
            RectRadiusRatio = 100;
        } else if (r < 0) {
            RectRadiusRatio = 0;
        } else {
            RectRadiusRatio = SpinnerBorderRadius;
        }
    } else {
        RectRadiusRatio = 50;
    }

    if (SpinnerRectHeight) {
        let rh = parseInt(SpinnerRectHeight);
        if (rh > 50) {
            RectHeight = 50;
        } else {
            RectHeight = rh;
        }
    } else {
        RectHeight = 12;
    }

    if (SpinnerRadius) {
        let r = parseInt(SpinnerRadius);
        if (r < 0) {
            Radius = 0;
        } else if (r > 50) {
            Radius = 50;
        } else {
            Radius = r;
        }
    } else {
        Radius = 50;
    }
    let FinalRadius = 50 - Radius;

    //Check that if fits if not -> Change the inputs to make it fit

    rectY = FinalRadius;
    let CheckRFit = rectY + RectHeight;
    if (CheckRFit > 50) {
        rectY = 50 - CheckRFit;
    }

    var rx = (RectWidth * RectRadiusRatio) / 100;
    var ry = (RectHeight * RectRadiusRatio) / 100;
    var rectX = 47;
    // var rectY=24;

    const SVGRectangles = () => {
        var FinalSVGRect = [];
        let rot = parseFloat(360 / TotalItem);
        let timegap = parseFloat(SpinnerSpeedDuration / TotalItem);
        for (var i = 0; i < TotalItem; i++) {
            FinalSVGRect.push(
                <g key={i} transform={`rotate(${rot * i} 50 50)`}>
                    <rect x={rectX} y={rectY} rx={rx} ry={ry} width={RectWidth} height={RectHeight} fill={SpinnerColor}>
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur={`${SpinnerSpeedDuration}s`} begin={`-${(TotalItem - i) * timegap}s`} repeatCount="indefinite"></animate>
                    </rect>
                </g>
            );
        }
        return FinalSVGRect;
    };

    var FinaLocalLoader;
    if (Loading) {
        FinaLocalLoader = (
            <div className={WithModalCard === true ? classes.LoaderWithModal : classes.Loader}>
                <svg style={{margin: "auto", display: "block"}} width={`${SVGDimensions}`} height={`${SVGDimensions}`} viewBox="0 0 100 100">
                    {SVGRectangles()}
                </svg>
            </div>
        );
    } else {
        FinaLocalLoader = null;
    }
    var MessageComponent = null;
    if (Message) {
        MessageComponent = Message;
    }
    var FinalLoader;
    if (WithModalCard) {
        if (Loading) {
            FinalLoader = (
                <Card
                    CardID="MyCard"
                    IsDraggable={false}
                    ParentRefCompID={ModalParentID}
                    FitToParentDimensions={FitToParentDimensions}
                    CardBackgroundColor="none"
                    ClassOverrideCard={classes.ClassOverrideCard}
                    CardBackground="none"
                    WithPerfectScrollbar={false}
                    AlertUserWhenLeavingPage={false}
                    CardWindowPaddingVertical="0px"
                    CardWindowPaddingHorizontal="0px"
                    ZIndex="800"
                    WindowBackgroundColor={ModalCardBackground}
                    // CardMaxWidth={}
                    CardHeight={LoaderHeight}
                >
                    {MessageComponent}
                    {FinaLocalLoader}
                </Card>
            );
        } else {
            FinalLoader = null;
        }
    } else {
        FinalLoader = <React.Fragment>{FinaLocalLoader}</React.Fragment>;
    }

    return (
        <div
            ref={LoaderRef}
            style={{
                ...LockedstylesWithProps(props).preloader,
                ...{height: LoaderHeight, display: Loading ? "flex" : "none"},
            }}
            className={classes.LocalCircularLoaderIOS}
        >
            {FinalLoader}
            {children}
        </div>
    );
});

LocalCircularLoaderIOS.defaultProps = {
    FullSize: false,
    WithModalCard: false,
    ModalCardBackground: "rgba(0, 0, 0, 0.2)",
    ModalParentID: "",
    FitToParentDimensions: false,
    Loading: false,
    SpinnerDimension: "30px",
    SpinnerCount: 12,
    SpinnerRectHeight: 25,
    SpinnerRectWidth: 6,
    SpinnerRadius: 50,
    SpinnerColor: "rgb(66, 165, 245)",
    SpinnerSpeedDuration: 0.8,
    SpinnerBorderRadius: 30,
    Message: null,
};

LocalCircularLoaderIOS.propTypes = {
    /**
     * Add or not a modal card
     */
    FullSize: PropTypes.bool,
    /**
     * Add or not a modal card
     */
    WithModalCard: PropTypes.bool,
    /**
     * Background Color of the modal card : rgba(0,0,0,0.2)
     */
    ModalCardBackground: PropTypes.string,
    /**
     * id of the parent component where the card will be inserted
     */
    ModalParentID: PropTypes.string,
    /**
     * Fit to the parent dimensions
     */
    FitToParentDimensions: PropTypes.bool,
    /**
     * Pass the Loading props to display the loader
     */
    Loading: PropTypes.bool,
    /**
     * The Dimension of the SVG. Easiest way to change the size of the loader without customizing too much
     */
    SpinnerDimension: PropTypes.string,
    /**
     * color of the spinner
     */
    SpinnerColor: PropTypes.string,
    /**
     * the number of small path for the spinner
     */
    SpinnerCount: PropTypes.number,
    /**
     * Dimension of the rectangle
     */
    SpinnerRectHeight: PropTypes.number,
    /**
     * Dimension of the rectangle
     */
    SpinnerRectWidth: PropTypes.number,
    /**
     * Radius of the spinner
     */
    SpinnerRadius: PropTypes.number,
    /**
     * duration of the animation rotation
     */
    SpinnerSpeedDuration: PropTypes.number,
    /**
     * Radius of the spinner elements
     */
    SpinnerBorderRadius: PropTypes.number,
    /**
     * Message : A message to be displayed -> in dev mode to be upgraded
     */
    Message: PropTypes.any,
};
export default LocalCircularLoaderIOS;
