/* eslint-disable */
import React, {useState, useRef, useEffect, useCallback} from "react";
import {Switch, Route, useHistory} from "react-router-dom";
import {createUseStyles, useTheme} from "react-jss";
import _ from "lodash";
import CardModal from "@artibulles-cis/react/CardModal";
import FormCompontent from "@artibulles-cis/react/FormComponent";
import Button from "@artibulles-cis/react/Button/Button";
import {WarningHexagonRed} from "@artibulles-cis/react-icons";
import {toast} from "react-toastify";
import APICallExternal from "../../../../../artibulles-cis/utils/APICallExternal";

import ManualActivationFullBagStep1 from "./ManualActivationFullBagStep1";
import ManualActivationFullBagStep2 from "./ManualActivationFullBagStep2";
import ManualActivationFullBagStep3 from "./ManualActivationFullBagStep3";


//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    CardContent: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
		color : "black",
		textAlign : "left"
    },
    CardTopBar: {
        display: "flex",
        height: "50px",
        background: "#2c2c2c",
        position: "fixed",
        top: "0px",
        left: "0px",
        width: "100%",
        color: "white",
        zIndex: "1000",
    },
    MainContent: {
        position: "relative",
        top: "50px",
        padding: "5px 10px",
    },
    TopBarLeft: {
        // position: "absolute",
        // left: "0px",
        // top: "0px",
        flex: "0 0 500px",
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
        padding: "0px 10px 0px 10px",
    },

    ArtibullesApp_Content: {
        fontSize: "30px",
        color: "white",
    },
    ArtibullesApp_Text: {
        flex: "1 0 auto",
        color: "white",
        margin: "0px",
    },
    ArtibullesApp_Logo: {
        width: "45px",
        height: "45px",
    },
    TopBarMiddle: {
        // position: "absolute",
        // left: "0px",
        // top: "0px",
        flex: "1 0 auto",
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
        padding: "0px 10px 0px 10px",
        justifyContent: "center",
    },
    TopBarRight: {
        // position: "absolute",
        // left: "0px",
        // top: "0px",
        flex: "0 0 500px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        boxSizing: "border-box",
        padding: "0px 10px 0px 10px",
    },
    AbortButton: {
        margin: "0px",
        cursor: "pointer",
    },
    CardExtendedAbort: {
        borderRadius: "10px",
    },
    AbortMessageMain: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
    AbortWarningMessage: {
        flex: "1 0 auto",
        padding: "10px 10px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    Intro: {
        display: "flex",
        alignItems: "center",
        maxWidth: "1200px",
    },
    Intro_Icon: {
        flex: "0 0 50px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    Intro_Message: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        // alignItems: "center",
        marginLeft: "20px",
    },
    h: {
        margin: "5px 0px",
    },
    FormField: {
        // display : "flex",
        margin: "0px 10px 0px 10px",
        width: "350px",
    },
    ButtonBar: {
        display: "flex",
        padding: "0px 10px",
        justifyContent: "space-between",
    },
}));

const ManualActivationFullBagTestRouter = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});
    const History = useHistory();

    /***************** CONST ******************/
    const Ref_FullTestData = useRef({
        OperatorName: null,
        TestDateTimeUnix: null,
        Protocol_Id: null,
        Sample_Id: null,
        TestType: "Full Bag",
        SensorInstallation: null, //PortTube or Needle,
        SensorDeviceSerial: null,
        ZeroSensorData: {
            MeanAbsPressure: null,
            MinAbsPressure: null,
            MaxAbsPressure: null,
            Std: null,
            DeltaMinMax: null,
            ZeroSensorValue: null,
            ZeroSensorRawData: [], //the array used to zero the sensor
            ZeroSensorPressureData: [], //the array used to zero the sensor
        },
        TestAborted: {
            ABorted: null,
            Abort_reson: null,
        },
        MixingSealData: {
            RawSensorData: null,
            GraphSensorData: null,
            ImagesPaths: null,
            TestDuration: null,
            MaxPressure: null,
            SealOpened: null,
            sealNotopenedReason: null,
            Issue: null,
            IssueDescription: null,
            DiscardTest: null,
            DiscardReason: null,
            EaseOfOpeningRanking: null,
            OpeningDescription: null,
        },
        AccessSealData: {
            RawSensorData: null,
            GraphSensorData: null,
            ImagesPaths: null,
            TestDuration: null,
            MaxPressure: null,
            SealOpened: null,
            sealNotopenedReason: null,
            Issue: null,
            IssueDescription: null,
            DiscardTest: null,
            DiscardReason: null,
            EaseOfOpeningRanking: null,
            OpeningDescription: null,
        },
    });
    /***************** CONST ******************/

    /***************** STATE ******************/

    const [TestReference, setTestReference] = useState(null); //Used to display sample and protocol IDs in the top bar
    const [ZeroSensorValue, setZeroSensorValue] = useState(null); //Used to share the initial atm pressure to zero the sensor
    const [ShowAbortMessage, setShowAbortMessage] = useState();
    const [ShowAbortButton, setShowAbortButton] = useState("none");
    const [FormValues, setFormValues] = React.useState({AbortReason: null}); //FINAL FORM VALUES
    const [PristineDetails, setPristineDetails] = useState({});
    //eslint-disable-next-line
	const [Pristine, setPristine] = useState(true); //Form has been edited by the user -> Pristie === false
    const [ApiRes, setApiRes] = useState(null);
    const [Loading, setLoading] = useState(false);
    const [Phase, setPhase] = useState(null);

    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    //eslint-disable-next-line
	const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                //Something went wrong for instance wrong credentials are captured here
                console.log("error EndofApi", ApiRes);
                // toast.error(`Error : ${ApiRes.errorMessage.message}`, {autoClose: true});
            } else {
                if (Phase === "Mixing Final") {
                    toast.success("Mixing seal data saved", {autoClose: true});
                    setTimeout(() => {
                        History.push("/testing/manualactivation/fullbag/step3");
                        setPhase(null);
                    }, 1000);
                } else {
                    toast.success("Final test results submitted", {autoClose: true});
                    setTimeout(() => {
                        History.push("/");
                    }, 1000);
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleAbortTest = () => {
        setShowAbortMessage(true);
    };

    const HandleCancelAbort = () => {
        setShowAbortMessage(false);
    };
    const HandleConfirmAbort = () => {
        //Get the abort reason and send it to the server

		
        //Send to API

        //If Ok -> close and redirect
        History.push("/");
    };

    const HandleUpdateFormValues = (field, values) => {
        let value = values.Value;
        
        let FieldPristine = values.Pristine;

        //Checking Pristine

        // Update the pristine based on the Field
        var FinalPristine = true;
        let PristineKeys = _.keys(PristineDetails);
        let UpdatedPristineDetails = PristineDetails;

        if (PristineKeys && PristineKeys.length > 0) {
            //Going through all the pristine fields to check if one of them is false
            var CurrentFieldHandled = false;
            PristineKeys.forEach((key) => {
                if (key === field) {
                    CurrentFieldHandled = true;
                    //Update the pristine value
                    UpdatedPristineDetails[field] = FieldPristine;
                    if (FieldPristine === false) {
                        FinalPristine = false;
                    }
                } else {
                    //just checking if false;
                    if (UpdatedPristineDetails[key] === false) {
                        FinalPristine = false;
                    }
                }
            });
            if (CurrentFieldHandled === false) {
                //The key wasn't there so we need to add it
                if (FieldPristine === false) {
                    UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                    FinalPristine = false;
                }
            }
        } else {
            //First field to be touched
            if (FieldPristine === false) {
                UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                FinalPristine = false;
            }
        }
        setPristineDetails(UpdatedPristineDetails);
        setFormValues({...FormValues, ...{[field]: value}});
        setPristine(FinalPristine);
        //Manual update the pristine
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/
        // let FormValuesKeys = _.keys(FormValues);
        // var FinalPristine = true;

        // if (FormValuesKeys && FormValuesKeys.length > 0) {
        //     FormValuesKeys.forEach((key) => {
        //         let InitialValue = InitialFormValues[key];

        //         var Value;
        //         if (key !== field) {
        //             Value = FormValues[key];
        //         } else {
        //             //Check that the modified field is not the same as the InitalValue (The value has not changed yet)
        //             Value = value;
        //         }
        //         if (InitialValue === undefined) {
        //             InitialValue = null; //We set it to null to be able to compare to the Input Value that is set to null
        //             //It means that there was no initialValue passed to the form (that's the case when it is new)
        //         }
        //         if (InitialValue !== Value) {
        //             FinalPristine = false;
        //         }
        //     });
        // }

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        //Confirm Errors
        // ValidateForm(field, value);
        // HandleFiedsChange(field, value);
    };
    const HandleFieldExit = (Field, status) => {
        if (status === "Left") {
            //Show the Abort button if not empty
            if (FormValues.AbortReason) {
                setShowAbortButton("flex");
            } else {
                setShowAbortButton("none");
            }
        }
    };

    const HandleStoreFullData = (data, submitType) => {
        //We update all data in the ref
        let CurrentTestData = Ref_FullTestData.current;
        const UdpatedData = _.merge(CurrentTestData, data);
        // console.log("UdpatedData", UdpatedData);
        //Because of Axios errors when the data is too large, we remove the raw sensor data for now, it's in the files anyway
        if (UdpatedData.MixingSealData) {
            UdpatedData.MixingSealData.RawSensorData = null;
        }
        if (UdpatedData.AccessSealData) {
            UdpatedData.AccessSealData.RawSensorData = null;
        }

        var FinalSubmitData = {
            chamber: null,
            data: UdpatedData,
        };
        if (submitType) {
            if (submitType === "Mixing Final") {
                console.log("submitting mixing only");
                FinalSubmitData.chamber = "mixing";
                FinalSubmitData.data.AccessSealData = null;
                setPhase("Mixing Final");
            } else if (submitType === "Access Final") {
                console.log("submitting access only");
                FinalSubmitData.chamber = "access";
                FinalSubmitData.data.MixingSealData = null;
            }
            if (submitType === "Mixing not opened" || submitType === "Mixing opened discard data") {
                //We need to delete the pressure data and we need to delete all the pictures on the server
                let newMixingSealData = {
                    RawSensorData: null,
                    GraphSensorData: null,
                    ImagesPaths: null,
                    TestDuration: null,
                    MaxPressure: null,
                    SealOpened: null,
                    sealNotopenedReason: null,
                    Issue: null,
                    issueDescription: null,
                    DiscardTest: null,
                    DiscardReason: null,
                    EaseOfOpeningRanking: null,
                    OpeningDescription: null,
                };
                CurrentTestData.MixingSealData = newMixingSealData;
            }
            console.log("FinalSubmitData", FinalSubmitData);
            const ApiCall = {url: `https://artibulles-es-dataserver.artibulles.com/api/pdprotectesting/manualactivation/addtestresult`, type: "post", data:FinalSubmitData};
            // const ApiCall = {url: `http://192.168.1.110:3661/api/pdprotectesting/manualactivation`, type: "post"};
            setLoading(true);

            async function APIInternal() {
                const res = await APICallExternal(ApiCall);

                setApiRes(res);
                setLoading(false);
            }
            APIInternal();
        }
    };

    const HandleShareTestReference = (data) => {
        setTestReference({sample_Id: data.sample_Id ? data.sample_Id : null, protocol_Id: data.protocol_Id ? data.protocol_Id : null});
    };
    const ShareZeroSensorValue = (data) => {
        setZeroSensorValue(data);
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    var ToolBarRefComp = null;
    if (TestReference) {
        ToolBarRefComp = `- Protocol: ${TestReference.protocol_Id ? TestReference.protocol_Id : ""} - Sample : ${TestReference.sample_Id ? TestReference.sample_Id : ""}`;
    }
    return (
        <React.Fragment>
            <CardModal
                // CardID="HealFit_CreateDailyIntake"
                // ParentCompRef={Ref_TabNavContent}
                // TopBarContent={CardTitle}
                // CloseCard={HandleCloseViewCard}
                // CloseOnClickOutside={true}
                // WithCloseButton={true}
                // CardMaxWidth="400px"
                // CardHeight="500px"
                WithPerfectScrollbar={true}
                CardWindowPaddingVertical="0px"
                CardWindowPaddingHorizontal="0px"
                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.6)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.CardExtended}
            >
                <div className={classes.CardContent}>
                    <div className={classes.CardTopBar}>
                        <div className={classes.TopBarLeft}>
                            <img className={classes.ArtibullesApp_Logo} src="/images/Artibulles_logo_ES_black_fontexp.svg" alt="Artibulles_Logo" />
                            <p style={{padding: "10px"}} className={classes.ArtibullesApp_Text}>
                                PeelSeals Pressure Tests
                            </p>
                        </div>
                        <div className={classes.TopBarMiddle}>Manual activation - Full bag testing {ToolBarRefComp}</div>

                        <div className={classes.TopBarRight}>
                            <Button Background="red" ClassExtendButton={classes.AbortButton} onClick={HandleAbortTest}>
                                Abort test
                            </Button>
                        </div>
                    </div>
                    <div className={classes.MainContent}>
                        <Switch>
                            <Route exact path="/testing/manualactivation/fullbag/step1">
                                <ManualActivationFullBagStep1
                                    HandleStoreFullData={HandleStoreFullData}
                                    HandleShareTestReference={HandleShareTestReference}
                                    ShareZeroSensorValue={ShareZeroSensorValue}
                                />
                            </Route>
                            <Route exact path="/testing/manualactivation/fullbag/step2">
                                <ManualActivationFullBagStep2
                                    HandleStoreFullData={HandleStoreFullData}
                                    ZeroSensorValue={ZeroSensorValue}
									ProtocolID={Ref_FullTestData.current ? Ref_FullTestData.current.Protocol_Id : null}
                                    SampleID={Ref_FullTestData.current ? Ref_FullTestData.current.Sample_Id : null}
                                />
                            </Route>
                            <Route exact path="/testing/manualactivation/fullbag/step3">
                                <ManualActivationFullBagStep3
                                    HandleStoreFullData={HandleStoreFullData}
                                    ZeroSensorValue={ZeroSensorValue}
									ProtocolID={Ref_FullTestData.current ? Ref_FullTestData.current.Protocol_Id : null}
                                    SampleID={Ref_FullTestData.current ? Ref_FullTestData.current.Sample_Id : null}
                                />
                            </Route>
                        </Switch>
                    </div>
                </div>
            </CardModal>
            <CardModal
                // CardID="HealFit_CreateDailyIntake"
                // ParentCompRef={Ref_TabNavContent}
                // TopBarContent={CardTitle}
                // CloseCard={HandleCloseViewCard}
                // CloseOnClickOutside={true}
                // WithCloseButton={true}
                CardMaxWidth="400px"
                CardHeight="500px"
                WithPerfectScrollbar={true}
                // CardWindowPaddingVertical="0px"
                // CardWindowPaddingHorizontal="0px"
                ShowCard={ShowAbortMessage}
                WindowBackgroundColor="rgba(0,0,0,0.6)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.CardExtendedAbort}
            >
                <div className={classes.AbortMessageMain}>
                    <div className={classes.AbortWarningMessage}>
                        <div className={classes.Intro}>
                            <div className={classes.Intro_Icon}>
                                <WarningHexagonRed IconSize="50px" />
                            </div>

                            <div className={classes.Intro_Message}>
                                <h4 className={classes.h}>You are about to stop a test</h4>
                            </div>
                        </div>
                        <div style={{margin: "10px 0px 10px 0px"}}>Please enter the reason for aborting the test</div>
                        <div className={classes.FormField}>
                            <FormCompontent
                                Name="abort_reason"
                                // InitialValue={InitialFormValues.standard_input ? InitialFormValues.standard_input : null}
                                FormValue={FormValues.AbortReason ? FormValues.AbortReason : null}
                                OutputValue={(values) => HandleUpdateFormValues("AbortReason", values)}
                                Component="Input"
                                Variant="OutlinedLabel"
                                Label="Abort reason"
                                NoMessage={true}
                                multiline={true}
                                RowsToDisplay="4"
                                MaxRows="4"
                                OnFieldExit={(status) => HandleFieldExit("AbortReason", status)}
                                // meta={{error: FormErrors.normal, invalid: FormErrors.normal ? true : false}}
                                // OutputError={(error) => HandleUpdateErrors("normal", error)}
                            />
                        </div>
                    </div>
                    <div className={classes.ButtonBar}>
                        <div style={{display: ShowAbortButton}}>
                            <Button style={{marginRight: "5px"}} Background="red" onClick={HandleConfirmAbort}>
                                Abort the test
                            </Button>
                        </div>
                        <Button style={{marginLeft: "5px"}} onClick={HandleCancelAbort}>
                            Cancel Abort
                        </Button>
                    </div>
                </div>
            </CardModal>
        </React.Fragment>
    );

    /***************** RENDER ******************/
};

export default ManualActivationFullBagTestRouter;
