import React from "react";
import {createUseStyles, useTheme} from "react-jss";
import Button from "@artibulles-cis/react/Button";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    CameraPreview: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px 50px",
    },
}));

const CameraPreview = (props) => {
    const {imgSrc, HandleShareConfig, CloseCardFromInside} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleShareConfigWithParent = (selection) => {
        if (HandleShareConfig && typeof HandleShareConfig === "function") {
            HandleShareConfig(selection);
            CloseCardFromInside();
        }
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    var CameraStatusComponent = null;
    if (imgSrc) {
        CameraStatusComponent = <img src={imgSrc} alt="stream" style={{width: "700px", height: "auto"}} />;
    } else {
        CameraStatusComponent = <h2>The camera is not connected</h2>;
    }

    return (
        <div className={classes.CameraPreview}>
            {CameraStatusComponent}
            <div style={{display: "flex", marginTop: "10px"}}>
                <Button style={{margin: "0px 5px 0px 0px"}} onClick={() => HandleShareConfigWithParent("Ready")}>
                    Camera is ready
                </Button>
            </div>
        </div>
    );

    /***************** RENDER ******************/
};

export default CameraPreview;
