import React from "react";
import WebsiteAppInit from "@artibulles-cis/react/WebsiteAppInit";

import ArtiwebThemeProvider from "@artibulles-cis/react/ArtiwebThemeProvider";
import MainTheme from "./ArtibullesCIS-react-Themes/mainTheme";

import AppDesktop from "./AppDesktop";
import AppMobile from "./AppMobile";

// toast.configure();
const App = (props) => {
    const AppConfiguration = {
        NoResizeCalc: true,
        appMaxWidth: "2500px",
        mobileWidth: "1000px",
        GeoLocation: false,
        withCookies: false,
        animationIntro: false,
    };
    return (
        <ArtiwebThemeProvider Theme={MainTheme}>
            <WebsiteAppInit {...AppConfiguration} MobileComponent={AppMobile} DesktopComponent={AppDesktop} />
        </ArtiwebThemeProvider>
    );
};
export default App;
