/* eslint-disable */
import React, {useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";
import Button from "@artibulles-cis/react/Button";
import {sockeQRServer} from "../../../common/util/socketQRServer.js";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

const DocRouter = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/
    const [PISensorsSocketConnection, setPISensorsSocketConnection] = useState(false); //Checking the PI sensor Socket Connection
    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        //Requesting connection to the PI-Sensors Socket Server
        sockeQRServer.emit("RequestConnection", () => {});
        //Receiving confirmation
        const HandlePISensorConnection = () => setPISensorsSocketConnection(true);

        sockeQRServer.on("UserConnected", (data) => {
            console.log("Server confirmed scoket connection", data);
            HandlePISensorConnection();
        });
        sockeQRServer.on("Your request", (data) => {
            console.log("Answer", data);
        });

        return () => {
            console.log("UseEffectReturn");
            sockeQRServer.off("UserConnected", HandlePISensorConnection);
        };
    }, []);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleSendRequest = () => {
        sockeQRServer.emit("Request Something");
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <div>
            Test doc
            <Button onClick={HandleSendRequest}> Request</Button>
        </div>
    );

    /***************** RENDER ******************/
};

export default DocRouter;
