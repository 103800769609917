/* eslint-disable */
import React, {useEffect, useState, useRef, useCallback} from "react";
import ReactTooltip from "react-tooltip";
import {useHistory} from "react-router-dom";
import {createUseStyles, useTheme} from "react-jss";
import _ from "lodash";
// import {socketQRServer} from "../../../../../common/util/socketQRServer";
import {socketPIsensor} from "../../../../../common/util/socketPIsensor";
import {AvatarV2, Calendar, QRCode, CheckBoxCircularFilled} from "@artibulles-cis/react-icons";
import FormCompontent from "@artibulles-cis/react/FormComponent";
import Button from "@artibulles-cis/react/Button/Button";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import LocalCircularLoaderIOS from "../../../../../artibulles-cis/LocalCircularLoaderIOS/LocalCircularLoaderIOS";
import CheckBox from "../../../../../artibulles-cis/CheckBox/CheckBox";
const {jStat} = require("jstat");
const {format, getUnixTime, fromUnixTime, parseISO, formatISO} = require("date-fns");
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    Main: {
		color : "black",
		textAlign : "left"
	},
    Step: {
        borderBottom: "1px solid grey",
    },
    Step_Title: {
        "& h2": {margin: "5px 0px"},
    },
    Step_TitleFinal: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        marginTop: "10px",
        "& h2": {margin: "0px"},
    },
    Step_Content: {
        padding: "10px 20px",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "4px 0px 4px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
    FormFieldCheckBoxAligned: {
        flex: "0 1 600px",
        margin: "10px 0px 0px 0px",
    },
    Step2_Wrapper: {
        display: "flex",
    },
    Step2_Left: {
        flex: "0 0 400px",
    },
    Step2_Right: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        padding: "0px 0px 0px 20px",
        alignItems: "flex-start",
    },
    Description_Picture_Wrapper: {
        display: "flex",
    },
    Description_Picture: {
        width: "300px",
        height: "auto",
    },
    Instructions: {
        fontSize: "1.2rem",
        "& li": {
            margin: "5px 0px",
        },
    },
    ConnectionCheck: {
        fontSize: "0.8rem",
        marginTop: "5px",
        display: "flex",
        alignItems: "center",
        "& p": {
            margin: "0px 0px 0px 0px ",
        },
    },
}));

const ManualActivationFullBagStep1 = React.memo(function ManualActivationFullBagStep1(props) {
    const {HandleStoreFullData, HandleShareTestReference, ShareZeroSensorValue} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const History = useHistory();
    const Ref_FormValues = useRef({
        operator_name: "Arnaud Jaspard",
        operator_id: null,
        test_date: format(Date.now(), "dd-MMM-yyyy , hh:mm"),
        protocol_id: null,
        sample_id: null,
        sensor_installation: null,
        devmode: false,
    });
    const Ref_SensorCalibrationData = useRef([]); //raw sensor data from calibration
    const Ref_SensorSerial = useRef(null);
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [QRSocketConnection, setQRSocketConnection] = useState(false); //Checking the QRcode Socket Connection
    const [PISensorsSocketConnection, setPISensorsSocketConnection] = useState(false); //Checking the PI sensor Socket Connection
    const [PISensorSerial, setPISensorSerial] = useState(null);
    const [PISensorZeroing, setPISensorZeroing] = useState(null);
    const [Step2, setStep2] = useState(false); //use to show step2 only when step1 is completed
    const [Step3, sestStep3] = useState(false); //use to show step3 only when step2 is completed

    const [CodeBarReadWaiting, setCodeBarReadWaiting] = useState(false); //display a loader while the user scans the QR code
    const [Loadertest, setLoadertest] = useState(false);

    const [InitialFormValues, setInitialFormValues] = React.useState({
        operator_name: "Arnaud Jaspard",
        operator_id: null,
        test_date: format(Date.now(), "dd-MMM-yyyy , HH:mm"),
        protocol_id: null,
        sample_id: null,
        sensor_installation: null,
        devmode: false,

        //DONT FOLD
    });
    const [FormValues, setFormValues] = React.useState({
        operator_name: "Arnaud Jaspard",
        operator_id: null,
        test_date: format(Date.now(), "dd-MMM-yyyy , HH:mm"),
        test_date_Unix: getUnixTime(Date.now()),
        protocol_id: null,
        sample_id: null,
        sensor_installation: null,
        devmode: false,

        //DONT FOLD
    });

    const [FieldOptions, setFieldOptions] = React.useState({
        sensor_installation: [
            {value: "01", label: "Needle adaptor"},
            {value: "02", label: "PortTube adaptor"},
        ],
    }); //FORM OPTIONS FOR SELECT

    const [PristineDetails, setPristineDetails] = useState({});
    const [Pristine, setPristine] = useState(true); //Form has been edited by the user -> Pristie === false

    const [Invalid, setInvalid] = useState(false); //Form has invalid fields

    const [FormErrors, setFormErrors] = useState({}); //HNDLING FORM ERRORS

    const [FormErrorMain, setFormErrorMain] = useState(); //HNDLING FORM ERROR GLOBAL MESSAGE
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const StoreCalibrationData = useCallback(
        (data) => {
            //data = {SerialNumber, TimeMs , AtmPressure (bar), StartDate (Server unixTime), Pressure (mbar), SensorCount (SensorValue)}
            var CurrentEntries = [];

            if (Ref_SensorCalibrationData.current && Array.isArray(Ref_SensorCalibrationData.current)) {
                CurrentEntries = Ref_SensorCalibrationData.current;
            }
            //Storing Raw Data as backup
            let NewEntry = data;
            CurrentEntries.push(NewEntry);
        },
        [Ref_SensorCalibrationData]
    );
    const CalculateCalibrationValue = useCallback(() => {
        //Based on the RawData, we calculate the average, the min, the max, the std and we propose a zero value
        var PressureData = [];
        if (Ref_SensorCalibrationData.current && Array.isArray(Ref_SensorCalibrationData.current)) {
            Ref_SensorCalibrationData.current.forEach((elem) => {
                PressureData.push(elem.Pressure);
            });
        }

        let ZeroingOutput = {
            min: jStat.min(PressureData),
            max: jStat.max(PressureData),
            mean: jStat.mean(PressureData),
            stdv: jStat.stdev(PressureData),
            deltaMaxMin: jStat.max(PressureData) - jStat.min(PressureData),
        };
        let AbsErrorTheoretical = (0.25 * ZeroingOutput.mean) / 100;
        ZeroingOutput.AbsError = AbsErrorTheoretical;

        setPISensorZeroing(ZeroingOutput);
        ShareZeroSensorValue(ZeroingOutput.mean);

        HandleStoreFullData({
            ZeroSensorData: {
                MeanAbsPressure: ZeroingOutput.mean,
                MinAbsPressure: ZeroingOutput.min,
                MaxAbsPressure: ZeroingOutput.max,
                Std: ZeroingOutput.stdv,
                DeltaMinMax: ZeroingOutput.deltaMaxMin,
                ZeroSensorValue: ZeroingOutput.mean,
                ZeroSensorRawData: Ref_SensorCalibrationData.current, //the array used to zero the sensor
                ZeroSensorPressureData: PressureData,
            },
            SensorDeviceSerial: Ref_SensorSerial.current,
        });

        sestStep3(true);
    }, [Ref_SensorCalibrationData]);

    const HandleStoreSerial = useCallback((data) => {
        setPISensorSerial(data.DeviceSerialNumber);
        Ref_SensorSerial.current = data.DeviceSerialNumber;
        HandleStoreFullData({SensorDeviceSerial: data.DeviceSerialNumber});
    }, []);

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    useEffect(() => {
        // //Requesting connection to the QR Socket Server to check the QR codes
        // socketQRServer.emit("RequestConnection", () => {});
        // //Receiving confirmation
        // const HandleQRConnection = () => setQRSocketConnection(true);

        // socketQRServer.on("UserConnected", () => {
        //     HandleQRConnection();
        // });
        // //Requesting to read a QRcode
        // socketQRServer.on("sensors_QRRead", (data) => {});

        // //Listening to the QRSample to receive the info from the server
        // socketQRServer.on("QRBroadcasting", (data) => {
        //     if (data.type === "sample") {
        //         let InitialFormVal = {...Ref_FormValues.current, ...{sample_id: data.sample_id}};
        //         Ref_FormValues.current = InitialFormVal;
        //         setInitialFormValues({...InitialFormVal, ...{sample_id: data.sample_id}});
        //         setFormValues({...InitialFormVal, ...{sample_id: data.sample_id}});
        //         // HandleUpdateFormValues("sample_id", {Value: data.sample_id, FormattedValue: null, Prisine: false});
        //         setCodeBarReadWaiting(false);
        //         HandleShareTestReference({sample_Id: data.sample_id, protocol_Id: Ref_FormValues.current.protocol_id});
        //         HandleStoreFullData({Sample_Id: data.sample_id, Protocol_Id: Ref_FormValues.current.protocol_id});
        //     }
        //     if (data.type === "protocol") {
        //         let InitialFormVal = {...Ref_FormValues.current, ...{protocol_id: data.protocol_id}};
        //         Ref_FormValues.current = InitialFormVal;
        //         setInitialFormValues({...InitialFormVal, ...{protocol_id: data.protocol_id}});
        //         setFormValues({...InitialFormVal, ...{protocol_id: data.protocol_id}});
        //         setCodeBarReadWaiting(false);
        //         HandleShareTestReference({sample_Id: Ref_FormValues.current.sample_id, protocol_Id: data.protocol_id});
        //         HandleStoreFullData({Sample_Id: Ref_FormValues.current.sample_id, Protocol_Id: data.protocol_id});
        //     }
        // });

        //Requesting connection to the PI-Sensors Socket Server
        socketPIsensor.emit("RequestConnection", {Component: "FullBagTestStep1"});
        //Receiving confirmation
        const HandlePISensorConnection = () => setPISensorsSocketConnection(true);

        socketPIsensor.on("UserConnected", (data) => {
            HandleStoreSerial(data);
            HandlePISensorConnection();
        });

        //Receiving calibration data from the sensor
        socketPIsensor.on("SensorCalibration", (data) => {
            StoreCalibrationData(data);
        });

        //Receiving calibration data from the sensor
        socketPIsensor.on("SensorCalibrationDone", () => {
            CalculateCalibrationValue();
            setLoadertest(false);
        });
        return () => {
            // socketQRServer.off("UserConnected", HandleQRConnection);
            socketPIsensor.off("UserConnected", HandlePISensorConnection);
        };
    }, []);

    useEffect(() => {
        if (Ref_FormValues.current.operator_name && Ref_FormValues.current.test_date && Ref_FormValues.current.protocol_id && Ref_FormValues.current.sample_id) {
            setStep2(true);
        }
    }, [Ref_FormValues.current]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    const HandleUpdateFormValues = (field, values) => {
        let value = values.Value;
        let formattedValue = values.FormattedValue;
        let FieldPristine = values.Pristine;

        //Checking Pristine

        // Update the pristine based on the Field
        var FinalPristine = true;
        let PristineKeys = _.keys(PristineDetails);
        let UpdatedPristineDetails = PristineDetails;

        if (PristineKeys && PristineKeys.length > 0) {
            //Going through all the pristine fields to check if one of them is false
            var CurrentFieldHandled = false;
            PristineKeys.forEach((key) => {
                if (key === field) {
                    CurrentFieldHandled = true;
                    //Update the pristine value
                    UpdatedPristineDetails[field] = FieldPristine;
                    if (FieldPristine === false) {
                        FinalPristine = false;
                    }
                } else {
                    //just checking if false;
                    if (UpdatedPristineDetails[key] === false) {
                        FinalPristine = false;
                    }
                }
            });
            if (CurrentFieldHandled === false) {
                //The key wasn't there so we need to add it
                if (FieldPristine === false) {
                    UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                    FinalPristine = false;
                }
            }
        } else {
            //First field to be touched
            if (FieldPristine === false) {
                UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                FinalPristine = false;
            }
        }
        setPristineDetails(UpdatedPristineDetails);
        setFormValues({...FormValues, ...{[field]: value}});
        Ref_FormValues.current = {...FormValues, ...{[field]: value}};
        setPristine(FinalPristine);
        //Manual update the pristine
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/
        // let FormValuesKeys = _.keys(FormValues);
        // var FinalPristine = true;

        // if (FormValuesKeys && FormValuesKeys.length > 0) {
        //     FormValuesKeys.forEach((key) => {
        //         let InitialValue = InitialFormValues[key];

        //         var Value;
        //         if (key !== field) {
        //             Value = FormValues[key];
        //         } else {
        //             //Check that the modified field is not the same as the InitalValue (The value has not changed yet)
        //             Value = value;
        //         }
        //         if (InitialValue === undefined) {
        //             InitialValue = null; //We set it to null to be able to compare to the Input Value that is set to null
        //             //It means that there was no initialValue passed to the form (that's the case when it is new)
        //         }
        //         if (InitialValue !== Value) {
        //             FinalPristine = false;
        //         }
        //     });
        // }

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        //Confirm Errors
        // ValidateForm(field, value);
        // HandleFiedsChange(field, value);
    };
    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/
    const handleSelectChange = (field, SelectedDetails) => {
        //Hanlding the select change
        //Checking Pristine

        let FieldPristine = SelectedDetails.Pristine;
        let SelectedValues = SelectedDetails.Value;

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/
        var FinalPristine = true;

        //Checking Pristine using the FieldResponse
        let PristineKeys = _.keys(PristineDetails);
        let UpdatedPristineDetails = PristineDetails;

        if (PristineKeys && PristineKeys.length > 0) {
            //Going through all the pristine fields to check if one of them is false
            var CurrentFieldHandled = false;
            PristineKeys.forEach((key) => {
                if (key === field) {
                    CurrentFieldHandled = true;
                    //Update the pristine value
                    UpdatedPristineDetails[field] = FieldPristine;
                    if (FieldPristine === false) {
                        FinalPristine = false;
                    }
                } else {
                    //just checking if false;
                    if (UpdatedPristineDetails[key] === false) {
                        FinalPristine = false;
                    }
                }
            });
            if (CurrentFieldHandled === false) {
                //The key wasn't there so we need to add it
                if (FieldPristine === false) {
                    UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                    FinalPristine = false;
                }
            }
        } else {
            //First field to be touched
            if (FieldPristine === false) {
                UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                FinalPristine = false;
            }
        }
        setPristineDetails(UpdatedPristineDetails);
        setPristine(FinalPristine);
        var UpdatedFormValues = {...FormValues, ...{[field]: SelectedValues}};
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        /************* IF NO CONDITIONAL SELECT USE THIS *****************/
        setFormValues(UpdatedFormValues);
        /************* IF NO CONDITIONAL SELECT USE THIS *****************/

        /************* CONDITIONAL SELECT *****************/
        //To prevent having to deal with different server configuration, the logic to implement here must be tailored to your needs

        /*Option 1 :
         *Updating the options of a subfield based on the select value ->
         *If you use this, don't forget to specify that the conditional field is disabled if the parentField is empty or null
         */
    };
    const HandleCheckbox = (field, value) => {
        setFormValues({...FormValues, ...{[field]: value}});
        Ref_FormValues.current = {...FormValues, ...{[field]: value}};
    };
    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/
    const HandleUpdateErrors = (field, error) => {
        // console.log("HandleUpdateErrors", field, error);
        //We need to check if there is an error or not
        const ErrorsKeys = _.keys(FormErrors);
        var FinalErrors = {};
        var InvalidTemp = false;
        if (ErrorsKeys && ErrorsKeys.length > 0) {
            // If there is an error
            ErrorsKeys.forEach((key) => {
                if (key !== field) {
                    if (FormErrors[key]) {
                        //Add the error in the list
                        FinalErrors = {...FinalErrors, ...{[key]: FormErrors[key]}};
                        InvalidTemp = true;
                    }
                }
            });
        }

        if (error) {
            //Add the error in the list
            FinalErrors = {...FinalErrors, ...{[field]: error}};
            InvalidTemp = true;
        }
        // console.log("InfalErrors", FinalErrors);

        setFormErrors(FinalErrors);

        setInvalid(InvalidTemp);
    };
    const HandleScanBarCode = (type) => {
        // socketQRServer.emit("App_pi-sensors_QRRead", (type) => {});
        // setCodeBarReadWaiting(true);
        // setTimeout(() => {
        //     setCodeBarReadWaiting(false);
        // }, 5000);
    };

    const HandleSetupCompleted = () => {
        socketPIsensor.emit("CalibrateSensor", {SampleRate: 100, CalibrationTimeMS: 2000});
        let SensorInstalation = _.find(FieldOptions.sensor_installation, {value: FormValues.sensor_installation});

        HandleStoreFullData({
            OperatorName: FormValues.operator_name,
            TestDateTimeUnix: FormValues.test_date_Unix,
            TestDateIso: formatISO(fromUnixTime(FormValues.test_date_Unix)),
            Protocol_Id: FormValues.protocol_id,
            Sample_Id: FormValues.sample_id,
            TestType: "Full Bag",
            SensorInstallation: SensorInstalation.label,
        });
        setLoadertest(true);
    };
    const HandleStartTest = () => {
        History.push("/testing/manualactivation/fullbag/step2");
    };

    const HandleFieldExit = (Field, status) => {
        if (status === "Left") {
            // //Show the Abort button if not empty
            // if (FormValues.AbortReason) {
            //     setShowAbortButton("flex");
            // } else {
            //     setShowAbortButton("none");
            // }
        }
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    var Step2Component = null;

    if (Step2 === true) {
        Step2Component = (
            <div className={classes.Step}>
                <div className={classes.Step_Title}>
                    <h2>Step 2: Test setup</h2>
                </div>
                <div className={classes.Step_Content}>
                    <div className={classes.Step2_Wrapper}>
                        <div className={classes.Step2_Left}>
                            <div className={classes.Description_Picture_Wrapper}>
                                <img className={classes.Description_Picture} src="/images/PeelSealsPressureTester_V1.svg" alt="TestDescription" />
                            </div>
                        </div>

                        <div className={classes.Step2_Right}>
                            <ul className={classes.Instructions}>
                                <li>Check that the PeelSeals-Pressure device is powered on</li>
                                <li>
                                    <div>Connect the silicon tube between the sensor and the buffer chamber of the bag.</div>
                                    <div style={{fontStyle: "italic"}}>You can connect the sensor using a port-tube adaptor or a needle adaptor</div>
                                </li>
                                <li>Check that there are no leackages in the setup by gently pushing on the Buffer chamber</li>
                            </ul>
                            <h3 style={{margin: "10px 0px 0px 0px"}}>Please Confirm the sensor installation</h3>
                            <div style={{width: "250px"}}>
                                <FormCompontent
                                    Name="sensor_installation"
                                    InitialValue={InitialFormValues.sensor_installation ? InitialFormValues.sensor_installation : null}
                                    FormValue={FormValues.sensor_installation ? FormValues.sensor_installation : null}
                                    OutputSelectedValues={(values) => handleSelectChange("sensor_installation", values)}
                                    SelectOptions={FieldOptions.sensor_installation}
                                    Component="Select"
                                    Variant="OutlinedLabel"
                                    Label="Sensor Installation"
                                    NoMessage={false}
                                    meta={{error: FormErrors.sensor_installation, invalid: FormErrors.normsensor_installational ? true : false}}
                                    OutputError={(error) => HandleUpdateErrors("sensor_installation", error)}
                                    Required={true}
                                    // MultiSelect={true}
                                    // ControlledEditViewMode={true}
                                    // EditModeActive={FormViewMode === "Edit" ? true : false}
                                    // SetFormEditMode={HandleEnableEditMode}
                                    // SortSelectOptions="Dsc"
                                    // SortLastItemName={{Exact: false, Name: "Arnaud"}}
                                    // SortIcons={true}
                                    // Required={true}
                                    // ReadOnly={true}
                                />
                            </div>

                            <Button Width="250px" style={{margin: "20px 0px 0px 0px"}} onClick={HandleSetupCompleted} disabled={!FormValues.sensor_installation ? true : false}>
                                Setup check completed
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    var Step3Component = null;

    if (Step3 === true) {
        Step3Component = (
            <div className={classes.Step}>
                <div className={classes.Step_TitleFinal}>
                    <h2>Step 3: Pressure sensor check status : </h2>
                    <CheckBoxCircularFilled SVGFillColor="green" />
                    <Button style={{margin: "0px 0px 0px 10px"}} onClick={HandleStartTest}>
                        Start the test
                    </Button>
                </div>
                <div className={classes.Step_Content}>
                    <div className={classes.ConnectionCheck}>
                        <p>PeelSeals pressure test device ID : {PISensorSerial}</p>
                        <p style={{margin: "5px 10px 0px 10px"}}>-</p>
                        <p>Connection is : ok</p>
                        <p style={{margin: "5px 10px 0px 10px"}}>-</p>
                        <p>Sensor Zeroing value: {PISensorZeroing.mean.toFixed(3)} mbar</p>
                    </div>
                    <div className={classes.ConnectionCheck}>
                        <p>Mean Absolute Pressure : {PISensorZeroing.mean.toFixed(3)} mbar</p>
                        <p style={{margin: "5px 10px 0px 10px"}}>-</p>
                        <p>Min Absolute Pressure : {PISensorZeroing.min.toFixed(3)} mbar</p>
                        <p style={{margin: "5px 10px 0px 10px"}}>-</p>
                        <p>Max Absolute Pressure : {PISensorZeroing.max.toFixed(3)} mbar</p>
                    </div>
                    <div className={classes.ConnectionCheck}>
                        <p>Standard Deviation : {PISensorZeroing.stdv.toFixed(3)} mbar</p>
                        <p style={{margin: "5px 10px 0px 10px"}}>-</p>
                        <p> DeltaMaxMin : {PISensorZeroing.deltaMaxMin.toFixed(3)} mbar</p>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div className={classes.Main}>
            <LocalCircularLoader Loading={CodeBarReadWaiting} WithModalCard={true} FullSize={true} />
            <LocalCircularLoaderIOS
                Loading={Loadertest}
                ModalCardBackground="rgba(0,0,0,0.8)"
                WithModalCard={true}
                FullSize={true}
                SpinnerDimension="50px"
                Message={<div style={{color: "white"}}>Please wait while we check the sensor</div>}
            />
            <div className={classes.Step}>
                <div className={classes.Step_Title}>
                    <h2>Step 1: Test information</h2>
                </div>

                <div className={classes.Step_Content}>
                    <div className={classes.FormMultilineFlex}>
                        <div className={classes.FormField} style={{flex: "0 1 300px"}}>
                            <FormCompontent
                                Name="operator_name"
                                InitialValue={InitialFormValues.operator_name ? InitialFormValues.operator_name : null}
                                FormValue={FormValues.operator_name ? FormValues.operator_name : null}
                                OutputValue={(values) => HandleUpdateFormValues("operator_name", values)}
                                Component="Input"
                                Variant="OutlinedLabel"
                                Label="Operator"
                                NoMessage={false}
                                Required={true}
                                FieldMaxWidth="200px"
                                InternalToolbarLeftIcon={<AvatarV2 IconSize="25px" SVGFillColor="black" />}
                                AnimateToolBarLeft={true}
                                AnimateToolbarLeftColor={true}
                                ReadOnly={true}
                                // OnFieldExit={(status) => HandleFieldExit("operator_name", status)}
                                // meta={{error: FormErrors.normal, invalid: FormErrors.normal ? true : false}}
                                // OutputError={(error) => HandleUpdateErrors("normal", error)}
                            />
                        </div>
                        <div className={classes.FormField} style={{flex: "0 1 300px"}}>
                            <FormCompontent
                                Name="protocol_id"
                                InitialValue={InitialFormValues.protocol_id ? InitialFormValues.protocol_id : null}
                                FormValue={FormValues.protocol_id ? FormValues.protocol_id : null}
                                OutputValue={(values) => HandleUpdateFormValues("protocol_id", values)}
                                OutputError={(error) => HandleUpdateErrors("protocol_id", error)}
                                meta={{error: FormErrors.protocol_id, invalid: FormErrors.protocol_id ? true : false}}
                                Component="Input"
                                Variant="OutlinedLabel"
                                Label="Protocol Id"
                                NoMessage={false}
                                Required={true}
                                LocalValidation={{When: "Leaving"}}
                                DisplayMessageIn="Popup"
                                // FieldMaxWidth="200px"
                                ExternalToolbarRight={
                                    <div style={{cursor: "pointer"}} data-tip="You can scan the protocol QRCode with your phone" data-for="ScanProtocolQR">
                                        <QRCode onClick={() => HandleScanBarCode("Procol_id")} Frame="rounded" FrameStrokeWidth="8px" IconSize="40px" />
                                        <ReactTooltip disable={false} id="ScanProtocolQR" place="right" type="dark" effect="solid" />
                                    </div>
                                }
                            />
                        </div>
                    </div>
                    <div className={classes.FormMultilineFlex}>
                        <div className={classes.FormField} style={{flex: "0 1 300px"}}>
                            <FormCompontent
                                Name="test_date"
                                InitialValue={InitialFormValues.test_date ? InitialFormValues.test_date : null}
                                FormValue={FormValues.test_date ? FormValues.test_date : null}
                                OutputValue={(values) => HandleUpdateFormValues("test_date", values)}
                                Component="Input"
                                Variant="OutlinedLabel"
                                Label="Date"
                                NoMessage={false}
                                Required={true}
                                FieldMaxWidth="200px"
                                InternalToolbarLeftIcon={<Calendar IconSize="25px" SVGFillColor="black" />}
                                AnimateToolBarLeft={true}
                                AnimateToolbarLeftColor={true}
                                ReadOnly={true}
                                // OnFieldExit={(status) => HandleFieldExit("AbortReason", status)}
                                // meta={{error: FormErrors.normal, invalid: FormErrors.normal ? true : false}}
                                // OutputError={(error) => HandleUpdateErrors("normal", error)}
                            />
                        </div>

                        <div className={classes.FormField} style={{flex: "0 1 300px"}}>
                            <FormCompontent
                                Name="sample_id"
                                InitialValue={InitialFormValues.sample_id ? InitialFormValues.sample_id : null}
                                FormValue={FormValues.sample_id ? FormValues.sample_id : null}
                                OutputValue={(values) => HandleUpdateFormValues("sample_id", values)}
                                OutputError={(error) => HandleUpdateErrors("sample_id", error)}
                                meta={{error: FormErrors.sample_id, invalid: FormErrors.sample_id ? true : false}}
                                Component="Input"
                                Variant="OutlinedLabel"
                                Label="Sample Id"
                                NoMessage={false}
                                Required={true}
                                // FieldMaxWidth="200px"
                                LocalValidation={{When: "Leaving"}}
                                DisplayMessageIn="Popup"
                                ExternalToolbarRight={
                                    <div style={{cursor: "pointer"}} data-tip="You can scan the Sample QRCode with your phone" data-for="ScanSampleQR">
                                        <QRCode onClick={() => HandleScanBarCode("Sample_Id")} Frame="rounded" FrameStrokeWidth="8px" IconSize="40px" />
                                        <ReactTooltip disable={false} id="ScanSampleQR" place="right" type="dark" effect="solid" />
                                    </div>
                                }
                            />
                        </div>
                    </div>
                    <div className={classes.FormMultilineFlex}>
                        <div className={classes.FormFieldCheckBoxAligned} style={{flex: "0 0 200px"}}>
                            <CheckBox
                                Inputname="devmode"
                                LabelPosition="right"
                                label="Development mode"
                                initialvalue={InitialFormValues.devmode === true ? true : false}
                                curvalue={FormValues.devmode}
                                ShareInputValue={(value) => HandleCheckbox("devmode", value)}
                            />
                        </div>
                        <div className={classes.FormField} style={{visibility: FormValues.devmode === true ? "visible" : "hidden", flex: "0 1 300px"}}>
                            <FormCompontent
                                Name="devmode_description"
                                InitialValue={InitialFormValues.devmode_description ? InitialFormValues.devmode_description : null}
                                FormValue={FormValues.devmode_description ? FormValues.devmode_description : null}
                                OutputValue={(values) => HandleUpdateFormValues("devmode_description", values)}
                                Component="Input"
                                Variant="OutlinedLabel"
                                Label="Description"
                                NoMessage={false}
                                Required={true}
                                FieldMaxWidth="200px"
                                // OnFieldExit={(status) => HandleFieldExit("operator_name", status)}
                                meta={{error: FormErrors.devmode_description, invalid: FormErrors.devmode_description ? true : false}}
                                OutputError={(error) => HandleUpdateErrors("devmode_description", error)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {Step2Component}
            {Step3Component}
        </div>
    );

    /***************** RENDER ******************/
});

export default ManualActivationFullBagStep1;
