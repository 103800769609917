/* eslint-disable */
import React, {useState, useEffect, useCallback} from "react";
import PropTypes from "prop-types";
import {createUseStyles, useTheme} from "react-jss";
import useTableContext from "../utils/context/tableContext/useTableContext";
import TableContainer from "../TableContainerNew/TableContainer";
import APICallExternal from "../utils/APICallExternal";
import Button from "../Button/Button";
import _ from "lodash";
//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

//eslint-disable-next-line
const Lockedstyles = {};

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

/**
 * Component Description
 */

const Table = React.memo(function Table(props, ref) {
    /****************************** PROPS AND JSS CLASSES *********************/
    const {
        URLAbsolute,
        URLRelative,
        //eslint-disable-next-line
        LocalData,
        MainDataSource,
        ColomnsInput,
        TableDimensions,
        TableOptions,
        DevMode,
        ShareTableData,
        UpdateDataFromOutisde,
        TotalRow,
    } = props;

    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});
    /****************************** PROPS AND JSS CLASSES *********************/

    /****************************** REFS *********************/
    // const ref = useRef(null)
    /****************************** REFS *********************/

    /****************************** CONSTS *********************/
    const InitialTableOptions = {
        Theme: "Dark",
        DimensionsOptions: {
            FitParentContainer: true,
            FitContent: true,
            ContainerScroll: true,
        },
        Selectable: false,
        Sortable: true,
        Searchable: false,
        SearchOptions: {
            WithFilterMenu: true,
            WithGlobalSearchToolbar: true,
            OnClickSearch_Only: false,
            MinCharSearch: "2",
        },
        PagerOptions: {
            InfiniteScroll: false,
            ShowPager: true,
            DefaultPageSize: 20,
            AllowedPageSizes: [10, 20, 30],
        },
        RowButtons: {
            ColWidth: "100px",
            TotalStandardButtons: 0,
            TotalCustomButtons: 0,
            TotalButtons: 0,
            Buttons: [],
        },
        TopToolbar: {
            Disabled: false,
            AlwaysShow: false,
        },
        HeaderStyle: {},
		
		ServerLimits : {
			MaxRecords : null,
			LimitWithPager : false

		}
    };

    //Initializing the buttons for the rows
    let FinalRowButtons = [];
    let TotalStandardButtons = 0;
    let TotalCustomButtons = 0;

    let RowButtonsInitial = TableOptions.RowButtons;
    if (RowButtonsInitial && Array.isArray(RowButtonsInitial) && RowButtonsInitial.length > 0) {
        RowButtonsInitial.forEach((elem) => {
            let Button = null;
            if (elem.Standard === true) {
                //Standard button
                if (!elem.Name || (elem.Name !== "View" && elem.Name !== "Edit" && elem.Name !== "Delete")) {
                    //We ignore the button
                    console.log("Table - Error - Standard button has no name or the name is invalid");
                } else {
                    if (elem.OnClick && typeof (elem.OnClick === "function")) {
                        Button = {
                            Standard: true,
                            Name: elem.Name,
                            ActiveCondition: null,
                            OnClick: elem.OnClick,
                            IconSize: elem.IconSize ? elem.IconSize : null,
                            ActiveColor: elem.ActiveColor ? elem.ActiveColor : null,
                            HoverColor: elem.HoverColor ? elem.HoverColor : null,
                            InactiveColor: elem.InactiveColor ? elem.InactiveColor : null,
                        };
                        if (elem.ActiveCondition) {
                            if (typeof (elem.ActiveCondition === "function")) {
                                Button.ActiveCondition = elem.ActiveCondition;
                            } else {
                                console.log("Table - Error - Standard button ActiveCondition is not a function");
                            }
                        }
                    } else {
                        if (elem.Name == "Delete") {
                            Button = {
                                Standard: true,
                                Name: elem.Name,
                                ActiveCondition: null,
                                OnClick: elem.OnClick,
                                IconSize: elem.IconSize ? elem.IconSize : null,
                                ActiveColor: elem.ActiveColor ? elem.ActiveColor : null,
                                HoverColor: elem.HoverColor ? elem.HoverColor : null,
                                InactiveColor: elem.InactiveColor ? elem.InactiveColor : null,
                            };
                            if (elem.ActiveCondition) {
                                if (typeof (elem.ActiveCondition === "function")) {
                                    Button.ActiveCondition = elem.ActiveCondition;
                                } else {
                                    console.log("Table - Error - Standard button ActiveCondition is not a function");
                                }
                            }
                        } else {
                            console.log("Table - Error - Standard button has no OnClick function");
                        }
                    }
                }
                if (Button) {
                    TotalStandardButtons = TotalStandardButtons + 1;
                    FinalRowButtons.push(Button);
                }
            } else {
                //Custom button
                if (elem.OnClick && typeof (elem.OnClick === "function")) {
                    Button = {
                        Standard: false,
                        ActiveCondition: null,
                        OnClick: elem.OnClick,
                        IconActive: elem.IconActive ? elem.IconActive : null,
                        IconInactive: elem.IconInactive ? elem.IconInactive : null,
                    };
                    if (elem.ActiveCondition) {
                        if (typeof (elem.ActiveCondition === "function")) {
                            Button.ActiveCondition = elem.ActiveCondition;
                        } else {
                            console.log("Table - Error - Custom button ActiveCondition is not a function");
                        }
                    }
                } else {
                    console.log("Table - Error - Custom has no OnClick function");
                }

                if (Button) {
                    TotalCustomButtons = TotalCustomButtons + 1;
                    FinalRowButtons.push(Button);
                }
            }
        });
    }
    let TotalButtons = TotalStandardButtons + TotalCustomButtons;
    InitialTableOptions.RowButtons.TotalStandardButtons = TotalStandardButtons;
    InitialTableOptions.RowButtons.TotalCustomButtons = TotalCustomButtons;
    InitialTableOptions.RowButtons.TotalButtons = TotalButtons;
    InitialTableOptions.RowButtons.Buttons = FinalRowButtons;

    //We remove the RowButtons key from the tableOptions
    let FinalInitalOptions = _.omit(TableOptions, ["RowButtons"]);
    //Initializing the buttons for the rows

    var FinalTableOptions;
    if (TableOptions) {
        FinalTableOptions = _.merge(InitialTableOptions, FinalInitalOptions);
    } else {
        FinalTableOptions = InitialTableOptions;
    }

    const InitialTotalRow = {
        Show: false,
    };
    var FinalTotalRow;
    if (TotalRow) {
        if (TotalRow.Render && typeof (TotalRow.Render === "function")) {
            FinalTotalRow = _.merge(InitialTotalRow, TotalRow);
        } else {
            FinalTotalRow = InitialTotalRow;
            console.log("Table Component - Total Row Render is not a function");
        }
    } else {
        FinalTotalRow = InitialTotalRow;
    }

    const NodeDevMode = process.env.NODE_ENV === "development" ? true : false;
    let FinalDevMode = false;
    if (NodeDevMode === true && DevMode === true) {
        FinalDevMode = true;
    }

    /****************************** CONSTS *********************/

    /****************************** STATE *********************/
    const {StoreOriginalData, InitializeRowsAndCols, TableAction, ResetTheContext} = useTableContext();

    const [ApiRes, setApiRes] = useState(null); //Loader and ensuring async is done for API response
    const [Loading, setLoading] = useState(false); //Loader and ensuring async is done for API response
    const [CallType, setCallType] = useState(null);
    const [Errors, setErrors] = useState(null);

    const [OriginalTableData, setOriginalTableData] = useState(null);
    const [IsInitialized, setIsInitialized] = useState(false);
    const [CrudAction, setCrudAction] = useState(null);
    const [ReloadTable, setReloadTable] = useState(false);

    /****************************** STATE *********************/

    /****************************** CALLBACK FUNCTIONS *********************/
    const EndofAPI = React.useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
			
            if (ApiRes.error) {
                console.log("Table Data Store error : ", ApiRes);
                //Something went wrong
                // toast.error(`Error : ${ApiRes.errorMessage.message}`, {autoClose: true});
            } else {
                if (CallType === "DeleteOne" && ReloadTable === false) {
                    console.log("EndofApi deleteone");
                    //We need to reset the table and rebuild it again
                    //Need to call again the API to get the data again
                    // DataOutput("Deleted");
                    setCallType(null);
                    setCrudAction(null);
                    ResetTheContext();
                    setReloadTable(true);
                    setTimeout(() => {
                        setReloadTable(false);
                    }, 100);
                } else {
                    setOriginalTableData(ApiRes.data.data);
                }
            }
        }
    }, [ApiRes, Loading, CallType, ReloadTable]);
    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** EFFECT *********************/
    useEffect(() => {
		
        //This portion is used to load the original table data
        if (ReloadTable === false) {
            var FinalURL;
            if (!LocalData && !URLAbsolute && !URLRelative) {
                setErrors("Table Component error, You didn't pass LocaleData or URLAbsolute or URLRelative");
            } else if (LocalData && !URLAbsolute && !URLRelative) {
                setOriginalTableData(LocalData);
            } else if (URLAbsolute && URLRelative) {
                setErrors("Table Component error, Make up your mind URLAbsolute or URLRelative ?");
            } else {
				//Normal server request
	
				let QueryLimit = null;
				if (FinalTableOptions.ServerLimits.MaxRecords){
					QueryLimit = parseInt(FinalTableOptions.ServerLimits.MaxRecords);
				}
                if (URLAbsolute) {
					let Main = URLAbsolute.MAIN
					if(QueryLimit){
						Main = Main +`?limit=${QueryLimit}`
					}
                    FinalURL = {
                        MAIN: Main,
                        DELETE: URLAbsolute.DELETE,
                        PUT: URLAbsolute.PUT,
                        POST: URLAbsolute.POST,
                    };
                } else {
					let Main = URLRelative.MAIN
					if(QueryLimit){
						Main = Main +`?limit=${QueryLimit}`
					}
                    FinalURL = {
                        MAIN: Main,
                        DELETE: URLRelative.MAIN + "/" + URLRelative.DELETE + "/",
                        PUT: URLRelative.PUT,
                        POST: URLRelative.POST,
                    };
                }
                let FinalAPICall;

                if (CrudAction) {
                    if (CrudAction.action === "DeleteOne") {
                        FinalAPICall = {url: FinalURL.DELETE + CrudAction.Id, type: "delete"};
                        setCallType("DeleteOne");
                    }
                } else {
                    FinalAPICall = {url: FinalURL.MAIN, type: "get"};
                }

                setLoading(true);
				
                async function APIInternal() {
                    const res = await APICallExternal(FinalAPICall);

                    setApiRes(res);
                    setLoading(false);
                }

                APIInternal();
            }
        }
    }, [LocalData, URLAbsolute, URLRelative, MainDataSource, CrudAction, ReloadTable]);

    useEffect(() => {
        //GENERATING THE COLS INPUTS FOR THE HEADERS AND THE REST OF THE INFORMATION
        if (OriginalTableData) {
            var Error = null;
            var ColInputs = [],
                RowInputs = [];

            if (ColomnsInput && Array.isArray(ColomnsInput) && ColomnsInput.length > 0) {
                for (let i = 0; i < ColomnsInput.length; i++) {
                    let elem = ColomnsInput[i];
                    let FinalLookup;
                    if (elem.lookup) {
                        if (elem.lookup.data && elem.lookup.searchField && elem.lookup.returnField) {
                            FinalLookup = {data: elem.lookup.data, searchField: elem.lookup.searchField, returnField: elem.lookup.returnField};
                        }
                    }
                    let DefaultTableCol = {
                        colId: i,
                        datafield: elem.datafield,
                        caption: elem.caption,
                        width: elem.width,
                        minWidth: elem.minWidth,
                        isVisible: true,
                        isFixed: elem.fixedCol === true ? true : false,
                        isAction: false,
                        isDraggable: false,
                        isDragged: false,
                        isSortable: elem.sortable === true ? true : false,
                        sortIndex: elem.initialSort ? (elem.initialSort.order ? elem.initialSort.order : null) : null,
                        sortDirection: elem.initialSort ? (elem.initialSort.sortDirection ? elem.initialSort.sortDirection : null) : null,
                        isSorted: elem.initialSort ? (elem.initialSort.order ? true : false) : false,
                        isSearchable: elem.searchable === true ? true : false,
                        isSearched: false,
                        searchValue: null,
                        searchFilterType: null,
                        dataType: elem.datatype ? elem.datatype : "text",
                        customFormatFunction: elem.cellFormatFunction ? (typeof elem.cellFormatFunction === "function" ? elem.cellFormatFunction : null) : null,
                        customRenderFunction: elem.cellCustomRender ? (typeof elem.cellCustomRender === "function" ? elem.cellCustomRender : null) : null,
                        isLookup: FinalLookup ? true : false,
                        lookup: FinalLookup ? FinalLookup : null,
                        wrapHeader: elem.wrapColHeader === true ? true : false,
                        wrapCell: elem.wrapCell === true ? true : false,
                    };
                    ColInputs.push(DefaultTableCol);
                }
            } else {
                Error = "Table Component error, ColomnsInput is not a valid array";
            }

            //GENERATING THE ROWS INPUTS INFORMATION FOR THE TABLE BODY
            let TableRowsInputs;
            if (MainDataSource) {
                TableRowsInputs = OriginalTableData[MainDataSource];
                StoreOriginalData(OriginalTableData, TableRowsInputs);
            } else {
                TableRowsInputs = OriginalTableData;
                StoreOriginalData(OriginalTableData, OriginalTableData);
            }

            if (TableRowsInputs && Array.isArray(TableRowsInputs) && TableRowsInputs.length > 0) {
                for (let i = 0; i < TableRowsInputs.length; i++) {
                    //We look at each data rows
                    let RowElem = TableRowsInputs[i];

                    let DefaultTableRow = {
                        rowId: i,
                        rowOrder: i,
                        isSelectable: false,
                        isSelected: false,
                        isVisible: true,
                        sortedVisible: true,
                        isGroup: false,
                        isDeletable: false,
                        isEditable: false,
                        isEditing: false,
                        rowData: RowElem,
                        isDragable: false,
                        isDraged: false,
                    };
                    RowInputs.push({...DefaultTableRow, ...RowElem});
                }
            } else {
                Error = "Table Component error, No Data";
            }

            if (Error) {
                console.log(Error);
                setIsInitialized(true);
                setErrors(Error);
            } else {
                InitializeRowsAndCols(RowInputs, ColInputs);
                setIsInitialized(true);
            }
        }
    }, [OriginalTableData, ColomnsInput, TableOptions]);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);

    useEffect(() => {
        if (TableAction && ReloadTable === false) {
            setCrudAction(TableAction);
        }
    }, [TableAction]);

    /****************************** EFFECT *********************/

    /****************************** FUNCTIONS *********************/

    /****************************** FUNCTIONS *********************/

    /****************************** RENDER *********************/
    if (IsInitialized === true && ReloadTable === false) {
        if (Errors) {
            return <div>{Errors}</div>;
        } else {
            return <TableContainer TableOptions={FinalTableOptions} TotalRow={FinalTotalRow} DevMode={FinalDevMode} />;
        }
    } else {
        return <div>Loading</div>;
    }

    /****************************** RENDER *********************/
});

Table.defaultProps = {
    TableData: null,
};

Table.propTypes = {
    /**
     * TableData :
     */
    TableData: PropTypes.any,
};

export default Table;
