/* eslint-disable */
import React, {useEffect, useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import Page from "@artibulles-cis/react/Page";
import FormCompontent from "@artibulles-cis/react/FormComponent";
import ReactTooltip from "react-tooltip";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import Button from "@artibulles-cis/react/Button/Button";
import {AvatarV2, Calendar, QRCode} from "@artibulles-cis/react-icons";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import APICallExternal from "@artibulles-cis/react/utils/APICallExternal";

import _ from "lodash";
const {format, getUnixTime, formatISO} = require("date-fns");
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    Main: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "0px 10px",
        // height : "100%"
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "4px 0px 4px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
    Chamber: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "row",
        boxSizing: "border-box",
        padding: "10px",
        border: "1px solid grey",
        borderRadius: "5px",
        margin: "15px 0px",
    },
    InstructionTitle: {
        flex: "0 0 250px",
        boxSizing: "border-box",
        padding: "0px 30px 0px 0px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // justifyContent : "center"
    },
    InstructionPictureWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    InstructionPicture: {
        width: "200px",
        height: " auto",
    },
}));

const BagFilling = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const Ref_FormValues = useRef({
        Operator_name: "Arnaud Jaspard",
        operator_id: null,
        TestDate_Iso: formatISO(new Date(Date.now())),
        Sample_Id: null,
        Buffer_chamber_mass_g: null,
        Buffer_chamber_air_volume: null,
        Buffer_chamber_filling_issue: false,
        Buffer_chamber_issue_description: null,
        Buffer_chamber_filling_StartDateUnix: null,
        Buffer_chamber_filling_EndDateUnix: null,
        Glucose_chamber_mass_g: null,
        Glucose_chamber_air_volume: null,
        Glucose_chamber_filling_issue: false,
        Glucose_chamber_issue_description: null,
        Glucose_chamber_filling_StartDateUnix: null,
        Glucose_chamber_filling_EndDateUnix: null,
        Sample_discard: false,
        Sample_discard_reason: null,
    });
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [InitialFormValues, setInitialFormValues] = React.useState({
        Operator_name: "Arnaud Jaspard",
        operator_id: null,
        TestDate_Iso: formatISO(new Date(Date.now())),
        Sample_Id: null,
        Buffer_chamber_mass_g: null,
        Buffer_chamber_air_volume: null,
        Buffer_chamber_filling_issue: false,
        Buffer_chamber_issue_description: null,
        Buffer_chamber_filling_StartDateUnix: null,
        Buffer_chamber_filling_EndDateUnix: null,
        Glucose_chamber_mass_g: null,
        Glucose_chamber_air_volume: null,
        Glucose_chamber_filling_issue: false,
        Glucose_chamber_issue_description: null,
        Glucose_chamber_filling_StartDateUnix: null,
        Glucose_chamber_filling_EndDateUnix: null,
        Sample_discard: false,
        Sample_discard_reason: null,
    });
    const [FormValues, setFormValues] = React.useState({
        Operator_name: "Arnaud Jaspard",
        operator_id: null,
        TestDate_Iso: formatISO(new Date(Date.now())),
        Sample_Id: null,
        Buffer_chamber_mass_g: null,
        Buffer_chamber_air_volume: null,
        Buffer_chamber_filling_issue: false,
        Buffer_chamber_issue_description: null,
        Buffer_chamber_filling_StartDateUnix: null,
        Buffer_chamber_filling_EndDateUnix: null,
        Glucose_chamber_mass_g: null,
        Glucose_chamber_air_volume: null,
        Glucose_chamber_filling_issue: false,
        Glucose_chamber_issue_description: null,
        Glucose_chamber_filling_StartDateUnix: null,
        Glucose_chamber_filling_EndDateUnix: null,
        Sample_discard: false,
        Sample_discard_reason: null,
    });
    const [FieldOptions, setFieldOptions] = React.useState({
        Buffer_chamber_air_volume: [
            {value: "01", label: "Small"},
            {value: "02", label: "Medium"},
            {value: "03", label: "Large"},
        ],
        Glucose_chamber_air_volume: [
            {value: "01", label: "Small"},
            {value: "02", label: "Medium"},
            {value: "03", label: "Large"},
        ],
    }); //FORM OPTIONS FOR SELECT

    const [PristineDetails, setPristineDetails] = useState({});
    const [Pristine, setPristine] = useState(true); //Form has been edited by the user -> Pristie === false

    const [Invalid, setInvalid] = useState(false); //Form has invalid fields

    const [FormErrors, setFormErrors] = useState({}); //HNDLING FORM ERRORS

    const [FormErrorMain, setFormErrorMain] = useState(); //HNDLING FORM ERROR GLOBAL MESSAGE
    const [ApiRes, setApiRes] = useState(null);
    const [Loading, setLoading] = useState(false);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = React.useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                console.log("error");
            } else {
                console.log("Api Response", ApiRes);
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    const HandleUpdateFormValues = (field, values) => {
        let value = values.Value;
        let formattedValue = values.FormattedValue;
        let FieldPristine = values.Pristine;

        //Checking Pristine

        // Update the pristine based on the Field
        var FinalPristine = true;
        let PristineKeys = _.keys(PristineDetails);
        let UpdatedPristineDetails = PristineDetails;

        if (PristineKeys && PristineKeys.length > 0) {
            //Going through all the pristine fields to check if one of them is false
            var CurrentFieldHandled = false;
            PristineKeys.forEach((key) => {
                if (key === field) {
                    CurrentFieldHandled = true;
                    //Update the pristine value
                    UpdatedPristineDetails[field] = FieldPristine;
                    if (FieldPristine === false) {
                        FinalPristine = false;
                    }
                } else {
                    //just checking if false;
                    if (UpdatedPristineDetails[key] === false) {
                        FinalPristine = false;
                    }
                }
            });
            if (CurrentFieldHandled === false) {
                //The key wasn't there so we need to add it
                if (FieldPristine === false) {
                    UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                    FinalPristine = false;
                }
            }
        } else {
            //First field to be touched
            if (FieldPristine === false) {
                UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                FinalPristine = false;
            }
        }
        setPristineDetails(UpdatedPristineDetails);
        setFormValues({...FormValues, ...{[field]: value}});
        Ref_FormValues.current = {...FormValues, ...{[field]: value}};
        setPristine(FinalPristine);
        //Manual update the pristine
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/
        // let FormValuesKeys = _.keys(FormValues);
        // var FinalPristine = true;

        // if (FormValuesKeys && FormValuesKeys.length > 0) {
        //     FormValuesKeys.forEach((key) => {
        //         let InitialValue = InitialFormValues[key];

        //         var Value;
        //         if (key !== field) {
        //             Value = FormValues[key];
        //         } else {
        //             //Check that the modified field is not the same as the InitalValue (The value has not changed yet)
        //             Value = value;
        //         }
        //         if (InitialValue === undefined) {
        //             InitialValue = null; //We set it to null to be able to compare to the Input Value that is set to null
        //             //It means that there was no initialValue passed to the form (that's the case when it is new)
        //         }
        //         if (InitialValue !== Value) {
        //             FinalPristine = false;
        //         }
        //     });
        // }

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        //Confirm Errors
        // ValidateForm(field, value);
        // HandleFiedsChange(field, value);
    };
    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/
    const handleSelectChange = (field, SelectedDetails) => {
        //Hanlding the select change
        //Checking Pristine

        let FieldPristine = SelectedDetails.Pristine;
        let SelectedValues = SelectedDetails.Value;

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/
        var FinalPristine = true;

        //Checking Pristine using the FieldResponse
        let PristineKeys = _.keys(PristineDetails);
        let UpdatedPristineDetails = PristineDetails;

        if (PristineKeys && PristineKeys.length > 0) {
            //Going through all the pristine fields to check if one of them is false
            var CurrentFieldHandled = false;
            PristineKeys.forEach((key) => {
                if (key === field) {
                    CurrentFieldHandled = true;
                    //Update the pristine value
                    UpdatedPristineDetails[field] = FieldPristine;
                    if (FieldPristine === false) {
                        FinalPristine = false;
                    }
                } else {
                    //just checking if false;
                    if (UpdatedPristineDetails[key] === false) {
                        FinalPristine = false;
                    }
                }
            });
            if (CurrentFieldHandled === false) {
                //The key wasn't there so we need to add it
                if (FieldPristine === false) {
                    UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                    FinalPristine = false;
                }
            }
        } else {
            //First field to be touched
            if (FieldPristine === false) {
                UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                FinalPristine = false;
            }
        }
        setPristineDetails(UpdatedPristineDetails);
        setPristine(FinalPristine);
        var UpdatedFormValues = {...FormValues, ...{[field]: SelectedValues}};
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        /************* IF NO CONDITIONAL SELECT USE THIS *****************/
        setFormValues(UpdatedFormValues);
        /************* IF NO CONDITIONAL SELECT USE THIS *****************/

        /************* CONDITIONAL SELECT *****************/
        //To prevent having to deal with different server configuration, the logic to implement here must be tailored to your needs

        /*Option 1 :
         *Updating the options of a subfield based on the select value ->
         *If you use this, don't forget to specify that the conditional field is disabled if the parentField is empty or null
         */
    };
    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/
    const HandleUpdateErrors = (field, error) => {
        console.log("HandleUpdateErrors", field, error);
        //We need to check if there is an error or not
        const ErrorsKeys = _.keys(FormErrors);
        var FinalErrors = {};
        var InvalidTemp = false;
        if (ErrorsKeys && ErrorsKeys.length > 0) {
            // If there is an error
            ErrorsKeys.forEach((key) => {
                if (key !== field) {
                    if (FormErrors[key]) {
                        //Add the error in the list
                        FinalErrors = {...FinalErrors, ...{[key]: FormErrors[key]}};
                        InvalidTemp = true;
                    }
                }
            });
        }

        if (error) {
            //Add the error in the list
            FinalErrors = {...FinalErrors, ...{[field]: error}};
            InvalidTemp = true;
        }
        // console.log("InfalErrors", FinalErrors);

        setFormErrors(FinalErrors);

        setInvalid(InvalidTemp);
    };
    const HandleScanBarCode = (type) => {
        // socketQRServer.emit("App_pi-sensors_QRRead", (type) => {});
        // setCodeBarReadWaiting(true);
        // setTimeout(() => {
        //     setCodeBarReadWaiting(false);
        // }, 5000);
    };
    const HandleGenerateBarCode = () => {
        window.open("https://artibulles-es-qrserver.artibulles.com/api", "_blank");
    };
    const HandleSubmit = () => {
        let BufferAirVolume = _.find(FieldOptions.Buffer_chamber_air_volume, {value: FormValues.Buffer_chamber_air_volume});
        FormValues.Buffer_chamber_air_volume = BufferAirVolume.label;
        let GlucoseAirVolume = _.find(FieldOptions.Glucose_chamber_air_volume, {value: FormValues.Glucose_chamber_air_volume});
        FormValues.Glucose_chamber_air_volume = GlucoseAirVolume.label;
        console.log("FormValues", FormValues);

        // const ApiCall = {url: `https://artibulles-es-dataserver.artibulles.com/api/pdprotectesting/bagfilling/addresult`, type: "post"};
        // // const ApiCall = {url: `http://192.168.1.110:3661/api/pdprotectesting/manualactivation`, type: "post"};
        // setLoading(true);

        // async function APIInternal() {
        //     const res = await APICallExternal(ApiCall.url, ApiCall.type, FormValues);
        //     setApiRes(res);
        //     setLoading(false);
        // }
        // APIInternal();
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    // var Phase2Component = null;
    // if (PhaseStep === "Filling LargeChamber"){
    // 	Phase2Component
    // }
    return (
        <Page WithPerfectScrollBar={true} PagePadding="10px">
            <LocalCircularLoader Loading={Loading} WithModalCard={true} FullSize={true} />
            <div className={classes.Main}>
                <h1 style={{margin: "0px"}}>Bag Filling</h1>
            </div>
            <div className={classes.Form}>
                <div className={classes.FormMultilineFlex}>
                    <div className={classes.FormField} style={{flex: "0 1 300px"}}>
                        <FormCompontent
                            Name="Operator_name"
                            InitialValue={InitialFormValues.Operator_name ? InitialFormValues.Operator_name : null}
                            FormValue={FormValues.Operator_name ? FormValues.Operator_name : null}
                            OutputValue={(values) => HandleUpdateFormValues("Operator_name", values)}
                            Component="Input"
                            Variant="OutlinedLabel"
                            Label="Operator"
                            NoMessage={false}
                            Required={true}
                            FieldMaxWidth="200px"
                            InternalToolbarLeftIcon={<AvatarV2 IconSize="25px" SVGFillColor="black" />}
                            AnimateToolBarLeft={true}
                            AnimateToolbarLeftColor={true}
                            ReadOnly={true}
                            // OnFieldExit={(status) => HandleFieldExit("Operator_name", status)}
                            // meta={{error: FormErrors.normal, invalid: FormErrors.normal ? true : false}}
                            // OutputError={(error) => HandleUpdateErrors("normal", error)}
                        />
                    </div>
                    <div className={classes.FormField} style={{flex: "0 1 300px"}}>
                        <FormCompontent
                            Name="TestDate_Iso"
                            InitialValue={InitialFormValues.TestDate_Iso ? InitialFormValues.TestDate_Iso : null}
                            FormValue={FormValues.TestDate_Iso ? FormValues.TestDate_Iso : null}
                            OutputValue={(values) => HandleUpdateFormValues("TestDate_Iso", values)}
                            Component="Input"
                            Variant="OutlinedLabel"
                            Label="Date"
                            NoMessage={false}
                            Required={true}
                            FieldMaxWidth="200px"
                            InternalToolbarLeftIcon={<Calendar IconSize="25px" SVGFillColor="black" />}
                            AnimateToolBarLeft={true}
                            AnimateToolbarLeftColor={true}
                            ReadOnly={true}
                            Imask={true}
                            Mask={{
                                Type: "DateTime",
                                // Options: {
                                //     Format: "yyyy/MM/dd - HH:mm", //default

                                // },
                            }}
                            // OnFieldExit={(status) => HandleFieldExit("AbortReason", status)}
                            meta={{error: FormErrors.normal, invalid: FormErrors.normal ? true : false}}
                            OutputError={(error) => HandleUpdateErrors("normal", error)}
                        />
                    </div>
                </div>

                <div className={classes.FormMultilineFlex}>
                    <div className={classes.FormField} style={{flex: "0 0 200px"}}>
                        <FormCompontent
                            Name="Sample_Id"
                            InitialValue={InitialFormValues.Sample_Id ? InitialFormValues.Sample_Id : null}
                            FormValue={FormValues.Sample_Id ? FormValues.Sample_Id : null}
                            OutputValue={(values) => HandleUpdateFormValues("Sample_Id", values)}
                            OutputError={(error) => HandleUpdateErrors("Sample_Id", error)}
                            meta={{error: FormErrors.Sample_Id, invalid: FormErrors.Sample_Id ? true : false}}
                            Component="Input"
                            Variant="OutlinedLabel"
                            Label="Sample Id"
                            NoMessage={false}
                            Required={true}
                            LocalValidation={{When: "Leaving"}}
                            DisplayMessageIn="Popup"
                            // FieldMaxWidth="200px"
                            ExternalToolbarRight={
                                <div style={{cursor: "pointer"}} data-tip="You can scan the protocol QRCode with your phone" data-for="ScanProtocolQR">
                                    <QRCode onClick={() => HandleScanBarCode("Procol_id")} Frame="rounded" FrameStrokeWidth="8px" IconSize="40px" />
                                    <ReactTooltip disable={false} id="ScanProtocolQR" place="right" type="dark" effect="solid" />
                                </div>
                            }
                        />
                    </div>
                    <div data-tip="Redirect to Sample management" data-for="GenerateBarCode" style={{display: "flex", alignItems: "center", margin: "10px 0px 0px 0px"}}>
                        <Button Width="120px" style={{margin: "0px", flex: "0 1 130px"}} onClick={HandleGenerateBarCode}>
                            Generate Barcode
                        </Button>
                        <ReactTooltip disable={false} id="GenerateBarCode" place="right" type="dark" effect="solid" />
                    </div>
                </div>

                <div className={classes.Chamber}>
                    <div className={classes.InstructionTitle}>
                        Buffer chamber
                        <div className={classes.InstructionPictureWrapper}>
                            <img className={classes.InstructionPicture} src="/images/buffer_chamber_filling.png" alt="BufferChamber" />
                        </div>
                    </div>
                    <div className={classes.FormMultilineFlex} style={{flex: "1 1 auto"}}>
                        <div className={classes.FormField} style={{flex: "0 0 200px"}}>
                            <FormCompontent
                                Name="Buffer_chamber_mass_g"
                                InitialValue={InitialFormValues.Buffer_chamber_mass_g ? InitialFormValues.Buffer_chamber_mass_g : null}
                                FormValue={FormValues.Buffer_chamber_mass_g ? FormValues.Buffer_chamber_mass_g : null}
                                OutputValue={(values) => HandleUpdateFormValues("Buffer_chamber_mass_g", values)}
                                OutputError={(error) => HandleUpdateErrors("Buffer_chamber_mass_g", error)}
                                meta={{error: FormErrors.Buffer_chamber_mass_g, invalid: FormErrors.Buffer_chamber_mass_g ? true : false}}
                                Component="Input"
                                Variant="OutlinedLabel"
                                Label="Buffer Chamber Mass (g)"
                                // NoMessage={false}
                                Required={true}
                                LocalValidation={{When: "Leaving"}}
                                DisplayMessageIn="Popup"
                                Imask={true}
                                Mask={{
                                    Type: "Number",
                                    Options: {
                                        AllowNegative: false,
                                        // Min: 2400,
                                        // Max: 2600,
                                        Scale: 1,
                                    },
                                }}
                                // FieldMaxWidth="200px"
                            />
                        </div>

                        <div className={classes.FormField} style={{flex: "0 0 300px"}}>
                            <FormCompontent
                                Name="Buffer_chamber_air_volume"
                                // InitialValue={InitialFormValues.Buffer_chamber_air_volume ? InitialFormValues.Buffer_chamber_air_volume : null}
                                FormValue={FormValues.Buffer_chamber_air_volume ? FormValues.Buffer_chamber_air_volume : null}
                                OutputSelectedValues={(values) => handleSelectChange("Buffer_chamber_air_volume", values)}
                                SelectOptions={FieldOptions.Buffer_chamber_air_volume}
                                Component="Select"
                                Variant="OutlinedLabel"
                                Label="Buffer Chamber Air Volume"
                                // NoMessage={false}
                                // meta={{error: FormErrors.Buffer_chamber_air_volume, invalid: FormErrors.Buffer_chamber_air_volume ? true : false}}
                                // OutputError={(error) => HandleUpdateErrors("Buffer_chamber_air_volume", error)}
                                Required={true}
                            />
                        </div>
                        <div data-tip="Redirect to Sample management" data-for="GenerateBarCode" style={{display: "flex", alignItems: "center", margin: "10px 0px 0px 0px"}}>
                            <Button Width="120px" style={{margin: "0px", flex: "0 1 130px"}} onClick={HandleGenerateBarCode}>
                                Start Filling
                            </Button>
                            <ReactTooltip disable={false} id="GenerateBarCode" place="right" type="dark" effect="solid" />
                        </div>
                    </div>
                </div>
                <div className={classes.Chamber}>
                    <div className={classes.InstructionTitle}>
                        Glucose chamber
                        <div className={classes.InstructionPictureWrapper}>
                            <img className={classes.InstructionPicture} src="/images/glucose_chamber_filling.png" alt="GlucoseChamber" />
                        </div>
                    </div>
                    <div className={classes.FormMultilineFlex} style={{flex: "1 1 auto"}}>
                        <div className={classes.FormField} style={{flex: "0 0 200px"}}>
                            <FormCompontent
                                Name="Glucose_chamber_mass_g"
                                InitialValue={InitialFormValues.Glucose_chamber_mass_g ? InitialFormValues.Glucose_chamber_mass_g : null}
                                FormValue={FormValues.Glucose_chamber_mass_g ? FormValues.Glucose_chamber_mass_g : null}
                                OutputValue={(values) => HandleUpdateFormValues("Glucose_chamber_mass_g", values)}
                                OutputError={(error) => HandleUpdateErrors("Glucose_chamber_mass_g", error)}
                                meta={{error: FormErrors.Glucose_chamber_mass_g, invalid: FormErrors.Glucose_chamber_mass_g ? true : false}}
                                Component="Input"
                                Variant="OutlinedLabel"
                                Label="Glucose Chamber Mass (g)"
                                NoMessage={false}
                                Required={true}
                                LocalValidation={{When: "Typing"}}
                                DisplayMessageIn="Popup"
                                Imask={true}
                                Mask={{
                                    Type: "Number",
                                    Options: {
                                        AllowNegative: false,
                                        Min: 2400,
                                        Max: 2600,
                                        Scale: 1,
                                    },
                                }}
                                // FieldMaxWidth="200px"
                            />
                        </div>

                        <div className={classes.FormField} style={{flex: "0 0 300px"}}>
                            <FormCompontent
                                Name="Glucose_chamber_air_volume"
                                InitialValue={InitialFormValues.glucose_chamber_airvolume ? InitialFormValues.Glucose_chamber_air_volume : null}
                                FormValue={FormValues.Glucose_chamber_air_volume ? FormValues.Glucose_chamber_air_volume : null}
                                OutputSelectedValues={(values) => handleSelectChange("Glucose_chamber_air_volume", values)}
                                SelectOptions={FieldOptions.Glucose_chamber_air_volume}
                                Component="Select"
                                Variant="OutlinedLabel"
                                Label="Glucose Chamber Air Volume"
                                // NoMessage={false}
                                // meta={{error: FormErrors.Glucose_chamber_air_volume, invalid: FormErrors.Glucose_chamber_air_volume ? true : false}}
                                // OutputError={(error) => HandleUpdateErrors("Glucose_chamber_air_volume", error)}
                                Required={true}
                            />
                        </div>
                        <div data-tip="Redirect to Sample management" data-for="GenerateBarCode" style={{display: "flex", alignItems: "center", margin: "10px 0px 0px 0px"}}>
                            <Button Width="120px" style={{margin: "0px", flex: "0 1 130px"}} onClick={HandleGenerateBarCode}>
                                Start Filling
                            </Button>
                            <ReactTooltip disable={false} id="GenerateBarCode" place="right" type="dark" effect="solid" />
                        </div>
                    </div>
                </div>
                <Button onClick={HandleSubmit} disabled={Invalid || Pristine}>
                    Submit data{" "}
                </Button>
            </div>
        </Page>
    );

    /***************** RENDER ******************/
};

export default BagFilling;
