/* eslint-disable */
import React, {useEffect, useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
// import TabNavContent from "@artibulles-cis/react/TabNavContent";
import {useHistory} from "react-router-dom";
import Button from "@artibulles-cis/react/Button";
import CardModal from "@artibulles-cis/react/CardModal";
import Table from "../../../../artibulles-cis/TableNew/Table";
import AGGridTable from "../../../../artibulles-cis/AGGridTable/AGGridTable";
import TabNavContent from "../../../../artibulles-cis/TabNavContent/TabNavContent";
import AnalysisManualActivationSummaryTable from "./AnalysisManualActivationSummaryTable";
import {Preview} from "@artibulles-cis/react-icons";
const {
    format,

    parseISO,
} = require("date-fns");
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendTabContent: {
        padding: "5px",
    },
    TableWrapper: {
        width: "100%",
        maxWidth: "100%",
        height: "100%",
        overflow: "hidden",
        // padding: "20px",
        // position: "relative",
        // boxSizing: "border-box",
        // display: "flex",
        // margin: "0 auto",
        // justifyContent: "center",
    },
}));

const TestsTableView = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const Ref_TabNavContent = useRef(null);
    const History = useHistory();
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "https://artibulles-es-dataserver.artibulles.com" : "https://artibulles-es-dataserver.artibulles.com";

    const HandleViewRow = (Id, RowData) => {
        if (Id) {
			console.log("Id",Id)
			console.log("RowData",RowData)
			window.open(`/analysis/manualactivationdetails/${Id}`, "_blank");
            // if (DevMode) {
            //     History.push(`/analysis/manualactivation/${Id}`);
            // } else {
            //     window.open(`/analysis/manualactivation/${Id}`, "_blank");
            // }
        }
        // setShowEditCard(true);
        // setEditCardId(Id);
    };

    // const HandlePreviewRow = (arg) => {
    //     console.log("handle preview");

    //     let RowId = arg._id;
    //     if (RowId) {
    //         if (DevMode) {
    //             History.push(`/analysis/manualactivation/${RowId}`);
    //         } else {
    //             window.open(`/analysis/manualactivation/${RowId}`, "_blank");
    //         }
    //     }
    // };

    const ColumnButtonStyle = {
        Width: 45,
        padding: "0px 0px 0px 0px",
    };

    const TableRowButtons = [
        {
            Standard: true,
            Name: "View",
            ActiveCondition: null,
            Index: 1,
            OnClick: HandleViewRow,
            // IconSize: "10px",
            // ActiveColor: "red",
            // HoverColor: "green",
            // InactiveColor: "blue",
            Tooltip: {Show: true, Position: "right", Theme: "light"},
        },
        // {
        //     Standard: true,
        //     Name: "Edit",
        //     ActiveCondition: null,
        //     Index: 2,
        //     OnClick: HandleDuplicateInvoice,
        //     Tooltip: {Show: true, Position: "right", Theme: "light"},
        // }
    ];

    const MixPressureRendering = (params) => {
        let RowData = params.data;
        let MixPressure = RowData.MixingSealData.MaxPressure;
        return parseFloat(MixPressure).toFixed(1);
    };
    const AccessPressureRendering = (params) => {
        let RowData = params.data;
        let MixPressure = RowData.AccessSealData.MaxPressure;
        return parseFloat(MixPressure).toFixed(1);
    };

    const TableColumns = [
        {
            field: "TestDateIso",
            headerName: "Test Date",
            headerClass: "ag-CenterHeader",
            width: 150,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sort: "desc",
            sortIndex: 1,
            sortingOrder: ["asc", "desc"],
            Type: "Date",
        },
        {
            field: "OperatorName",
            headerName: "Operator",
            headerClass: "ag-CenterHeader",
            width: 200,
            filter: true,
            autoHeight: true,
            wrapText: true,
        },
        {
            field: "Protocol_Id",
            headerName: "Protocol",
            headerClass: "ag-CenterHeader",
            width: 200,
            filter: true,
            autoHeight: true,
            wrapText: true,
        },
        {
            field: "Sample_Id",
            headerName: "SampleId",
            headerClass: "ag-CenterHeader",
            width: 200,
            filter: true,
            autoHeight: true,
            wrapText: true,
        },
        {
            field: "TestType",
            headerName: "Test type",
            headerClass: "ag-CenterHeader",
            width: 120,
            filter: true,
            autoHeight: true,
            wrapText: true,
        },
        {
            field: "TestCompleted",
            headerName: "Completed",
            headerClass: "ag-CenterHeader",
            width: 120,
            filter: true,
            autoHeight: true,
            wrapText: true,

            Type: "Boolean",
        },
        {
            field: "DevModeOnly",
            headerName: "Dev Mode",
            headerClass: "ag-CenterHeader",
            width: 120,
            filter: true,
            autoHeight: true,
            wrapText: true,

            Type: "Boolean",
        },
        {
            field: "MixingSealData.MaxPressure",
            headerName: "Pmax Mixing",
            headerClass: "ag-CenterHeader",
            cellRenderering: MixPressureRendering,
            width: 100,
            filter: true,
            autoHeight: true,
            wrapText: true,
        },
        {
            field: "MixingSealData.EaseOfOpeningRanking",
            headerName: "Mixing EOO",
            headerClass: "ag-CenterHeader",
            // cellRenderering: MixPressureRendering,
            width: 100,
            filter: true,
            autoHeight: true,
            wrapText: true,
        },
        {
            field: "MixingSealData.OpeningDescription",
            headerName: "Opening Desc. EOO",
            headerClass: "ag-CenterHeader",
            // cellRenderering: MixPressureRendering,
            width: 200,
            filter: true,
            autoHeight: true,
            wrapText: true,
        },

        {
            field: "AccessSealData.MaxPressure",
            headerName: "Pmax Access",
            headerClass: "ag-CenterHeader",
            cellRenderering: AccessPressureRendering,
            width: 100,
            filter: true,
            autoHeight: true,
            wrapText: true,
        },

        {
            field: "AccessSealData.EaseOfOpeningRanking",
            headerName: "Mixing EOO",
            headerClass: "ag-CenterHeader",
            // cellRenderering: MixPressureRendering,
            width: 100,
            filter: true,
            autoHeight: true,
            wrapText: true,
        },
        {
            field: "AccessSealData.OpeningDescription",
            headerName: "Opening Desc. EOO",
            headerClass: "ag-CenterHeader",
            // cellRenderering: MixPressureRendering,
            width: 200,
            filter: true,
            autoHeight: true,
            wrapText: true,
        },
        // {
        //     field: "Calc_RefInvoice",
        //     headerName: "Ref",
        //     headerClass: "ag-CenterHeader",
        //     width: 170,
        //     filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
        //     sort: "asc",
        //     sortIndex: 2,
        //     sortingOrder: ["asc", "desc"],
        // },

        // {
        //     field: "CalcTotalVATIncluded",
        //     headerName: "Amount (VAT)",
        //     headerClass: "ag-CenterHeader",
        //     width: 140,
        //     filter: "agNumberColumnFilter",
        //     filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
        //     Type: "Currency",
        //     // TotalRow: {
        //     //     Show: true,
        //     //     // Rendering: (params) => {
        //     //     //     return <div>Coucou</div>;
        //     //     // },
        //     // },
        // },
        // {
        //     field: "status_invoice",
        //     //
        //     headerName: "Invoice Status",
        //     headerClass: "ag-CenterHeader",
        //     width: 130,
        //     filter: true,
        //     sortingOrder: ["asc", "desc"],
        //     cellRenderering: StatusRendering,
        // },

        // {
        //     field: "PurchaseInvoicePaymentActionNeeded",
        //     //
        //     headerName: "Payment Status",
        //     headerClass: "ag-CenterHeader",
        //     width: 120,
        //     filter: true,
        //     sortingOrder: ["asc", "desc"],
        //     cellRenderering: PaymentStatusRendering,
        //     cellStyle: PaymentCell,
        // },
        // {
        //     field: "AccountantStatus",
        //     //
        //     headerName: "Accountant Status",
        //     headerClass: "ag-CenterHeader",
        //     width: 130,
        //     filter: true,
        //     sortingOrder: ["asc", "desc"],
        //     cellRenderering: AccountantStatusRendering,
        //     cellStyle: PaymentCell,
        // },
        // {
        //     field: "InvoicePaymentDueDateIso",
        //     headerName: "Due Date",
        //     headerClass: "ag-CenterHeader",
        //     width: 150,
        //     filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
        //     sortingOrder: ["asc", "desc"],
        //     Type: "Date",
        // },
        // {
        //     headerName: "Supplier",
        //     headerClass: "ag-CenterHeader",
        //     width: 300,
        //     filter: true,
        //     Lookup: {LookupField: "ref_Customer", Source: "Customers", ReturnField: "company"},
        //     sort: "asc",
        //     sortIndex: 2,
        //     sortingOrder: ["asc", "desc"],
        // },

        // {
        //     field: "Description",
        //     headerName: "Description",
        //     headerClass: "ag-CenterHeader",
        //     flex: 1,
        //     filter: true,
        //     autoHeight: true,
        //     wrapText: true,
        //
        // },
        // // {
        // //     field: "InvoicePaymentExpectedCommunication",
        // //     headerName: "Communication",
        // //     headerClass: "ag-CenterHeader",
        // //     width: 300,
        // //     filter: true,
        // // },

        // {
        //     field: "Invoices_File_Path",
        //     headerName: "Docs",
        //     headerClass: "ag-CenterHeader",
        //     width: 90,
        //     filter: false,
        //     cellRenderering: PDFRendering,
        //     cellStyle: PDFCell,
        // },

        // {
        //     field: "CalcTotalVATExcluded",
        //     headerName: "Tot VAT Exc.",
        //     headerClass: "ag-CenterHeader",
        //     width: 150,
        //     filter: "agNumberColumnFilter",
        //     filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
        //     Type: "Currency",
        //     // TotalRow: {
        //     //     Show: true,
        //     //     // Rendering: (params) => {
        //     //     //     return <div>Coucou</div>;
        //     //     // },
        //     // },
        // },
        // {
        //     field: "CalcTotalVATAmount",
        //     headerName: "Tot VAT",
        //     headerClass: "ag-CenterHeader",
        //     width: 130,
        //     filter: "agNumberColumnFilter",
        //     filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
        //     Type: "Currency",
        //     // TotalRow: {
        //     //     Show: true,
        //     //     // Rendering: (params) => {
        //     //     //     return <div>Coucou</div>;
        //     //     // },
        //     // },
        // },
        // {
        //     field: "updatedAt",
        //     headerName: "Last updated",
        //     headerClass: "ag-CenterHeader",
        //     width: 130,
        //     filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
        //     Type: "Date",
        //     sortingOrder: ["asc", "desc"],
        // },
    ];

    const TableOptions = {
        // Selection: {
        //     // Multiple: true,
        //     WithCheckBox: true,
        // },

        Resizable: true,
        Sortable: true,
        Filterable: true,
        WrapText: true,
        WrapHeader: true,
        SideBar: "filters",
        StatusBar: true,
    };

    const FinalAPIUrlAbsolute = {
        ALL: `${ProdURL}/api/pdprotectesting/manualactivation/analysis/results`,
        // QUERY: `${ProdURL}/api/companies/query`,
        // DELETEONE: `${ProdURL}/api/saleinvoices/invoicetableoperation`,
        // DELETEMANY: `${ProdURL}/api/saleinvoices/invoicetableoperation`,
        // PUT: `${ProdURL}/api/saleinvoices/invoicetableoperation`,
    };
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ShowViewCard, setShowViewCard] = useState(false);
    const [ViewCardId, setViewCardId] = useState(null);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleViewSample = (id) => {
        History.push(`/analysis/manualactivation/${id}`);
    };

    const HandleCloseViewCard = () => {
        setShowViewCard(false);
        setViewCardId(null);
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    var ModalCardView;
    if (ShowViewCard === true) {
        ModalCardView = (
            <CardModal
                // CardID="HealFit_CreateDailyIntake"
                ParentCompRef={Ref_TabNavContent}
                // TopBarContent={CardTitle}
                CloseCard={HandleCloseViewCard}
                CloseOnClickOutside={true}
                WithCloseButton={true}
                CardMaxWidth="400px"
                CardHeight="500px"
                WithPerfectScrollbar={true}
                // CardWindowPaddingVertical="50px"
                CardWindowPaddingHorizontal="50px"
                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.CardExtended}
            >
                <div className={classes.CardContent}>
                    <AnalysisManualActivationSummaryTable Id={ViewCardId} />
                </div>
            </CardModal>
        );
    }
    return (
        <TabNavContent
            TabRef={Ref_TabNavContent}
            ClassExtendTabContent={classes.ClassExtendTabContent}
            //   Border={ContentBorder}
            // Background="blue"
            //   InnerPadding={ContentPadding}
            //   MaxWidth={ContentMaxWidth}
            Height="100%"
            // PerfectScrollBar={{Active: true, ScrollX: false, ScrollY: true}}
            // ScrollX="hidden"
            // ScrollY="auto"
        >
            {ModalCardView}
            <div className={classes.TableWrapper}>
                <AGGridTable
                    TableName="ManualActivation"
                    URLAbsolute={FinalAPIUrlAbsolute}
                    TableColumns={TableColumns}
                    // MainDataSource="Invoices"
                    RowActionButtons={TableRowButtons}
                    ColumnButtonStyle={ColumnButtonStyle}
                    TableOptions={TableOptions}
                    // ReloadData={ReloadTableData}
                    // SyncReloadDataStateFunction={setReloadTableData}
                    // TotalRow={TotalRow}
                    // PaginationOptions={{
                    //     Pagination: true,
                    //     PageSize: 50,
                    // }}
                />
            </div>
        </TabNavContent>
    );

    /***************** RENDER ******************/
};

export default TestsTableView;
