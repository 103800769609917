import React, {useEffect, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";

import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ChartWrapper: {
        flex: "0 1 1500px",
        boxSizing: "border-box",
        maxWidth: "100%",
        padding: "20px",
        // height: "600px",
        display: "flex",
        position: "relative",
        overflow: "hidden",
    },
    HighChartContainer: {
        flex: "1 1 auto",
        height: "600px",	
        // minWidth: "1500px",
        // maxWidth: "1500px",
    },
}));

const GraphWrapper = (props) => {
    const {ChartInputs, ChartInstance, ChartHeight} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const ChartRef = useRef(null);
    const ChartContainerRef = useRef(null);
    /***************** REFS ******************/

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    //Resizing the chart
    useEffect(() => {
        window.addEventListener("resize", HandleWindowResize);
        return function cleanup() {
            window.removeEventListener("resize", HandleWindowResize);
        };
    }, []);

    //Passing the ChartInstance to the ParentComponent
    useEffect(() => {
        if (ChartInstance && ChartRef.current) {
            ChartInstance(ChartRef.current.chart);
        }
    }, [ChartRef, ChartInstance]);



	useEffect(()=> {


	},[ChartHeight])
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleWindowResize = () => {
        if (ChartContainerRef && ChartContainerRef.current) {
            let ChartInstance = ChartRef.current.chart;
            ChartInstance.setSize(100, 600);
            let width = ChartContainerRef.current.getBoundingClientRect().width - 40;

            // console.log(ChartInstance)
            setTimeout(() => {
                ChartInstance.setSize(width, 600);
            }, 20);
        }
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <div className={classes.ChartWrapper} ref={ChartContainerRef}>
            <HighchartsReact
                ref={ChartRef}
				options={ChartInputs}
                highcharts={Highcharts}
                constructorType={"chart"}
                allowChartUpdate={true}
                immutable={false}
                updateArgs={[true, true, true]}
                containerProps={{className: classes.HighChartContainer}}
            />
        </div>
    );

    /***************** RENDER ******************/
};

export default GraphWrapper;
