import React from "react";
import {createUseStyles, useTheme} from "react-jss";
import {useHistory} from "react-router-dom";
import Page from "@artibulles-cis/react/Page";
import Button from "@artibulles-cis/react/Button/Button";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    MainBoxes: {
        display: "flex",
        justifyContent: "center",
        boxSizing: "border-box",
    },
    Box: {
        display: "flex",
        flexDirection: "column",
        margin: "0px 15px",
        boxSizing: "border-box",
        border: "1px solid grey",
        borderRadius: "5px",
        background: "rgba(35, 35, 35, 1)",
        color: "white",
        maxWidth: "500px",
    },
    BoxTitle: {
        display: "flex",
        justifyContent: "center",
        boxSizing: "border-box",
        borderBottom: "1px solid white",
        padding: "5px 15px",
        fontWeight: "500",
        height: "50px",
        alignItems: "center",
    },
    BoxContent: {
        padding: "15px 15px",
        background: "white",
        color: "black",
        flex: "1 0 auto",
    },
    BoxBottom: {
        display: "flex",
        justifyContent: "center",
        boxSizing: "border-box",
        borderTop: "1px solid white",
        padding: "5px 15px",
        height: "50px",
        alignItems: "center",
    },
    Button: {
        margin: "0px",
        padding: "0px",
        height: "30px",
    },
}));

const HomePage = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const History = useHistory();
    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleSelect = (type) => {
        if (type === "ManualActivation") {
            //Redirect
            History.push(`/testing/menu/manualactivation`);
        } else if (type === "BurstTest") {
            //Redirect
            History.push(`/testing/menu/bursttest`);
        }
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <Page WithPerfectScrollBar={true} PagePadding="10px">
            <h1 style={{textAlign: "center"}}>Peel seals testing modes</h1>
            <div className={classes.MainBoxes}>
                <div className={classes.Box}>
                    <div className={classes.BoxTitle}>Manual Activation</div>
                    <div className={classes.BoxContent}>
                        <p>In this mode, the bag is fully filled and the operator activates the bag to open the peel seals.</p>
                        <p>A pressure sensor records the pressure inside the bag.</p>
                        <p>The following pre-programmed modes can be used:</p>
                        <ul>
                            <li>Full bag (Mixing seal and access seal)</li>
                            <li>Mixing seal only</li>
                            <li>Missuse (access seal only)</li>
                            <li>Development (Flex mode for development purposes)</li>
                        </ul>
                    </div>
                    <div className={classes.BoxBottom}>
                        <Button ClassExtendButton={classes.Button} onClick={() => HandleSelect("ManualActivation")}>
                            Manual Activation
                        </Button>
                    </div>
                </div>
                <div className={classes.Box}>
                    <div className={classes.BoxTitle}>Burst test</div>
                    <div className={classes.BoxContent}>
                        <p>In this mode, the bag is empty and air pressure is applied inside the bag until the peel seal opens.</p>
                        <p>A pressure sensor records the air pressure inside the bag.</p>
                        <p>The following pre-programmed modes can be used:</p>
                        <ul>
                            <li>Full bag (Mixing seal and access seal)</li>
                            <li>Mixing seal only</li>
                            <li>Missuse (access seal only)</li>
                            <li>Development (Flex mode for development purposes)</li>
                        </ul>
                        <p style={{fontStyle: "italic"}}>Note: The test can be performed with a free bag or constrained</p>
                    </div>
                    <div className={classes.BoxBottom}>
                        <Button ClassExtendButton={classes.Button} onClick={() => HandleSelect("ManualActivation")}>
                            Burst Test
                        </Button>
                    </div>
                </div>
            </div>
        </Page>
    );

    /***************** RENDER ******************/
};

export default HomePage;
