/* eslint-disable */
import React, {useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";
import {useHistory} from "react-router-dom";
import APICallExternal from "@artibulles-cis/react/utils/APICallExternal";
import {useSelector, useDispatch} from "react-redux";
import {SIGN_OUT_USER} from "../../../reduxFunctions/auth/authConstants";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

const Logout = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
	const History = useHistory();
	const Dispatch = useDispatch();
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [Loading, setLoading] = useState(false);
    const [ApiRes, setApiRes] = useState(null);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = React.useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            if (ApiRes.error) {
            } else {
				Dispatch({type: SIGN_OUT_USER, payload: null});
				
               
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        const ApiCall = {url: `https://artibulles-es-dataserver.artibulles.com/api/auth/logout`, type: "post"};
        setLoading(true);

        async function APIInternal() {
            const res = await APICallExternal(ApiCall.url, ApiCall.type, null);
            
            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    }, []);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return <div>Logout</div>;

    /***************** RENDER ******************/
};

export default Logout;
