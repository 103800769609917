import React, {useState, useRef} from "react";
import {useHistory} from "react-router-dom";
import {createUseStyles, useTheme} from "react-jss";
import TabNavContent from "@artibulles-cis/react/TabNavContent";
import Button from "@artibulles-cis/react/Button/Button";
import CardModal from "@artibulles-cis/react/CardModal";
import {WarningHexagonRed} from "@artibulles-cis/react-icons";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    Main: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
		color : "black",
		textAlign : "left"

    },
    Intro: {
        display: "flex",
        alignItems: "center",
        maxWidth: "1200px",
    },
    Intro_Icon: {
        flex: "0 0 200px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    Intro_Message: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        // alignItems: "center",
        marginLeft: "20px",
    },
    h: {
        margin: "5px 0px",
    },
    Description: {
        display: "flex",
        // alignItems : "center",
        marginTop: "20px",
    },
    Description_Picture_Wrapper: {
        display: "flex",
    },
    Description_Picture: {
        width: "400px",
        height: "auto",
    },
    Description_Instructions: {},
    
	CardExtended: {
        borderRadius : "10px",
		padding:"10px"
		// background :"blue"
    },
	CardContent : {
		// borderRadius : "10px"
	}
}));

const ManualActivationFullBag = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});
	const History = useHistory();
    /***************** REF ******************/
    const Ref_TabNavContent = useRef(null);
    /***************** REF ******************/
    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/
	//eslint-disable-next-line
    const [ShowCard, setShowCard] = useState(false);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleStartTest = () => {
		History.push(`/testing/manualactivation/fullbag/step1`);
        // setShowCard(true);
        
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    return (
        <TabNavContent
            TabRef={Ref_TabNavContent}
            ClassExtendTabContent={classes.ClassExtendTabContent}
            //   Border={ContentBorder}
            // Background="blue"
            //   InnerPadding={ContentPadding}
            //   MaxWidth={ContentMaxWidth}
            // Height="100%"
            // PerfectScrollBar={{Active: true, ScrollX: false, ScrollY: true}}
            // ScrollX="hidden"
            // ScrollY="auto"
        >
            <div className={classes.Main}>
                <div className={classes.Intro}>
                    <div className={classes.Intro_Icon}>
                        <WarningHexagonRed IconSize="150px" />
                    </div>

                    <div className={classes.Intro_Message}>
                        <h2 className={classes.h}>To ensure data integrity and compliance to Artibulles quality standards, once the test is initiated, it needs to be completed till the end.</h2>
                        <h3 className={classes.h} style={{fontStyle: "italic"}}>
                            A log file of all activities will be stored on the server to ensure tracibility
                        </h3>
                    </div>
                </div>
                <div className={classes.Description}>
                    <div className={classes.Description_Picture_Wrapper}>
                        <img className={classes.Description_Picture} src="/images/PeelSealsPressureTester_V1.svg" alt="TestDescription" />
                    </div>
                    <div className={classes.Description_Instructions}>
                        <h2 style={{marginTop: "30px"}}>The "Full Bag" pre-programmed test mode consists of 2 steps:</h2>
                        <ul>
                            <li>Step 1 : Manually activating the mixing seal</li>
                            <li>Step 2 : Manually activating the access seal</li>
                        </ul>
                        <h2>When ready to proceed please press the start test button</h2>
                        <Button Width="200px" onClick={HandleStartTest}>
                            Start Full Bag test
                        </Button>
                    </div>
                </div>
            </div>
            <CardModal
                // CardID="HealFit_CreateDailyIntake"
                ParentCompRef={Ref_TabNavContent}
                // TopBarContent={CardTitle}
                // CloseCard={HandleCloseViewCard}
                // CloseOnClickOutside={true}
                // WithCloseButton={true}
                // CardMaxWidth="400px"
                // CardHeight="500px"
                WithPerfectScrollbar={true}
                CardWindowPaddingVertical="5px"
                CardWindowPaddingHorizontal="10px"
                ShowCard={ShowCard}
                WindowBackgroundColor="rgba(0,0,0,0.6)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.CardExtended}
				
            >
                <div className={classes.CardContent}>Hello</div>
            </CardModal>
        </TabNavContent>
    );

    /***************** RENDER ******************/
};

export default ManualActivationFullBag;
