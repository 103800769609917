import React, {useState, useCallback, useEffect, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import CardModal from "@artibulles-cis/react/CardModal";
import {ArrowCircleLeft, ArrowCircleRight} from "@artibulles-cis/react-icons";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    CardExtended: {
        borderRadius: "10px",
        padding: "10px",
        // background :"blue"
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    CardContent: {
        flex: "1 1 auto",
        // height: "100%",
        // borderRadius: "10px",
        // padding: "30px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxSizing: "border-box",
    },
    ArrowLeftFlex: {
        flex: "1 1 20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    ArrowRightFlex: {
        flex: "1 1 20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
    },
    ImageContainer: {
        display: "flex",
        boxSizing: "border-box",
        justifyContent: "center",
        position: "relative",

        // width: "100%",
        // height: "300px",
    },
    ImagePreview: {
        flex: "1 1 auto",
        display: "block",
        width: "auto",
        height: "auto",
        maxWidth: "100%",
        maxHeight: "100%",
    },
}));

const PictureViewer = (props) => {
    const {ShowCard, ImgSrc, HandleCloseViewCard, ImagesPath, ImagePathIndex} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const Ref_Card = useRef(null);
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ImgContainerSize, setImgContainerSize] = useState({Width: null, Height: null});
    const [ImgSource, setImgSource] = useState(ImgSrc);
    const [ImageIndex, setImageIndex] = useState(ImagePathIndex);

    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const CalculateImageSize = useCallback(() => {
        if (Ref_Card && Ref_Card.current) {
            let ParentElem = Ref_Card.current.parentNode;
            if (ParentElem) {
                let TopPArent = ParentElem.parentNode;
                if (TopPArent) {
                    let ParentElemHeight = TopPArent.getBoundingClientRect().height - 20;
                    let ParentElemWidth = TopPArent.getBoundingClientRect().width - 20;

                    let ImageContainerDimensions = {Width: ParentElemWidth + "px", Height: ParentElemHeight + "px"};
                    setImgContainerSize(ImageContainerDimensions);
                }
            }
        }
    }, [Ref_Card]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        //Calculating the card dimensions to make the picture fit to the window
        CalculateImageSize();
    }, [CalculateImageSize, Ref_Card]);

    React.useEffect(() => {
        window.addEventListener("resize", CalculateImageSize);
        return function cleanup() {
            window.removeEventListener("resize", CalculateImageSize);
        };
    }, [CalculateImageSize]);

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleViewPrevNext = (arg) => {
        if (arg === "Previous") {
            if (ImageIndex > 0) {
                let NewIndex = ImageIndex - 1;
                setImageIndex(NewIndex);
                setImgSource(ImagesPath[NewIndex]);
            }
        } else {
            if (ImageIndex < ImagesPath.length-1) {
                let NewIndex = ImageIndex + 1;
                setImageIndex(NewIndex);
                setImgSource(ImagesPath[NewIndex]);
            }
        }
        console.log(arg);
    };

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <CardModal
            // CardID="HealFit_CreateDailyIntake"
            // ParentCompRef={Ref_TabNavContent}
            // TopBarContent={CardTitle}
            CloseCard={HandleCloseViewCard}
            CloseOnClickOutside={true}
            WithCloseButton={true}
            CardMaxWidth="1300px"
            // CardHeight="500px"
            // IsResizable={true}
            // IsDraggable={true}
            WithPerfectScrollbar={false}
            CardWindowPaddingVertical="30px"
            CardWindowPaddingHorizontal="30px"
            ShowCard={ShowCard}
            WindowBackgroundColor="rgba(0,0,0,0.6)"
            CardBackgroundColor="white"
            CardBackground="white"
            ClassExtendCard={classes.CardExtended}
        >
            <div ref={Ref_Card} className={classes.CardContent}>
                <div className={classes.ArrowLeftFlex}>
                    <ArrowCircleLeft onClick={() => HandleViewPrevNext("Previous")} />
                </div>
                <div className={classes.ImageContainer} style={{height: ImgContainerSize.Height}}>
                    <img className={classes.ImagePreview} src={ImgSource ? ImgSource : null} alt="pic" />
                </div>
                <div className={classes.ArrowRightFlex}>
                    <ArrowCircleRight onClick={() => HandleViewPrevNext("Next")} />
                </div>
            </div>
        </CardModal>
    );

    /***************** RENDER ******************/
};

export default PictureViewer;
