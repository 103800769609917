/* eslint-disable */
import React, {useEffect, useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import Button from "@artibulles-cis/react/Button";
import {socketPIsensor} from "../../../common/util/socketPIsensor";
import FormCompontent from "@artibulles-cis/react/FormComponent";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    Toolbar: {
        display: "flex",
    },
}));

const SocketIOTest = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const Ref_Stream = useRef(null);
    const Ref_FormValues = useRef(null);

    /***************** CONST ******************/

    /***************** STATE ******************/
    const [PISensorsSocketConnection, setPISensorsSocketConnection] = useState(false); //Checking the PI sensor Socket Connection
    const [Stream, setStream] = useState(null);
    const [InitialFormValues, setInitialFormValues] = React.useState({
        CameraMode: "01",
        SampleRate: null,
        //DONT FOLD
    });
    const [FormValues, setFormValues] = React.useState({
        CameraMode: "01",
        SampleRate: null,
        //DONT FOLD
    });

    const [FieldOptions, setFieldOptions] = React.useState({
        CameraMode: [
            {value: "01", label: "4056x3040-10fps-Full"},
            {value: "02", label: "2028x1080-50fps-Partial"},
            {value: "03", label: "2028x1080-50fps-Full"},
            {value: "04", label: "1332x990-120fps-Partial"},
        ],
    }); //FORM OPTIONS FOR SELECT
    const [PristineDetails, setPristineDetails] = useState({});
    const [Pristine, setPristine] = useState(true); //Form has been edited by the user -> Pristie === false

    const [Invalid, setInvalid] = useState(false); //Form has invalid fields

    const [FormErrors, setFormErrors] = useState({}); //HNDLING FORM ERRORS

    const [FormErrorMain, setFormErrorMain] = useState(); //HNDLING FORM ERROR GLOBAL MESSAGE
    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        //Requesting connection to the PI-Sensors Socket Server
        socketPIsensor.emit("RequestConnection", () => {});
        //Receiving confirmation
        const HandlePISensorConnection = () => setPISensorsSocketConnection(true);

        socketPIsensor.on("UserConnected", (data) => {
            console.log("Server confirmed scoket connection", data);
            HandlePISensorConnection();
        });
        //Streaming the data
        socketPIsensor.on("CameraStream", (data) => {
            Ref_Stream.current = data;
            setStream(data);
        });

        return () => {
            console.log("UseEffectReturn");
            socketPIsensor.off("UserConnected", HandlePISensorConnection);
        };
    }, []);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleUpdateFormValues = (field, values) => {
        let value = values.Value;
        let formattedValue = values.FormattedValue;
        let FieldPristine = values.Pristine;

        //Checking Pristine

        // Update the pristine based on the Field
        var FinalPristine = true;
        let PristineKeys = _.keys(PristineDetails);
        let UpdatedPristineDetails = PristineDetails;

        if (PristineKeys && PristineKeys.length > 0) {
            //Going through all the pristine fields to check if one of them is false
            var CurrentFieldHandled = false;
            PristineKeys.forEach((key) => {
                if (key === field) {
                    CurrentFieldHandled = true;
                    //Update the pristine value
                    UpdatedPristineDetails[field] = FieldPristine;
                    if (FieldPristine === false) {
                        FinalPristine = false;
                    }
                } else {
                    //just checking if false;
                    if (UpdatedPristineDetails[key] === false) {
                        FinalPristine = false;
                    }
                }
            });
            if (CurrentFieldHandled === false) {
                //The key wasn't there so we need to add it
                if (FieldPristine === false) {
                    UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                    FinalPristine = false;
                }
            }
        } else {
            //First field to be touched
            if (FieldPristine === false) {
                UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                FinalPristine = false;
            }
        }
        setPristineDetails(UpdatedPristineDetails);
        setFormValues({...FormValues, ...{[field]: value}});
        Ref_FormValues.current = {...FormValues, ...{[field]: value}};
        setPristine(FinalPristine);
        //Manual update the pristine
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/
        // let FormValuesKeys = _.keys(FormValues);
        // var FinalPristine = true;

        // if (FormValuesKeys && FormValuesKeys.length > 0) {
        //     FormValuesKeys.forEach((key) => {
        //         let InitialValue = InitialFormValues[key];

        //         var Value;
        //         if (key !== field) {
        //             Value = FormValues[key];
        //         } else {
        //             //Check that the modified field is not the same as the InitalValue (The value has not changed yet)
        //             Value = value;
        //         }
        //         if (InitialValue === undefined) {
        //             InitialValue = null; //We set it to null to be able to compare to the Input Value that is set to null
        //             //It means that there was no initialValue passed to the form (that's the case when it is new)
        //         }
        //         if (InitialValue !== Value) {
        //             FinalPristine = false;
        //         }
        //     });
        // }

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        //Confirm Errors
        // ValidateForm(field, value);
        // HandleFiedsChange(field, value);
    };
    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/
    const handleSelectChange = (field, SelectedDetails) => {
        //Hanlding the select change
        //Checking Pristine

        let FieldPristine = SelectedDetails.Pristine;
        let SelectedValues = SelectedDetails.Value;

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/
        var FinalPristine = true;

        //Checking Pristine using the FieldResponse
        let PristineKeys = _.keys(PristineDetails);
        let UpdatedPristineDetails = PristineDetails;

        if (PristineKeys && PristineKeys.length > 0) {
            //Going through all the pristine fields to check if one of them is false
            var CurrentFieldHandled = false;
            PristineKeys.forEach((key) => {
                if (key === field) {
                    CurrentFieldHandled = true;
                    //Update the pristine value
                    UpdatedPristineDetails[field] = FieldPristine;
                    if (FieldPristine === false) {
                        FinalPristine = false;
                    }
                } else {
                    //just checking if false;
                    if (UpdatedPristineDetails[key] === false) {
                        FinalPristine = false;
                    }
                }
            });
            if (CurrentFieldHandled === false) {
                //The key wasn't there so we need to add it
                if (FieldPristine === false) {
                    UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                    FinalPristine = false;
                }
            }
        } else {
            //First field to be touched
            if (FieldPristine === false) {
                UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                FinalPristine = false;
            }
        }
        setPristineDetails(UpdatedPristineDetails);
        setPristine(FinalPristine);
        var UpdatedFormValues = {...FormValues, ...{[field]: SelectedValues}};
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        /************* IF NO CONDITIONAL SELECT USE THIS *****************/
        setFormValues(UpdatedFormValues);
        /************* IF NO CONDITIONAL SELECT USE THIS *****************/

        /************* CONDITIONAL SELECT *****************/
        //To prevent having to deal with different server configuration, the logic to implement here must be tailored to your needs

        /*Option 1 :
         *Updating the options of a subfield based on the select value ->
         *If you use this, don't forget to specify that the conditional field is disabled if the parentField is empty or null
         */
    };

    const HandleStartStreamingCamera = () => {
        // console.log("start streaming");
        let Width, Height, FPS, StreamRate, SensorMode;
        if (FormValues.CameraMode === "01") {
            Width = 4056;
            Height = 3040;
            FPS = 10;
            StreamRate = 100;
			// SensorMode = "03"
        } else if (FormValues.CameraMode === "02") {
            Width = 2028;
            Height = 1080;
            FPS = 30;
            StreamRate = 20;
			// SensorMode = "01"
        } else if (FormValues.CameraMode === "03") {
            Width = 2028;
            Height = 1080;
            FPS = 50;
            StreamRate = 20;
			// SensorMode = "02"
        } else if (FormValues.CameraMode === "04") {
            Width = 1332;
            Height = 990;
            FPS = 60;
            StreamRate = 20;
			// SensorMode = "04"
        }
        // let PicRatio = 3 / 4;
        let PicRatio = 55 / 75;

        // const CameraOptions = {
        //     width: 1332,
        //     height: 990,
        //     fps: 90,
        //     StreamRate: 90, //ms
        //     SensorMode: SensorMode,
        // };

		const CameraOptions = {
            width: Width,
            height: Height,
            StreamRate: StreamRate,
            camerafps: FPS, //ms
            SensorMode: SensorMode,
        };
        socketPIsensor.emit("StartStreamCameraFrames", CameraOptions);
    };

    const HandleStopStreamingCamera = () => {
        console.log("stop streaming");
        socketPIsensor.emit("StopStreamCameraFrames");
    };

    const HandleStartVideo = () => {
        console.log("start video");
        let PicRatio = 3 / 4;

        const CameraOptions = {
            width: 1280,
            // height: PicRatio * 640,
            height: 720,
            camerafps: 20,
        };
        socketPIsensor.emit("TakeVideo", CameraOptions);
    };

    const HandleStopVideo = () => {
        console.log("StopVideo");
        socketPIsensor.emit("StopVideo");
    };

    const HandleStartJpgeStream = () => {
        console.log("start streaming");
        let PicRatio = 3 / 4;

        const CameraOptions = {
            width: 1332,
            height: 990,
            fps: 60,
            StreamRate: 100, //ms
        };

        // const CameraOptions= {
        // 	width: 640,
        // 	height: 480,
        // 	fps: 90,
        // 	StreamRate: 100, //take a picture every StreamRate ms
        // }
        socketPIsensor.emit("SaveImagesFromCameraStream", CameraOptions);
    };

    const HandleStopJpgeStream = () => {
        console.log("stop streaming");
        socketPIsensor.emit("StopSaveImagesFromCameraStream");
    };

    const HandleRecoverTest = () => {
        console.log("recover");
        socketPIsensor.emit("recover sample data", {SampleId: "recovertest", chamber: "mixing"});
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            Pi Connection : {JSON.stringify(PISensorsSocketConnection)}
            <div style={{width: "250px"}}>
                <FormCompontent
                    Name="CameraMode"
                    InitialValue={InitialFormValues.CameraMode ? InitialFormValues.CameraMode : null}
                    FormValue={FormValues.CameraMode ? FormValues.CameraMode : null}
                    OutputSelectedValues={(values) => handleSelectChange("CameraMode", values)}
                    SelectOptions={FieldOptions.CameraMode}
                    Component="Select"
                    Variant="OutlinedLabel"
                    Label="Mode"
                    NoMessage={false}
                    // meta={{error: FormErrors.sensor_installation, invalid: FormErrors.normsensor_installational ? true : false}}
                    // OutputError={(error) => HandleUpdateErrors("sensor_installation", error)}
                    // Required={true}
                    // MultiSelect={true}
                    // ControlledEditViewMode={true}
                    // EditModeActive={FormViewMode === "Edit" ? true : false}
                    // SetFormEditMode={HandleEnableEditMode}
                    // SortSelectOptions="Dsc"
                    // SortLastItemName={{Exact: false, Name: "Arnaud"}}
                    // SortIcons={true}
                    Required={true}
                    // ReadOnly={true}
                />
            </div>
            <div className={classes.Toolbar}>
                <Button onClick={HandleStartStreamingCamera} Width="170px" Height="25px" style={{margin: "10px 5px", padding: "0px 5px 0px 5px"}}>
                    Start Stream
                </Button>
                <Button onClick={HandleStopStreamingCamera} Width="170px" Height="25px" style={{margin: "10px 5px", padding: "0px 5px 0px 5px"}}>
                    Stop Stream
                </Button>
            </div>
            <img src={Stream} alt="stream" style={{width: "700px", height: "auto"}} />
        </div>
    );

    /***************** RENDER ******************/
};

export default SocketIOTest;
// <Button onClick={HandleStartVideo}>Start Video</Button>
//             <Button onClick={HandleStopVideo}>Stop Video</Button>
//             <Button onClick={HandleStartJpgeStream}>Start Saving JPEG stream</Button>
//             <Button onClick={HandleStopJpgeStream}>Stop Saving JPEG stream</Button>
//             <Button onClick={HandleRecoverTest}>Recover test</Button>
