/* eslint-disable */
import React, {useEffect, useState} from "react";
import {Route, Switch, useHistory, Redirect} from "react-router-dom";
import {createUseStyles, useTheme} from "react-jss";
import {ToastContainer, toast, Slide} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useSelector, useDispatch} from "react-redux";
import {LOGIN_USER, SIGN_OUT_USER} from "../reduxFunctions/auth/authConstants";
import {useCookies} from "react-cookie";
import AppWrapper from "@artibulles-cis/react/AppWrapper";
import AppMainContainer from "@artibulles-cis/react/AppMainContainer";
import NavHorizontalBar from "@artibulles-cis/react/NavHorizontalBar";
import TopNavBar from "./TopNavBar/TopNavBar";
import HomePage from "../Pages/Desktop/HomePage";
import TestingRouter from "../Pages/Desktop/Testing/TestingRouter";
import BottomNavBar from "./BottomNavBar/BottomNavBar";
import APICallExternal from "../artibulles-cis/utils/APICallExternal";
import DocRouter from "../Pages/Desktop/Doc/DocRouter";
import {authserverurl} from "../common/util/APIServerAddresses";
import Login from "../Pages/Desktop/LoginAndProfile/Login";
import Logout from "../Pages/Desktop/LoginAndProfile/Logout";
import ProfileViewEdit from "../Pages/Desktop/LoginAndProfile/ProfileViewEdit";
import AnalysisRouter from "../Pages/Desktop/Analysis/AnalysisRouter";
import SamplePrepRouter from "../Pages/Desktop/SamplePrep/SamplePrepRouter";
import DevTestingRouter from "../Pages/Desktop/Devtesting/DevTestingRouter";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendNavSideBarController: {
        height: "50px",
        color: "white",
    },
    SidebarControllerContent_Left: {
        flex: "1 1 auto",
        display: "flex",
        alignItems: "center",
        padding: "0px 20px 0px 0px",
    },
    SideBarController_Icon: {
        flex: "0 0 50px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxSizing: "border-box",
        padding: "0px 0px 0px 0px",
    },
    SideBarController_Icon_Logo: {
        padding: "5px",
        height: "40px",
        width: "auto",
    },
    SideBarController_Hidable_Wrapper: {
        flex: "0 1 auto",
        display: "flex",
        overflow: "hidden",
        wordWrap: "nowrap",
        whiteSpace: "nowrap",
    },
    SideBarController_Hidable_Wrapper_Hidden: {
        flex: "0 1 auto",
        display: "flex",
        overflow: "hidden",
        wordWrap: "nowrap",
        whiteSpace: "nowrap",
    },
    SideBarController_Title_Left: {
        flex: "0 1 auto",
        padding: "0px 0px 0px 30px",
        margin: "0px",
    },
}));
toast.configure();
const AppDesktop = React.memo(function AppDesktop(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    const IntervalAuthCheckMilliseconds = 1000 * 60 * 60 * 24;

    /***************** CONST ******************/
    const AppConfig = {
        maxWidth: "2500px",
    };

    const AuthServerAddress = authserverurl("production");
    const Dispatch = useDispatch();
    const History = useHistory();
    const DevMode = process.env.NODE_ENV === "development" ? "development" : "production";
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [cookies, setCookie] = useCookies(null);
    // Extracting Desired Redux State Variables
    const {authenticated} = useSelector((state) => ({
        authenticated: state.auth.authenticated,
    }));

    const [Initialized, setInitialized] = useState(false);

    const [UserIsAuthenticated, setUserIsAuthenticated] = useState(false);
    const [LeftSideBarMinimized, setLeftSideBarMinimized] = React.useState(false);
    const [ApiRes, setApiRes] = useState(null);
    const [Loading, setLoading] = useState(false);

    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = React.useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                console.log("errors", ApiRes);
                setInitialized(true)
				setUserIsAuthenticated(false);
            } else {
                if (ApiRes.data) {
                    let data = ApiRes.data.data;
                    if (data) {
                        setUserIsAuthenticated(true);
						setInitialized(true)
                        Dispatch({type: LOGIN_USER, payload: data});
                    }
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    //Check If authenticated
    useEffect(() => {
        // console.log("authenticated check")
        if (authenticated === true) {
            // console.log("authenticated")
            //No need to do anything
            setUserIsAuthenticated(true);
        } else {
            if (DevMode === "development") {
                console.log("dev mode");
                //there are no cookies
                const ApiCall = {url: `${AuthServerAddress}/api/auth/checklogin`, type: "post", data: null};
                setLoading(true);
                async function APIInternal() {
                    const res = await APICallExternal(ApiCall);
                    setApiRes(res);
                    setLoading(false);
                }
                APIInternal();
            } else {
                console.log("production");
                if (Object.keys(cookies).length === 0) {
                    //No cookies at all
                    setUserIsAuthenticated(false);
					setInitialized(true)
                } else {
                    //We check that the right cookie is there (not any stupid cookie)

                    if (cookies["artibulles-es-dataserver_Session_P"]) {
                        //we need to call again the server to get the user information in case the user refreshed the page and the store was lost
                        const ApiCall = {url: `${AuthServerAddress}/api/auth/checklogin`, type: "post"};
                        setLoading(true);
                        async function APIInternal() {
                            const res = await APICallExternal(ApiCall);
                            setApiRes(res);
                            setLoading(false);
                        }
                        APIInternal();
                    } else {
						setInitialized(true)
                        setUserIsAuthenticated(false);
                    }
                }
            }
        }
    }, [authenticated]);

    //Check browser cookies to confirm that the user is loged in
    useEffect(() => {
        setInterval(() => {
            if (Object.keys(cookies).length === 0) {
                //No cookies at all
                setUserIsAuthenticated(false);
            } else {
                //We check that the right cookie is there (not any stupid cookie)

                if (cookies["artibulles-es-dataserver_Session_P"]) {
                    setUserIsAuthenticated(true);
                } else {
                    setUserIsAuthenticated(false);
                    Dispatch({type: SIGN_OUT_USER, payload: null});
                }
            }
        }, IntervalAuthCheckMilliseconds);
    }, []);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const ToggleSideBar = () => {
        setLeftSideBarMinimized(!LeftSideBarMinimized);
    };
    const UpdateWidthDraggedDimensions = ({WidthMinimized, widthExpanded}) => {
        // console.log("draggign");
    };
    const HandleLoginCallBack = (callBack) => {
        if (callBack.offline === true) {
            console.log("offline");
        } else {
            Dispatch({type: LOGIN_USER, payload: callBack.User});
            History.push("/");
        }
    };

    var AppAutheticatedComponent = null;
    if (Initialized === false) {
		AppAutheticatedComponent = <React.Fragment>Loading...</React.Fragment>
    } else {
        if (UserIsAuthenticated === true) {
            console.log("Component rendering, authenticated is true");
            AppAutheticatedComponent = (
                <React.Fragment>
                    <NavHorizontalBar Show={true} Height="40px" id="TopFixedNavBar" MaxWidth={AppConfig.maxWidth}>
                        <TopNavBar />
                    </NavHorizontalBar>

                    <AppMainContainer
                        WithPerfectScrollbar={false}
                        WithScrollBar={false}
                        // classExtendMainContainer={classes.classExtendMainContainer}
                        DisableBodyScroll={true}
                        TopRefComponentID="TopFixedNavBar"
                        BottomRefComponentID="BottomFixedNavBar"
                    >
                        <Switch>
                            <Route exact path="/">
                                <HomePage />
                            </Route>
                            <Route exact path="/logout">
                                <Logout />
                            </Route>
                            <Route exact path="/user/profile">
                                <ProfileViewEdit />
                            </Route>

                            <Route path="/testing">
                                <TestingRouter />
                            </Route>
                            <Route path="/sampleprep">
                                <SamplePrepRouter />
                            </Route>

                            <Route path="/analysis">
                                <AnalysisRouter />
                            </Route>
                            <Route path="/doc">
                                <DocRouter />
                            </Route>

                            <Route path="/dev">
                                <DevTestingRouter />
                            </Route>
                        </Switch>
                    </AppMainContainer>
                    <NavHorizontalBar Show={true} Height="35px" Position="bottom" id="BottomFixedNavBar" MaxWidth={AppConfig.maxWidth}>
                        <BottomNavBar />
                    </NavHorizontalBar>
                </React.Fragment>
            );
        } else {
            console.log("Component rendering, authenticated is false");
            AppAutheticatedComponent = <Login HandleLoginCallBack={HandleLoginCallBack} />;
        }
    }

    return (
        <AppWrapper AppMaxWidth={AppConfig.maxWidth} style={{height: "100%"}}>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={true}
                // style={{minWidth : "500px"}}
                transition={Slide}
                closeOnClick={false}
                rtl={false}
                pauseOnVisibilityChange={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover={false}
            />
            {AppAutheticatedComponent}
        </AppWrapper>
    );
});

export default AppDesktop;
