import io from "socket.io-client";
const DevMode = process.env.NODE_ENV === "development" ? true : false;
const SocketIOPort = DevMode ? "https://artibulles-es-qrserver.artibulles.com" : "https://artibulles-es-qrserver.artibulles.com";

export const sockeQRServer = io(SocketIOPort, {
    withCredentials: true,
    auth: {
        token: "React-test-app-need-to-check-how-to-pass-a-secured-token",
    },
});
