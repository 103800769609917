import {createStore, applyMiddleware} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import thunk from "redux-thunk";
import throttle from 'lodash/throttle';
import rootReducer from "./rootReducer";

export const configureStore = (preloadedState) => {
    const middlewares = [thunk];
    const middlewareEnhancer = applyMiddleware(...middlewares);

    const storeEnhancers = [middlewareEnhancer];

    const composedEnhancer = composeWithDevTools(...storeEnhancers);

    const loadState = () => {
        try {
            const serializedState = localStorage.getItem("state");
            if (serializedState === null) {
                return undefined;
            }
            return JSON.parse(serializedState);
        } catch (e) {
            return undefined;
        }
    };

    const saveState = (state) => {
        try {
            const serializedState = JSON.stringify(state);
            localStorage.setItem("state", serializedState);
        } catch (e) {
            // Ignore write errors;
        }
    };

    const peristedState = loadState();

    

    

    const store = createStore(rootReducer, preloadedState, composedEnhancer, peristedState);
	
	
	store.subscribe(throttle(() => {
		saveState(store.getState());
	  }, 1000));


    //Only for dev mode : Use the hotReloader to make it faster
    if (process.env.NODE_ENV !== "production") {
        if (module.hot) {
            module.hot.accept("./rootReducer.js", () => {
                const newRootReducer = require("./rootReducer").default;
                store.replaceReducer(newRootReducer);
            });
        } else {
            const newRootReducer = require("./rootReducer").default;
            store.replaceReducer(newRootReducer);
        }
    }
    return store;
};
