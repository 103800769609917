import React, {useState, useEffect, useRef, useCallback} from "react";
import PropTypes from "prop-types";
import CardModal from "@artibulles-cis/react/CardModal";
import Button from "@artibulles-cis/react/Button";
import {Preview, EditBox, Trash, DuplicateDocument} from "@artibulles-cis/react-icons";
import {createUseStyles, useTheme} from "react-jss";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import {v4 as uuidv4} from "uuid";

//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

//eslint-disable-next-line
const Lockedstyles = {};

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    RowButtonActive: {
        display: "flex",
        padding: "0px 5px",
        boxSizing: "border-box",
        alignItems: "center",
        cursor: "pointer",
    },
    RowButtonInactive: {
        display: "flex",
        padding: "0px 5px",
        boxSizing: "border-box",
        alignItems: "center",
        cursor: "not-allowed",
    },
    Tooltip: {
        display: "flex",
        alignItems: "center",
        verticalAlign: "middle",
        height: "16px",
        maxHeight: "16px",
        padding: "2px 10px !important",
    },
    TooltipContent: {
        display: "flex",
        alignItems: "center",
        fontFamily: "Roboto",
        fontSize: "14px",
        height: "16px",
        maxHeight: "16px",
        padding: "0px",
    },
    CardExtended: {
        color: "black",
    },

    TopBar: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
    },
    ClassExtendCard: {
        flexDirection: "column",
        display: "flex",
        color: "black",
    },
    CardContent: {
        height: "250px",
        flexDirection: "column",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxSizing: "border-box",
    },
    CardContent_Content: {
        flex: "1 1 auto",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    BottomToolbar: {
        flex: "0 0 60px",
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
    },
}));

/**
 * Form Component
 * Wrapper handling the graphic interface for all form Input elements
 * Manages labels, animation, frames, border around all form elements to standardize them
 * Options are
 * Naked, Underlined, Outlined, OutlinedLabel
 */

const AGGridRowActionButton = React.memo(function AGGridRowActionButton(props, ref) {
    /****************************** PROPS AND JSS CLASSES *********************/
    const {
        TableRowButtons,
        Theme,
        GridApi,
        data,
        CrudAPIUpdate,
        //Dont Fold
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});

    /****************************** PROPS AND JSS CLASSES *********************/

    /****************************** REFS *********************/
    const Ref_Id = useRef(null);
    const Ref_RowData = useRef(null);
    const Ref_OnClickCallBack = useRef(null);
    const Ref_ConfirmationWindowDetails = useRef(null);
    /****************************** REFS *********************/
    /****************************** CONST *********************/

    let FinalTheme;
    if (Theme === "Dark") {
        FinalTheme = theme.Table.Dark;
    } else {
        FinalTheme = theme.Table.Light;
    }
    let DefaultRowActionButtonStyle = {
        IconSize: FinalTheme.RowButtonIconStyle.IconSize,
        ActiveColor: FinalTheme.RowButtonIconStyle.Color,
        HoverColor: FinalTheme.RowButtonIconStyle.HoverColor,
        DisabledColor: FinalTheme.RowButtonIconStyle.DisabledColor,
    };
    

    const GenerateTooltip = ({ButtonID, Position, Theme, Content}) => {
        return (
            <ReactTooltip id={ButtonID} place={Position} type={Theme} effect="solid" multiline={false} className={classes.Tooltip}>
                <div className={classes.TooltipContent}>{Content}</div>
            </ReactTooltip>
        );
    };
    const GenerateStandardIcon = ({Name, IconSize, SVGFillColor, SVGFillHoverColor}) => {
        let Icon;
        if (Name === "View") {
            Icon = <Preview />;
        }
        if (Name === "Edit") {
            Icon = <EditBox />;
        }
        if (Name === "Duplicate") {
            Icon = <DuplicateDocument />;
        }
        if (Name === "Delete") {
            Icon = <Trash />;
        }

        return React.cloneElement(Icon, {
            IconSize: IconSize,
            SVGFillColor: SVGFillColor,
            SVGFillHoverColor: SVGFillHoverColor,
        });
    };

    const CheckPropsStandarize = (ButtonProps) => {
        //This is used to check the properties and return satandardize format to generate the buttons
        // console.log("ButtonProps", ButtonProps);

        let Output = {
            Valid: false,
            Standard: true,
            Active: true,
            Tooltip: {
                Content: "Tooltip",
                Position: "right",
                Theme: "light",
            },
            Icon: null,
            OnClickFunction: null,
            OnClicConfirmationWindow: {
                Title: null,
                Content: null,
            },
        };
        let RowData = Ref_RowData.current;
        let Standard = ButtonProps.Standard === true ? true : false;
        let Name = ButtonProps.Name;
        if (Standard === true) {
            //Checking Button Name
            if (!Name) {
                console.log(`Table : Wrong Inputs for Button Column - Standard Button without name `);
                return Output;
            } else {
                if (Name === "View" || Name === "Edit" || Name === "Delete" || Name === "Duplicate") {
                    Output.Valid = true;
                } else {
                    console.log(`Table : Wrong Inputs for Button Column - Standard Button Has Wrong Name : ${Name}`);
                    return Output;
                }
            }

            //Checking Tooltip
            if (ButtonProps.Tooltip) {
                if (ButtonProps.Tooltip.Content) {
                    Output.Tooltip.Content = ButtonProps.Tooltip.Content;
                } else {
                    if (Name === "View") {
                        Output.Tooltip.Content = "View Entry";
                    }
                    if (Name === "Edit") {
                        Output.Tooltip.Content = "Edit Entry";
                    }
                    if (Name === "Duplicate") {
                        Output.Tooltip.Content = "Duplicate Entry";
                    }
                    if (Name === "Delete") {
                        Output.Tooltip.Content = "Delete Entry";
                    }
                }
                if (ButtonProps.Tooltip.Position) {
                    if (ButtonProps.Tooltip.Position === "right" || ButtonProps.Tooltip.Position === "left") {
                        Output.Tooltip.Position = ButtonProps.Tooltip.Position;
                    }
                }
                if (ButtonProps.Tooltip.Theme) {
                    if (ButtonProps.Tooltip.Theme === "light" || ButtonProps.Tooltip.Theme === "dark") {
                        Output.Tooltip.Theme = ButtonProps.Tooltip.Theme;
                    }
                }
            } else {
                Output.Tooltip = null;
            }

            //Checking if the Button is Active or not
            if (ButtonProps.ActiveCondition) {
                //We check that it is a function
                if (typeof ButtonProps.ActiveCondition === "function") {
                    //We check the rerturn of the function to check the status
                    let Active = ButtonProps.ActiveCondition(RowData);
                    if (Active === true) {
                        Output.Active = true;
                    } else {
                        Output.Active = false;
                    }
                } else {
                    Output.Valid = false;
                    console.log(`Table : Wrong Inputs for Button Column - ${Name} - ActiveCondition must be a function : ${ButtonProps.ActiveCondition} `);
                    return Output;
                }
            }

            //Generating the Icon
            let IconStyle = {
                Name: Name,
                IconSize: null,
                SVGFillColor: null,
                SVGFillHoverColor: null,
            };
            if (ButtonProps.IconSize) {
                IconStyle.IconSize = ButtonProps.IconSize;
            } else {
                IconStyle.IconSize = DefaultRowActionButtonStyle.IconSize;
            }

            if (Output.Active) {
                if (ButtonProps.ActiveColor) {
                    IconStyle.SVGFillColor = ButtonProps.ActiveColor;
                } else {
                    IconStyle.SVGFillColor = DefaultRowActionButtonStyle.ActiveColor;
                }
                if (ButtonProps.HoverColor) {
                    IconStyle.SVGFillHoverColor = ButtonProps.HoverColor;
                } else {
                    IconStyle.SVGFillHoverColor = DefaultRowActionButtonStyle.HoverColor;
                }
            } else {
                if (ButtonProps.InactiveColor) {
                    IconStyle.SVGFillColor = ButtonProps.InactiveColor;
                    IconStyle.SVGFillHoverColor = ButtonProps.InactiveColor;
                } else {
                    IconStyle.SVGFillColor = DefaultRowActionButtonStyle.DisabledColor;
                    IconStyle.SVGFillHoverColor = DefaultRowActionButtonStyle.DisabledColor;
                }
            }
            Output.Icon = GenerateStandardIcon(IconStyle);

            //Checking the OnClick function
            if (ButtonProps.OnClick) {
                //We check if it is a function
                if (typeof ButtonProps.OnClick === "function") {
                    if (Output.Active === true) {
                        //We only pass the onclick if it is active
                        //We check if a confirmation is required
                        if (ButtonProps.ConfirmationMessage) {
                            //We need to provide a confirmation window
                            //We store the OnClickFunction in a ref to reuse it when confirming
                            Ref_OnClickCallBack.current = ButtonProps.OnClick;
                            //We update the Onclick Function to open the card
                            Output.OnClickFunction = HandleOpenConfirmationCard;

                            //We also store the window Message content
                            let WindowMessage = {
                                Title: `Please confirm : ${Name}`,
                                Content: `Are you sure you want to ${Name}`,
                            };
                            Ref_ConfirmationWindowDetails.current = WindowMessage;
                        } else {
                            Output.OnClicConfirmation = null;
                            Output.OnClickFunction = ButtonProps.OnClick;
                        }
                    }
                } else {
                    Output.Valid = false;
                    console.log(`Table : Wrong Inputs for Button Column - ${Name} - OnClick must be a function : ${ButtonProps.OnClick} `);
                    return Output;
                }
            } else {
                //We check if it is an auto Delete Button
                if (Name === "Delete") {
                    //We need to provide a confirmation window
                    //We store the OnClickFunction in a ref to reuse it when confirming
                    Ref_OnClickCallBack.current = "DeleteOneAPI";
                    //We update the Onclick Function to open the card
                    Output.OnClickFunction = HandleOpenConfirmationCard;
                    //We also store the window Message content
                    let WindowMessage = {
                        Title: `Please confirm : ${Name}`,
                        Content: `Are you sure you want to ${Name}`,
                    };
                    Ref_ConfirmationWindowDetails.current = WindowMessage;
                } else {
                    Output.Valid = false;
                    console.log(`Table : Wrong Inputs for Button Column - ${Name} - OnClick is empty `);
                    return Output;
                }
            }
        }else{




		}

        return Output;
    };

    /****************************** CONST *********************/
    /****************************** STATE *********************/
    const [Id, setId] = useState(null);
    const [ButtonsComponent, setButtonsComponent] = useState(null);
    const [ShowViewCard, setShowViewCard] = useState(false);
    /****************************** STATE *********************/

    /****************************** CALLBACK FUNCTIONS *********************/
    const GenerateButtons = useCallback(() => {
        let FinalButtonComponents,
            FinalRowButtons = [];
        if (TableRowButtons && Array.isArray(TableRowButtons) && TableRowButtons.length > 0) {
            //We sort the buttons by the Index (if bad, it is the user responsibility)
            let SortedRowButtons = _.orderBy(TableRowButtons, ["Index"], ["asc"]);

            for (let i = 0; i < SortedRowButtons.length; i++) {
                let elem = SortedRowButtons[i];
                //1. Check properties and return standardize Inputs
                let FinalButtonOptions = CheckPropsStandarize(elem);

                if (FinalButtonOptions.Valid === false) {
                    FinalButtonComponents = "ButtonError";
                    break;
                }
                // console.log("FinalButtonOptions", FinalButtonOptions);
                let ButtonWrapperClass = FinalButtonOptions.Active === true ? classes.RowButtonActive : classes.RowButtonInactive;
                let TooltipComponent;
                let ButtonDataTipId = uuidv4();

                if (FinalButtonOptions.Tooltip) {
                    let TooltipOptions = {...FinalButtonOptions.Tooltip, ...{ButtonID: ButtonDataTipId}};

                    TooltipComponent = GenerateTooltip(TooltipOptions);
                }

                let ButtonWrapper = (
                    <React.Fragment>
                        <div
                            className={ButtonWrapperClass}
                            data-tip
                            data-for={ButtonDataTipId}
                            onClick={FinalButtonOptions.OnClickFunction ? () => FinalButtonOptions.OnClickFunction(Ref_RowData.current._id, Ref_RowData.current) : null}
                        >
                            {FinalButtonOptions.Icon}
                        </div>
                        {TooltipComponent}
                    </React.Fragment>
                );

                FinalRowButtons.push(ButtonWrapper);
            }
            FinalButtonComponents = <React.Fragment>{FinalRowButtons}</React.Fragment>;
        } else {
            console.log("Table : Wrong Inputs for Button Column - Should be an array");
            FinalButtonComponents = "ButtonError";
        }

        setButtonsComponent(FinalButtonComponents);
    }, [TableRowButtons]);

    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** EFFECT *********************/

    /****************************** EFFECT *********************/
    useEffect(() => {
        if (GridApi) {
            if (data && TableRowButtons) {
                let Id = data._id;
                Ref_Id.current = Id;
                Ref_RowData.current = data;
                setId(Id);
                GenerateButtons();
            }
        }
    }, [data, TableRowButtons, GenerateButtons, GridApi]);
    /****************************** FUNCTIONS *********************/
    const HandlePreview = () => {
        // console.log("Preview", Id);
    };
    const HandleEdit = () => {
        // console.log("HandleEdit", Id);
    };
    const HandleDelete = () => {
        // console.log("HandleDelete", Id);
    };

    const HandleDeleteMessage = () => {
        // console.log("ShowCardMessage");
        setShowViewCard(true);
    };

    const HandleCloseViewCard = () => {
        GridApi.deselectAll();
        setShowViewCard(false);
    };

    const HandleDeleteConfirmation = () => {
        let ClickAction = Ref_OnClickCallBack.current;
        // console.log("ClickAction", ClickAction);
        if (ClickAction === "DeleteOneAPI") {
            setShowViewCard(false);
            if (CrudAPIUpdate && typeof CrudAPIUpdate === "function") {
                CrudAPIUpdate({Action: "DeleteOne", Id: Ref_Id.current});
            }
        } else {
            setShowViewCard(false);
            Ref_OnClickCallBack.current(Ref_Id.current);
        }
    };

    const HandleOpenConfirmationCard = () => {
        SelectRowOnClick();
        setShowViewCard(true);
    };

    const HandleCancel = () => {
        setShowViewCard(false);
        GridApi.deselectAll();
    };

    const SelectRowOnClick = () => {
        let Id = Ref_Id.current;
        // console.log("Id", Id);

        GridApi.forEachNode(function (node) {
            node.setSelected(node.data._id === Id);

            // console.log("Node", node);
            // node.setSelected(node.data.country === "United States");
        });
    };
    /****************************** FUNCTIONS *********************/

    /****************************** RENDER *********************/
    let ModalCardComponent;

    if (ShowViewCard === true && Ref_ConfirmationWindowDetails.current) {
        let CardTitle = <div className={classes.TopBar}>{Ref_ConfirmationWindowDetails.current.Title}</div>;
        let CardContent = <div className={classes.CardContent_Content}>{Ref_ConfirmationWindowDetails.current.Content}</div>;
        let BottomToolbar = (
            <div className={classes.BottomToolbar}>
                <Button onClick={HandleDeleteConfirmation}>Delete</Button>
                <Button onClick={HandleCancel}>Cancel</Button>
            </div>
        );

        ModalCardComponent = (
            <CardModal
                // CardID="HealFit_CreateDailyIntake"
                // ParentCompRef={Ref_TabNavContent}
                IsDraggable={true}
                TopBarContent={CardTitle}
                CloseCard={HandleCloseViewCard}
                CloseOnClickOutside={true}
                WithCloseButton={true}
                CardMaxWidth="400px"
                CardHeight="300px"
                // CardWindowPaddingVertical="10px"
                // CardWindowPaddingHorizontal="10px"
                WithPerfectScrollbar={true}
                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.ClassExtendCard}
            >
                <div className={classes.CardContent}>
                    {CardContent}
                    {BottomToolbar}
                </div>
            </CardModal>
        );
    }
    return (
        <div style={{display: "flex", height: "100%", alignItems: "center"}}>
            {ModalCardComponent}
            {ButtonsComponent}
        </div>
    );
    /****************************** RENDER *********************/
});

AGGridRowActionButton.defaultProps = {
    TableRowButtons: null,
    Params: null,
};

AGGridRowActionButton.propTypes = {
    /**
     * Message : The message to display
     */
    TableRowButtons: PropTypes.any,
    /**
     * Message : The message to display
     */
    Params: PropTypes.any,
};

export default AGGridRowActionButton;
